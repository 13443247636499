<script lang="ts">
	import type { InventoryQuestion, SearchField, InventoryQuestionValue, InventoryQuestionChoice } from 'utility/search-fields'
	import type { ComponentProps } from 'svelte'
	type InputType = ComponentProps<Input>['type']

	import { v4 as uuid } from '@lukeed/uuid'
	import { createEventDispatcher } from 'svelte'

	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import Pin from 'components/Pin.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let label: string = ''
	export let value: InventoryQuestionValue = searchField?.qaFieldData?.dataType === 'BOOLEAN' ? true : ''
	export let disabled: boolean = false

	let datalistId: string = uuid()
	let disableInput: boolean = false

	const textInputTypeMap = new Map<InventoryQuestion['dataType'], InputType>([
		['TEXT', 'text'],
		['DATE', 'date'],
		['TIME', 'time'],
		['DATETIME', 'datetime-local'],
	])
	const dispatch = createEventDispatcher<{
		change: { field: SearchField; value: InventoryQuestionValue }
	}>()

	/* This function is called when the value changes, which is only from the checkbox component
	We need to ensure that we dispatch a change event when the boolean search field is initally added
	This is different from all of the other fields, which don't have a value until the user interacts with them */
	function handleBooleanField(searchField: SearchField, value: InventoryQuestionValue) {
		if (searchField.qaFieldData?.dataType === 'BOOLEAN') {
			dispatch('change', { field: searchField, value: value })
		}
	}

	$: handleBooleanField(searchField, value)
	$: disableInput = disabled || inEditMode
	$: label = searchField?.qaFieldData?.name ?? ''

	function currencyChangeHandler(e: CustomEvent<any>, searchField: SearchField) {
		value = e.detail.value
		dispatch('change', { field: searchField, value })
	}

	function autocompleteChangeHandler(e: CustomEvent<InventoryQuestionChoice | null>, searchField: SearchField) {
		value = e.detail
		dispatch('change', { field: searchField, value })
	}

	function elementChangeHandler(e: Event, searchField: SearchField) {
		const eventValue = (e.target as HTMLInputElement)?.value
		if (searchField?.qaFieldData?.dataType === 'NUMBER') {
			value = parseFloat(eventValue.replace(/[^\d.]/g, '')) //remove any non-numeric values
		} else {
			value = eventValue
		}
		dispatch('change', { field: searchField, value })
	}

	//Type Guards
	function isString(qaField: InventoryQuestion, value: InventoryQuestionValue): value is string {
		return Array.from(textInputTypeMap.keys()).includes(qaField.dataType)
	}

	function isChoice(qaField: InventoryQuestion, value: InventoryQuestionValue): value is InventoryQuestionChoice {
		return ['CHOICE', 'AUTOSUGGEST_TEXT'].includes(qaField.dataType)
	}

	function isBoolean(qaField: InventoryQuestion, value: InventoryQuestionValue): value is boolean {
		return qaField.dataType === 'BOOLEAN'
	}

	function isCurrency(qaField: InventoryQuestion, value: InventoryQuestionValue): value is string {
		return qaField.dataType === 'CURRENCY'
	}

	function isNumber(qaField: InventoryQuestion, value: InventoryQuestionValue): value is number {
		return qaField.dataType === 'NUMBER'
	}
</script>

{#if searchField?.qaFieldData}
	{@const qaField = searchField?.qaFieldData}
	<EditModeControls
		{searchField}
		{inEditMode}
		bind:pinnedSearchFields
		bind:selectedSearchFieldsKeys
	>
		{#if isString(qaField, value)}
			<Input
				{label}
				{value}
				type={textInputTypeMap.get(qaField.dataType) ?? 'text'}
				list={datalistId}
				disabled={disableInput}
				on:change={e => elementChangeHandler(e, searchField)}
				{...$$restProps}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Input>
		{:else if isNumber(qaField, value)}
			<!-- we can't use type="number" here because if they type in something non-numeric, the change event won't even fire -->
			<Input
				{label}
				{value}
				list={datalistId}
				disabled={disableInput}
				inputmode="numeric"
				on:change={e => elementChangeHandler(e, searchField)}
				{...$$restProps}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Input>
		{:else if isChoice(qaField, value)}
			<Autocomplete
				{label}
				{value}
				options={qaField.choices}
				emptyValue={null}
				getLabel={choice => choice?.label ?? ''}
				disabled={disableInput}
				on:change={e => autocompleteChangeHandler(e, searchField)}
				{...$$restProps}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Autocomplete>
		{:else if isCurrency(qaField, value)}
			<CurrencyInput
				{label}
				{value}
				list={datalistId}
				disabled={disableInput}
				on:change={e => currencyChangeHandler(e, searchField)}
				{...$$restProps}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</CurrencyInput>
		{:else if isBoolean(qaField, value)}
			<Checkbox
				{label}
				bind:checked={value}
				type="radio"
				btnGroupClass="mb-1 w-100"
				disabled={disableInput}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Checkbox>
		{/if}

		<!-- So if its not of these fields specifically mapped to an autocomplete and there are choices, just use a datalist element for the choices, I guess -->
		{#if qaField?.choices.length && !['AUTOSUGGEST_TEXT', 'CHOICE'].includes(qaField.dataType)}
			<datalist id={datalistId}>
				{#each qaField.choices as choice}
					<option value={choice.label} />
				{/each}
			</datalist>
		{/if}
	</EditModeControls>
{/if}
