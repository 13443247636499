import { storeQuery, groupsQuery, userAccountsQuery, userAccountQuery, permissionsQuery, createUserAccountMutation, updateUserAccountMutation, wageRatesQuery, adminSetPasswordSettingValueQuery } from 'common/graphql-queries'
import template from './user.html'
import makeItUserConfiguration from '@isoftdata/ractive-user-configuration'
import { klona } from 'klona'

import makeItCheckbox from '@isoftdata/checkbox'
import makeItSelect from '@isoftdata/select'
import makeItButton from '@isoftdata/button'
import toTitleCase from 'to-title-case'

const absoluteDateTimeFormatter = date => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)

const defaultNewUserAccountState = Object.freeze({
	status: null,
	name: '',
	workEmail: '',
	recoveryEmail: null,
	firstName: '',
	lastName: '',
	fullName: '',
	lockNotes: '',
	apiToken: null,
	userLastPasswordResetDate: null,
	userSites: [], //Site is our generic name for plant/store
	authorizedSites: [],
	userPermissions: [],
	userRoles: [],
	driver: false,
	worker: false,
	salesPerson: false,
})

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.configuration.user',
		route: 'user',
		querystringParameters: [ 'userAccountId' ],
		defaultParameters: {
			userAccountId: () => JSON.parse(sessionStorage.getItem('user') ?? '{}').id,
		},
		template: {
			template,
			components: {
				itButton: makeItButton(),
				itCheckbox: makeItCheckbox(),
				itSelect: makeItSelect({ twoway: true, lazy: false }),
				userConfiguration: makeItUserConfiguration({
					defaultNewUserAccountState,
					updateUserAccount: async(selectedUserAccount, sendPasswordRecoveryToken) => {
						await mediator.publish('graphqlFetch', updateUserAccountMutation, {
							input: {
								id: selectedUserAccount.id,
								firstName: selectedUserAccount.firstName,
								lastName: selectedUserAccount.lastName,
								name: selectedUserAccount.name,
								status: selectedUserAccount.status,
								userGroupIds: selectedUserAccount.userGroups.filter(group => group.isMember).map(group => group.groupId),
								authorizedStoreIds: selectedUserAccount.userSites.filter(plant => plant.isAuthorized).map(plant => plant.id),
								userPermissions: selectedUserAccount.userPermissions.map(permission => {
									return {
										id: permission.id,
										level: toTitleCase(permission.userPermissionLevel),
									}
								}),
								lockNotes: selectedUserAccount.lockNotes,
								workEmail: selectedUserAccount.workEmail,
								apiToken: selectedUserAccount?.apiToken ?? null,
								newPassword: selectedUserAccount?.newPassword ?? null,
								sendPasswordRecoveryToken,
								isDriver: selectedUserAccount.driver,
								isWorker: selectedUserAccount.worker,
								isSalesPerson: selectedUserAccount.salesPerson,
								wageRateId: selectedUserAccount.wageRateId,
							},
						})
					},
					createNewUserAccount: async selectedUserAccount => {
						const { createUserAccount } = await mediator.publish('graphqlFetch', createUserAccountMutation, {
							input: {
								firstName: selectedUserAccount.firstName,
								lastName: selectedUserAccount.lastName,
								name: selectedUserAccount.name,
								userGroupIds: selectedUserAccount.userGroups.filter(group => group.isMember).map(group => group.groupId),
								authorizedStoreIds: selectedUserAccount.userSites.filter(plant => plant.isAuthorized).map(plant => plant.id),
								userPermissions: selectedUserAccount.userPermissions.map(permission => {
									return {
										id: permission.id,
										level: toTitleCase(permission.userPermissionLevel),
									}
								}),
								lockNotes: selectedUserAccount.lockNotes,
								workEmail: selectedUserAccount.workEmail,
								apiToken: selectedUserAccount.apiToken ?? null,
								newPassword: selectedUserAccount.newPassword ?? null,
								isDriver: selectedUserAccount.driver,
								isWorker: selectedUserAccount.worker,
								isSalesPerson: selectedUserAccount.salesPerson,
								wageRateId: selectedUserAccount.wageRateId,
							},
						})
						return createUserAccount
					},
					errorHandler: error => {
						console.error(error[0])
						mediator.publish('showMessage', { type: 'danger', time: false, message: error[0].message })
					},
					successHandler: message => {
						mediator.publish('showMessage', { type: 'success', time: 3000, message })
					},
				}),
			},
		},
		data: {

		},
		async resolve(_data, { userAccountId }) {
			userAccountId = parseInt(userAccountId, 10) || null
			const { stores } = await mediator.publish('graphqlFetch', storeQuery)
			const { groups } = await mediator.publish('graphqlFetch', groupsQuery)
			let { userAccounts } = await mediator.publish('graphqlFetch', userAccountsQuery)
			const { permissions } = await mediator.publish('graphqlFetch', permissionsQuery)
			const { wageRates } = await mediator.publish('graphqlFetch', wageRatesQuery)

			const session = JSON.parse(sessionStorage.getItem('user') ?? '{}')
			userAccounts = userAccounts.map(userAccount => {
				return {
					...userAccount,
					name: session.id === userAccount.id ? `${userAccount.name} (You)` : userAccount.name,
					lastAccess: userAccount.lastAccess ? absoluteDateTimeFormatter(new Date(userAccount.lastAccess)) : '',
				}
			})

			const { settingValues } = await mediator.publish('graphqlFetch', adminSetPasswordSettingValueQuery)
			const hasPermissionToChangePassword = settingValues.security.administratorsCanSetOtherUsersPasswords

			let selectedUserAccount = klona(defaultNewUserAccountState)
			if (userAccountId) {
				const { userAccount } = await mediator.publish('graphqlFetch', userAccountQuery, { userAccountId })
				selectedUserAccount = userAccount
				const userPlantIdSet = new Set(selectedUserAccount.authorizedStores.map(store => store.id))
				const userGroupIdSet = new Set(selectedUserAccount.groups.map(group => group.id))

				const permissionMap = new Map()
				selectedUserAccount.highestAccessPermissions.map(highestPermission => {
					permissionMap.set(highestPermission.id, { grantedPermission: highestPermission.level })
				})
				selectedUserAccount.userPermissions.map(userPermission => {
					const permission = permissionMap.get(userPermission.id)
					const permissionLevel = userPermission.level
					if (permission) {
						permission.userPermission = permissionLevel
					} else {
						permissionMap.set(userPermission.permissionId, { userPermission: permissionLevel })
					}
				})
				selectedUserAccount.highestGroupPermissions.map(groupPermission => {
					const permission = permissionMap.get(groupPermission.id)
					const permissionValue = groupPermission.level
					if (permission) {
						permission.groupPermission = permissionValue
					} else {
						permissionMap.set(groupPermission.permissionId, { groupPermission: permissionValue })
					}
				})

				selectedUserAccount = {
					...selectedUserAccount,
					status: selectedUserAccount.status.toUpperCase(),
					userLastPasswordResetDate: selectedUserAccount.lastPasswordResetDate ? absoluteDateTimeFormatter(new Date(selectedUserAccount.lastPasswordResetDate)) : null,
					wageRateId: selectedUserAccount.wageRate?.id ?? null,
					userSites: stores.map(store => {
						return {
							...store,
							isAuthorized: userPlantIdSet.has(store.id),
						}
					}),
					userPermissions: permissions.map(permission => {
						return {
							...permission,
							computedPermissionLevel: permissionMap.get(permission.id)?.grantedPermission?.toUpperCase() ?? 'NONE',
							userPermissionLevel: permissionMap.get(permission.id)?.userPermission?.toUpperCase() ?? 'NONE',
							groupPermissionLevel: permissionMap.get(permission.id)?.groupPermission?.toUpperCase() ?? 'NONE',
						}
					}),
					userGroups: groups.map(group => {
						return {
							...group,
							isMember: userGroupIdSet.has(group.groupId),
							groupPermissions: group.groupPermissions.map(permission => {
								return {
									...permission,
									value: permission.value?.toUpperCase(),
								}
							}),
						}
					}),
				}
			}
			return {
				userAccounts,
				stores,
				selectedUserAccount,
				originalUserAccountData: klona(selectedUserAccount),
				selectedUserAccountId: userAccountId,
				showEditButton: true,
				haveUnsavedChanges: false,
				wageRates,
				hasPermissionToChangePassword,
			}
		},
		activate(context) {
			var ractive = context.domApi

			ractive.observe('selectedUserAccountId', () => {
				const userAccountId = ractive.get('selectedUserAccountId')
				stateRouter.go(null, { userAccountId }, { inherit: true, replace: true })
			})
		},
	})
}
