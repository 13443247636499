<script
	lang="ts"
	context="module"
>
	import type { Manufacturer, InventoryType, SearchField } from 'utility/search-fields'
	type Type = 'ASSEMBLY' | 'PART' | 'VEHICLE'

	import Pin from 'components/Pin.svelte'
	import { startsWithVowel } from 'utility/language-tools'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import { loadPartManufacturersForInventoryType, loadVehicleManufacturers } from 'utility/search-fields'
</script>

<script lang="ts">
	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let inventoryType: InventoryType | null | undefined = undefined
	export let manufacturer: Manufacturer | null = null
	export let type: Type // No default value here, require the consumer to pass the type of manufacturer.
	export let disabled: boolean = false

	let manufacturerList: Promise<Manufacturer[]> | Manufacturer[] = []

	async function loadManufacturers(type: Type, inventoryType?: InventoryType | null) {
		if (inventoryType) {
			manufacturerList = loadPartManufacturersForInventoryType(type === 'ASSEMBLY' ? inventoryType?.setId ?? inventoryType?.id : inventoryType?.id)
			manufacturerList.then(loadedManufacturers => {
				manufacturerList = loadedManufacturers
				// If we have a selected manufacturer but after loading the new list, it's not in the list, then we need to the clear the selection
				if (manufacturer && !loadedManufacturers.find(loadedManufacturer => loadedManufacturer.id === manufacturer?.id)) {
					manufacturer = null
				}
			})
		} else if (type === 'VEHICLE') {
			manufacturerList = loadVehicleManufacturers()
			manufacturer = null
		} else {
			manufacturerList = []
			manufacturer = null
		}
	}

	function computePlaceholder(type: Type) {
		const typeLabel = typeLabelMap.get(type)
		if (typeLabel) {
			return `Select ${startsWithVowel(typeLabel) ? 'an' : 'a'} ${typeLabel}`
		} else return 'Select a Manufacturer'
	}

	const typeLabelMap = new Map<Type, string>([
		['ASSEMBLY', 'Assembly Manufacturer'],
		['PART', 'Part Manufacturer'],
		['VEHICLE', 'Vehicle Make'],
	])

	$: loadManufacturers(type, inventoryType)
	$: computedPlaceholder = computePlaceholder(type)
	$: label = typeLabelMap.get(type) ?? 'Manufacturer'
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		{label}
		getLabel={option => option?.name ?? ''}
		options={manufacturerList}
		bind:value={manufacturer}
		emptyValue={null}
		placeholder={computedPlaceholder}
		disabled={disabled || (type !== 'VEHICLE' && !inventoryType)}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
