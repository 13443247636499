import type { AppContext } from 'types/common'
import component from './NotFound.svelte'

export default function (appContext: AppContext) {
	appContext.stateRouter.addState({
		name: 'not-found',
		route: 'not-found',
		querystringParameters: ['route'],
		template: {
			component,
			svelte: true,
		},
		resolve(_stateData, { route }) {
			return Promise.resolve({ route })
		},
	})
}
