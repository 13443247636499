import { graphql } from '$houdini'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'

export default async function loadVehicleModelsForMake(make: string) {
	const { data } = await vehicleModelsByMake.fetch({ variables: { make }, policy: 'CacheOrNetwork' })
	if (!data) {
		return []
	}
	const vehicleModels = sortArrayByObjectKey({ array: data.vehicleModels, key: 'name' }).map(row => {
		return row.name
	})
	return vehicleModels && Array.isArray(vehicleModels) ? vehicleModels : []
}

const vehicleModelsByMake = graphql(`
	query LIB_vehicleModels($make: String) {
		vehicleModels(make: $make) {
			name
			active
		}
	}
`)
