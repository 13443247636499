export default {
    "name": "SkuLookup",
    "kind": "HoudiniQuery",
    "hash": "657f0e006fa96700a06cdb7553b690221f0db66083a5b384651c028f87d4057c",

    "raw": `query SkuLookup($input: SkuLookupResolverInput!) {
  skuLookup(input: $input) {
    useInScanner
    relationshipType
    packageQuantity
    sku: inventoryId
    inventories {
      id
      storeId
      vehicleId
      inventoryType {
        vehicle
        id
      }
    }
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "skuLookup": {
                "type": "SkuLookup",
                "keyRaw": "skuLookup(input: $input)",

                "selection": {
                    "fields": {
                        "useInScanner": {
                            "type": "Boolean",
                            "keyRaw": "useInScanner",
                            "visible": true
                        },

                        "relationshipType": {
                            "type": "SkuLookupRelationshipTypeEnum",
                            "keyRaw": "relationshipType",
                            "visible": true
                        },

                        "packageQuantity": {
                            "type": "Int",
                            "keyRaw": "packageQuantity",
                            "visible": true
                        },

                        "sku": {
                            "type": "Int",
                            "keyRaw": "sku",
                            "visible": true
                        },

                        "inventories": {
                            "type": "Inventory",
                            "keyRaw": "inventories",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "UInt",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    },

                                    "vehicleId": {
                                        "type": "UInt",
                                        "keyRaw": "vehicleId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",

                                        "selection": {
                                            "fields": {
                                                "vehicle": {
                                                    "type": "Boolean",
                                                    "keyRaw": "vehicle",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "SkuLookupResolverInput"
        },

        "types": {
            "SkuLookupResolverInput": {
                "lookup": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8f9e870697cf9b1dcbefaad4e6f469f8ba02298c911f3fc6aa44e8ef0d1b5ffb";