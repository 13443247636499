<script lang="ts">
	import type { UserStatus, UserStatusType, SearchField } from 'utility/search-fields'

	import { onMount } from 'svelte'
	import { loadUserStatuses } from 'utility/search-fields'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let userStatus: UserStatus | null = null
	export let statusType: UserStatusType = 'INVENTORY'
	export let disabled: boolean = false

	let userStatusList: Promise<UserStatus[]> | UserStatus[] = []

	onMount(async () => {
		userStatusList = await loadUserStatuses(statusType)
	})
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		label="User Status"
		options={userStatusList}
		emptyValue={null}
		bind:value={userStatus}
		getLabel={option => (option ? `${option?.status} - ${option?.description}` : '')}
		disabled={disabled || inEditMode}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
