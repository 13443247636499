export default {
    "name": "LIB_modelCategoryPricing",
    "kind": "HoudiniQuery",
    "hash": "53f8939eafd1d037124a992d138e101ddcd18b377f2589e203d52e545e5e744d",

    "raw": `query LIB_modelCategoryPricing($modelId: Int!, $categoryId: Int) {
  modelCategoryPricing(modelId: $modelId, categoryId: $categoryId) {
    modelId
    category
    notes
    pricing {
      jobber
      jobberCore
      list
      retail
      retailCore
      wholesale
      wholesaleCore
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "modelCategoryPricing": {
                "type": "ModelCategoryPricing",
                "keyRaw": "modelCategoryPricing(categoryId: $categoryId, modelId: $modelId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "modelId": {
                            "type": "Int",
                            "keyRaw": "modelId",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "nullable": true,
                            "visible": true
                        },

                        "pricing": {
                            "type": "Pricing",
                            "keyRaw": "pricing",

                            "selection": {
                                "fields": {
                                    "jobber": {
                                        "type": "Decimal2",
                                        "keyRaw": "jobber",
                                        "visible": true
                                    },

                                    "jobberCore": {
                                        "type": "Decimal2",
                                        "keyRaw": "jobberCore",
                                        "visible": true
                                    },

                                    "list": {
                                        "type": "Decimal2",
                                        "keyRaw": "list",
                                        "visible": true
                                    },

                                    "retail": {
                                        "type": "Decimal2",
                                        "keyRaw": "retail",
                                        "visible": true
                                    },

                                    "retailCore": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailCore",
                                        "visible": true
                                    },

                                    "wholesale": {
                                        "type": "Decimal2",
                                        "keyRaw": "wholesale",
                                        "visible": true
                                    },

                                    "wholesaleCore": {
                                        "type": "Decimal2",
                                        "keyRaw": "wholesaleCore",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "modelId": "Int",
            "categoryId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=888a5bebcbc2cf5ef3660528a89aa648fc2291517eed7052dff612ef9b10887c";