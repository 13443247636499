<script lang="ts">
	import type { InventoryMessages$result } from '$houdini'
	type Message = InventoryMessages$result['inventory']['messages'][0]

	import { Table, Td } from '@isoftdata/svelte-table'
	import { graphql } from '$houdini'
	import titleCase from 'to-title-case'
	import Icon from '@isoftdata/svelte-icon'

	const messagesQuery = graphql(`
		query InventoryMessages($inventoryId: UInt!) {
			inventory(id: $inventoryId) {
				messages {
					type
					trigger
					text
					display
					id
				}
			}
		}
	`)

	export let inventoryId: number

	let messages: Message[] | Promise<Message[]> = []

	async function loadMessages(inventoryId: number) {
		return inventoryId ? (await messagesQuery.fetch({ variables: { inventoryId }, policy: 'CacheOrNetwork' }))?.data?.inventory?.messages ?? [] : []
	}

	function loadAndSetMessages(inventoryId: number) {
		messages = loadMessages(inventoryId)
	}

	$: loadAndSetMessages(inventoryId)
</script>

{#await messages}
	<Icon
		icon="spinner"
		class="fa-xl fa-spin"
	/>
{:then loadedMessages}
	<Table
		rows={loadedMessages}
		columns={[
			{ property: 'text', name: 'Text', title: 'The body of the message' },
			{ property: 'trigger', name: 'Trigger', title: 'The type of event that will trigger message delivery' },
			{ property: 'type', name: 'Message Type', title: 'The document type this message is associated with' },
			{ property: 'display', name: 'Display', title: 'How the message appears' },
		]}
		responsive
		let:row={message}
	>
		{@const { text, trigger, type, display } = message}
		<tr>
			<Td property="text">{text}</Td>
			<Td property="trigger">{trigger}</Td>
			<Td property="type">{titleCase(type)}</Td>
			<Td property="display">{titleCase(display)}</Td>
		</tr>
	</Table>
{:catch error}
	<p>Error loading part messages.</p>
{/await}
