import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/InventoryBySkuAndStore'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class InventoryBySkuAndStoreStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "InventoryBySkuAndStoreStore",
			variables: true,
		})
	}
}

export async function load_InventoryBySkuAndStore(params) {
	await initClient()

	const store = new InventoryBySkuAndStoreStore()

	await store.fetch(params)

	return {
		InventoryBySkuAndStore: store,
	}
}
