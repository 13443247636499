export default {
    "name": "HOUDINI_inventoryQuery",
    "kind": "HoudiniQuery",
    "hash": "180b16339cf52587290a29be3bb0c3f9b59ccf3a6118d6416ef8e8643e15c5f7",

    "raw": `query HOUDINI_inventoryQuery($innodbInventoryid: UInt!, $serialFilter: InventorySerialFilter) {
  inventory(id: $innodbInventoryid) {
    ...InventoryData_2X3WVo
    id
  }
}

fragment InventoryData_2X3WVo on Inventory {
  innodbInventoryid: id
  topImage {
    path
    name
    id
  }
  attachments {
    fileId: id
    file {
      id
      createdDate: created
      extension
      mimeType: mimetype
      name
      path: url
      size
      type
      updated
    }
    public
    rank
    id
  }
  averageCoreCost
  averageCost
  averageDemandPerDay
  averageDemandPerMonth
  bodyStyle
  buyPackage
  category {
    id
    description
    name
  }
  condition
  coreClass
  coreCost
  coreRequired
  coreRequiredToVendor
  cost
  cost
  dateEntered
  dateModified
  dateViewed
  daysToReturn
  daysToReturnCore
  daysToReturnCoreToVendor
  daysToReturnToVendor
  defaultVendor {
    id
    code
    companyName
    contactName
    coreClassHistory
  }
  deplete
  description
  distributorCorePrice
  distributorPrice
  ...EnteredBy
  freezeUntil
  glCategory {
    id
    name
  }
  inventoryId
  ...InventoryOptionValues
  inventoryTypeId
  jobberCorePrice
  jobberPrice
  jobberPrice
  interchangeNumber
  inventoryType {
    typeData1History
    typeData2History
    typeData3History
    typeData4History
    setId
    id
  }
  listPrice
  locations {
    id
    holdQuantity
    location {
      name
      description
      allowInventory
      id
    }
    locationId
    permanent
    quantity
    rank
  }
  manufacturer {
    id
    name
    code
    active
  }
  maxQuantity
  minQuantity
  model {
    id
    name
    active
  }
  notes
  oemNumber
  parentModel {
    id
    name
    active
  }
  parentManufacturer {
    id
    name
    code
    active
  }
  partNumber
  popularityCode
  printTag
  public
  quantity
  quantityAvailable
  quantityOnHold
  replenishable
  retailCorePrice
  retailCorePrice
  retailPrice
  retailPrice
  returnable
  returnableToVendor
  saleClass {
    code
    name
    id
  }
  serials(filter: $serialFilter) {
    id
    dateEntered
    enteredOnDocumentId
    enteredOnDocumentLineId
    enteredOnDocumentStoreId
    enteredOnDocumentType
    ...SerialOptionValues
    location {
      ... on Location {
        id
        name
        description
      }
      ... on VirtualLocation {
        name
      }
      __typename
    }
    number
    status
    usedOnDocumentId
    usedOnDocumentStoreId
    usedOnDocumentType
  }
  safetyStockPercent
  seasonal
  sellPackage
  sellPriceClass {
    id
  }
  serialized
  side
  singleQuantity
  ...ShippingDimensions
  sku
  status
  stockCategory
  stockingDays
  stockMethod
  stockType
  storeId
  storeId
  suggestedMaxQuantity
  suggestedMinQuantity
  suggestedSafetyStockPercent
  tagNumber
  taxable
  typeField1 {
    label
    data
  }
  typeField2 {
    label
    data
  }
  typeField3 {
    label
    data
  }
  typeField4 {
    label
    data
  }
  userStatus
  useVendorOrderMultiplier
  vehicle {
    manufacturer {
      name
      id
    }
    model {
      name
      id
    }
    vin
    year
    bodyStyle
    stockNumber
    topImage {
      url
      name
      id
    }
    id
  }
  vehicleId
  vehicleMake
  vehicleModel
  vehicleVin
  vehicleYear
  vendorLeadTime
  vendorPopularityCode
  vendorProductCode
  wholesaleCorePrice
  wholesalePrice
  wholesalePrice
  id
  __typename
}

fragment EnteredBy on Inventory {
  enteredBy {
    id
    name
  }
  id
  __typename
}

fragment InventoryOptionValues on Inventory {
  inventoryOptions: optionValues {
    ...InventoryOptionValueData
    id
    inventoryId
    optionId
  }
  id
  __typename
}

fragment SerialOptionValues on InventorySerial {
  inventoryOptions: optionValues {
    ...InventoryOptionValueData
    id
    inventoryId
    optionId
  }
  id
  __typename
}

fragment ShippingDimensions on Inventory {
  shippingDimensions {
    weightUnit
    measurementUnit
    weight
    length
    width
    height
  }
  id
  __typename
}

fragment InventoryOptionValueData on InventoryOptionValue {
  inventoryOptionValueId: id
  optionId
  value
  option {
    id
    name
    defaultChoice
    required
    rank
    dataType
    public
    manufacturer {
      id
      name
    }
    model {
      id
      name
    }
    category {
      id
      name
    }
    inventoryType {
      id
    }
    choices {
      default
      label
      rank
      id
    }
  }
  id
  inventoryId
  __typename
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $innodbInventoryid)",

                "selection": {
                    "fields": {
                        "innodbInventoryid": {
                            "type": "UInt",
                            "keyRaw": "innodbInventoryid",
                            "visible": true
                        },

                        "topImage": {
                            "type": "File",
                            "keyRaw": "topImage",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachments": {
                            "type": "ItemAttachment",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "fileId": {
                                        "type": "UInt",
                                        "keyRaw": "fileId",
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "createdDate": {
                                                    "type": "DateTime",
                                                    "keyRaw": "createdDate",
                                                    "visible": true
                                                },

                                                "extension": {
                                                    "type": "String",
                                                    "keyRaw": "extension",
                                                    "visible": true
                                                },

                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "size": {
                                                    "type": "Int",
                                                    "keyRaw": "size",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "Date",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "averageCoreCost": {
                            "type": "Decimal2",
                            "keyRaw": "averageCoreCost",
                            "visible": true
                        },

                        "averageCost": {
                            "type": "Decimal2",
                            "keyRaw": "averageCost",
                            "visible": true
                        },

                        "averageDemandPerDay": {
                            "type": "Decimal6",
                            "keyRaw": "averageDemandPerDay",
                            "visible": true
                        },

                        "averageDemandPerMonth": {
                            "type": "Decimal6",
                            "keyRaw": "averageDemandPerMonth",
                            "visible": true
                        },

                        "bodyStyle": {
                            "type": "String",
                            "keyRaw": "bodyStyle",
                            "nullable": true,
                            "visible": true
                        },

                        "buyPackage": {
                            "type": "Int",
                            "keyRaw": "buyPackage",
                            "nullable": true,
                            "visible": true
                        },

                        "category": {
                            "type": "Category",
                            "keyRaw": "category",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "condition": {
                            "type": "String",
                            "keyRaw": "condition",
                            "nullable": true,
                            "visible": true
                        },

                        "coreClass": {
                            "type": "String",
                            "keyRaw": "coreClass",
                            "nullable": true,
                            "visible": true
                        },

                        "coreCost": {
                            "type": "Decimal2",
                            "keyRaw": "coreCost",
                            "visible": true
                        },

                        "coreRequired": {
                            "type": "Boolean",
                            "keyRaw": "coreRequired",
                            "visible": true
                        },

                        "coreRequiredToVendor": {
                            "type": "Boolean",
                            "keyRaw": "coreRequiredToVendor",
                            "visible": true
                        },

                        "cost": {
                            "type": "Decimal2",
                            "keyRaw": "cost",
                            "visible": true
                        },

                        "dateEntered": {
                            "type": "DateTime",
                            "keyRaw": "dateEntered",
                            "nullable": true,
                            "visible": true
                        },

                        "dateModified": {
                            "type": "DateTime",
                            "keyRaw": "dateModified",
                            "nullable": true,
                            "visible": true
                        },

                        "dateViewed": {
                            "type": "DateTime",
                            "keyRaw": "dateViewed",
                            "nullable": true,
                            "visible": true
                        },

                        "daysToReturn": {
                            "type": "UInt",
                            "keyRaw": "daysToReturn",
                            "visible": true
                        },

                        "daysToReturnCore": {
                            "type": "UInt",
                            "keyRaw": "daysToReturnCore",
                            "visible": true
                        },

                        "daysToReturnCoreToVendor": {
                            "type": "UInt",
                            "keyRaw": "daysToReturnCoreToVendor",
                            "visible": true
                        },

                        "daysToReturnToVendor": {
                            "type": "UInt",
                            "keyRaw": "daysToReturnToVendor",
                            "visible": true
                        },

                        "defaultVendor": {
                            "type": "Vendor",
                            "keyRaw": "defaultVendor",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "companyName": {
                                        "type": "String",
                                        "keyRaw": "companyName",
                                        "visible": true
                                    },

                                    "contactName": {
                                        "type": "String",
                                        "keyRaw": "contactName",
                                        "visible": true
                                    },

                                    "coreClassHistory": {
                                        "type": "String",
                                        "keyRaw": "coreClassHistory",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "deplete": {
                            "type": "Boolean",
                            "keyRaw": "deplete",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "distributorCorePrice": {
                            "type": "Decimal2",
                            "keyRaw": "distributorCorePrice",
                            "visible": true
                        },

                        "distributorPrice": {
                            "type": "Decimal2",
                            "keyRaw": "distributorPrice",
                            "visible": true
                        },

                        "enteredBy": {
                            "type": "UserAccount",
                            "keyRaw": "enteredBy",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        },

                        "freezeUntil": {
                            "type": "Date",
                            "keyRaw": "freezeUntil",
                            "nullable": true,
                            "visible": true
                        },

                        "glCategory": {
                            "type": "GlCategory",
                            "keyRaw": "glCategory",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryId": {
                            "type": "Int",
                            "keyRaw": "inventoryId",
                            "visible": true
                        },

                        "inventoryOptions": {
                            "type": "InventoryOptionValue",
                            "keyRaw": "inventoryOptions",

                            "selection": {
                                "fields": {
                                    "inventoryOptionValueId": {
                                        "type": "Int",
                                        "keyRaw": "inventoryOptionValueId",
                                        "visible": true
                                    },

                                    "optionId": {
                                        "type": "Int",
                                        "keyRaw": "optionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "option": {
                                        "type": "InventoryOption",
                                        "keyRaw": "option",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "defaultChoice": {
                                                    "type": "String",
                                                    "keyRaw": "defaultChoice",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "required": {
                                                    "type": "Boolean",
                                                    "keyRaw": "required",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "Int",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "dataType": {
                                                    "type": "DataTypeEnum",
                                                    "keyRaw": "dataType",
                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "manufacturer": {
                                                    "type": "Manufacturer",
                                                    "keyRaw": "manufacturer",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "model": {
                                                    "type": "Model",
                                                    "keyRaw": "model",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "category": {
                                                    "type": "Category",
                                                    "keyRaw": "category",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "inventoryType": {
                                                    "type": "InventoryType",
                                                    "keyRaw": "inventoryType",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "choices": {
                                                    "type": "InventoryOptionChoice",
                                                    "keyRaw": "choices",

                                                    "selection": {
                                                        "fields": {
                                                            "default": {
                                                                "type": "Boolean",
                                                                "keyRaw": "default",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "label": {
                                                                "type": "String",
                                                                "keyRaw": "label",
                                                                "visible": true
                                                            },

                                                            "rank": {
                                                                "type": "Int",
                                                                "keyRaw": "rank",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "inventoryId": {
                                        "type": "Int",
                                        "keyRaw": "inventoryId",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "InventoryOptionValueData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryTypeId": {
                            "type": "UInt",
                            "keyRaw": "inventoryTypeId",
                            "visible": true
                        },

                        "jobberCorePrice": {
                            "type": "Decimal2",
                            "keyRaw": "jobberCorePrice",
                            "visible": true
                        },

                        "jobberPrice": {
                            "type": "Decimal2",
                            "keyRaw": "jobberPrice",
                            "visible": true
                        },

                        "interchangeNumber": {
                            "type": "String",
                            "keyRaw": "interchangeNumber",
                            "nullable": true,
                            "visible": true
                        },

                        "inventoryType": {
                            "type": "InventoryType",
                            "keyRaw": "inventoryType",

                            "selection": {
                                "fields": {
                                    "typeData1History": {
                                        "type": "String",
                                        "keyRaw": "typeData1History",
                                        "visible": true
                                    },

                                    "typeData2History": {
                                        "type": "String",
                                        "keyRaw": "typeData2History",
                                        "visible": true
                                    },

                                    "typeData3History": {
                                        "type": "String",
                                        "keyRaw": "typeData3History",
                                        "visible": true
                                    },

                                    "typeData4History": {
                                        "type": "String",
                                        "keyRaw": "typeData4History",
                                        "visible": true
                                    },

                                    "setId": {
                                        "type": "Int",
                                        "keyRaw": "setId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "listPrice": {
                            "type": "Decimal2",
                            "keyRaw": "listPrice",
                            "visible": true
                        },

                        "locations": {
                            "type": "InventoryLocation",
                            "keyRaw": "locations",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "holdQuantity": {
                                        "type": "Decimal6",
                                        "keyRaw": "holdQuantity",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "allowInventory": {
                                                    "type": "Boolean",
                                                    "keyRaw": "allowInventory",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "locationId": {
                                        "type": "UInt",
                                        "keyRaw": "locationId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "permanent": {
                                        "type": "Boolean",
                                        "keyRaw": "permanent",
                                        "visible": true
                                    },

                                    "quantity": {
                                        "type": "Decimal6",
                                        "keyRaw": "quantity",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "UInt",
                                        "keyRaw": "rank",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "maxQuantity": {
                            "type": "Decimal6",
                            "keyRaw": "maxQuantity",
                            "visible": true
                        },

                        "minQuantity": {
                            "type": "Decimal2",
                            "keyRaw": "minQuantity",
                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "visible": true
                        },

                        "oemNumber": {
                            "type": "String",
                            "keyRaw": "oemNumber",
                            "nullable": true,
                            "visible": true
                        },

                        "parentModel": {
                            "type": "Model",
                            "keyRaw": "parentModel",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "parentManufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "parentManufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "partNumber": {
                            "type": "String",
                            "keyRaw": "partNumber",
                            "visible": true
                        },

                        "popularityCode": {
                            "type": "String",
                            "keyRaw": "popularityCode",
                            "visible": true
                        },

                        "printTag": {
                            "type": "Boolean",
                            "keyRaw": "printTag",
                            "nullable": true,
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "quantity": {
                            "type": "Decimal6",
                            "keyRaw": "quantity",
                            "visible": true
                        },

                        "quantityAvailable": {
                            "type": "Decimal6",
                            "keyRaw": "quantityAvailable",
                            "visible": true
                        },

                        "quantityOnHold": {
                            "type": "Decimal6",
                            "keyRaw": "quantityOnHold",
                            "visible": true
                        },

                        "replenishable": {
                            "type": "Boolean",
                            "keyRaw": "replenishable",
                            "visible": true
                        },

                        "retailCorePrice": {
                            "type": "Decimal2",
                            "keyRaw": "retailCorePrice",
                            "visible": true
                        },

                        "retailPrice": {
                            "type": "Decimal2",
                            "keyRaw": "retailPrice",
                            "visible": true
                        },

                        "returnable": {
                            "type": "Boolean",
                            "keyRaw": "returnable",
                            "visible": true
                        },

                        "returnableToVendor": {
                            "type": "Boolean",
                            "keyRaw": "returnableToVendor",
                            "visible": true
                        },

                        "saleClass": {
                            "type": "SaleClass",
                            "keyRaw": "saleClass",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "serials": {
                            "type": "InventorySerial",
                            "keyRaw": "serials(filter: $serialFilter)",

                            "selection": {
                                "fields": {
                                    "inventoryOptions": {
                                        "type": "InventoryOptionValue",
                                        "keyRaw": "inventoryOptions",

                                        "selection": {
                                            "fields": {
                                                "inventoryOptionValueId": {
                                                    "type": "Int",
                                                    "keyRaw": "inventoryOptionValueId",
                                                    "visible": true
                                                },

                                                "optionId": {
                                                    "type": "Int",
                                                    "keyRaw": "optionId",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "InventoryOption",
                                                    "keyRaw": "option",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "defaultChoice": {
                                                                "type": "String",
                                                                "keyRaw": "defaultChoice",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "required": {
                                                                "type": "Boolean",
                                                                "keyRaw": "required",
                                                                "visible": true
                                                            },

                                                            "rank": {
                                                                "type": "Int",
                                                                "keyRaw": "rank",
                                                                "visible": true
                                                            },

                                                            "dataType": {
                                                                "type": "DataTypeEnum",
                                                                "keyRaw": "dataType",
                                                                "visible": true
                                                            },

                                                            "public": {
                                                                "type": "Boolean",
                                                                "keyRaw": "public",
                                                                "visible": true
                                                            },

                                                            "manufacturer": {
                                                                "type": "Manufacturer",
                                                                "keyRaw": "manufacturer",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "Int",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "model": {
                                                                "type": "Model",
                                                                "keyRaw": "model",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "Int",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "category": {
                                                                "type": "Category",
                                                                "keyRaw": "category",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "Int",
                                                                            "keyRaw": "id",
                                                                            "visible": true,
                                                                            "nullable": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "inventoryType": {
                                                                "type": "InventoryType",
                                                                "keyRaw": "inventoryType",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "choices": {
                                                                "type": "InventoryOptionChoice",
                                                                "keyRaw": "choices",

                                                                "selection": {
                                                                    "fields": {
                                                                        "default": {
                                                                            "type": "Boolean",
                                                                            "keyRaw": "default",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        },

                                                                        "label": {
                                                                            "type": "String",
                                                                            "keyRaw": "label",
                                                                            "visible": true
                                                                        },

                                                                        "rank": {
                                                                            "type": "Int",
                                                                            "keyRaw": "rank",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "Int",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "inventoryId": {
                                                    "type": "Int",
                                                    "keyRaw": "inventoryId",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "InventoryOptionValueData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    },

                                    "dateEntered": {
                                        "type": "Date",
                                        "keyRaw": "dateEntered",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "enteredOnDocumentId": {
                                        "type": "Int",
                                        "keyRaw": "enteredOnDocumentId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "enteredOnDocumentLineId": {
                                        "type": "Int",
                                        "keyRaw": "enteredOnDocumentLineId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "enteredOnDocumentStoreId": {
                                        "type": "Int",
                                        "keyRaw": "enteredOnDocumentStoreId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "enteredOnDocumentType": {
                                        "type": "DocumentTypeEnum",
                                        "keyRaw": "enteredOnDocumentType",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "LocationUnion",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "abstractFields": {
                                                "fields": {
                                                    "Location": {
                                                        "id": {
                                                            "type": "Int",
                                                            "keyRaw": "id",
                                                            "visible": true,
                                                            "nullable": true
                                                        },

                                                        "name": {
                                                            "type": "String",
                                                            "keyRaw": "name",
                                                            "visible": true
                                                        },

                                                        "description": {
                                                            "type": "String",
                                                            "keyRaw": "description",
                                                            "nullable": true,
                                                            "visible": true
                                                        },

                                                        "__typename": {
                                                            "type": "String",
                                                            "keyRaw": "__typename",
                                                            "visible": true
                                                        }
                                                    },

                                                    "VirtualLocation": {
                                                        "name": {
                                                            "type": "String",
                                                            "keyRaw": "name",
                                                            "visible": true
                                                        },

                                                        "__typename": {
                                                            "type": "String",
                                                            "keyRaw": "__typename",
                                                            "visible": true
                                                        }
                                                    }
                                                },

                                                "typeMap": {}
                                            },

                                            "fields": {
                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "abstract": true,
                                        "visible": true
                                    },

                                    "number": {
                                        "type": "String",
                                        "keyRaw": "number",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "InventoryStatusEnum",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "usedOnDocumentId": {
                                        "type": "Int",
                                        "keyRaw": "usedOnDocumentId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "usedOnDocumentStoreId": {
                                        "type": "Int",
                                        "keyRaw": "usedOnDocumentStoreId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "usedOnDocumentType": {
                                        "type": "DocumentTypeEnum",
                                        "keyRaw": "usedOnDocumentType",
                                        "nullable": true,
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "SerialOptionValues": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "safetyStockPercent": {
                            "type": "Decimal2",
                            "keyRaw": "safetyStockPercent",
                            "visible": true
                        },

                        "seasonal": {
                            "type": "Boolean",
                            "keyRaw": "seasonal",
                            "visible": true
                        },

                        "sellPackage": {
                            "type": "Int",
                            "keyRaw": "sellPackage",
                            "visible": true
                        },

                        "sellPriceClass": {
                            "type": "SellPriceClass",
                            "keyRaw": "sellPriceClass",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "serialized": {
                            "type": "Boolean",
                            "keyRaw": "serialized",
                            "visible": true
                        },

                        "side": {
                            "type": "SideEnum",
                            "keyRaw": "side",
                            "visible": true
                        },

                        "singleQuantity": {
                            "type": "Boolean",
                            "keyRaw": "singleQuantity",
                            "visible": true
                        },

                        "shippingDimensions": {
                            "type": "Dimensions",
                            "keyRaw": "shippingDimensions",

                            "selection": {
                                "fields": {
                                    "weightUnit": {
                                        "type": "DimensionsWeightUnit",
                                        "keyRaw": "weightUnit",
                                        "visible": true
                                    },

                                    "measurementUnit": {
                                        "type": "DimensionsMeasurementUnit",
                                        "keyRaw": "measurementUnit",
                                        "visible": true
                                    },

                                    "weight": {
                                        "type": "Decimal6",
                                        "keyRaw": "weight",
                                        "visible": true
                                    },

                                    "length": {
                                        "type": "Decimal2",
                                        "keyRaw": "length",
                                        "visible": true
                                    },

                                    "width": {
                                        "type": "Decimal2",
                                        "keyRaw": "width",
                                        "visible": true
                                    },

                                    "height": {
                                        "type": "Decimal2",
                                        "keyRaw": "height",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "sku": {
                            "type": "UInt",
                            "keyRaw": "sku",
                            "visible": true
                        },

                        "status": {
                            "type": "StatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "stockCategory": {
                            "type": "StockCategory",
                            "keyRaw": "stockCategory",
                            "nullable": true,
                            "visible": true
                        },

                        "stockingDays": {
                            "type": "Int",
                            "keyRaw": "stockingDays",
                            "visible": true
                        },

                        "stockMethod": {
                            "type": "StockMethodEnum",
                            "keyRaw": "stockMethod",
                            "visible": true
                        },

                        "stockType": {
                            "type": "StockTypeEnum",
                            "keyRaw": "stockType",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "suggestedMaxQuantity": {
                            "type": "Decimal2",
                            "keyRaw": "suggestedMaxQuantity",
                            "visible": true
                        },

                        "suggestedMinQuantity": {
                            "type": "Decimal2",
                            "keyRaw": "suggestedMinQuantity",
                            "visible": true
                        },

                        "suggestedSafetyStockPercent": {
                            "type": "Decimal6",
                            "keyRaw": "suggestedSafetyStockPercent",
                            "visible": true
                        },

                        "tagNumber": {
                            "type": "String",
                            "keyRaw": "tagNumber",
                            "visible": true
                        },

                        "taxable": {
                            "type": "Boolean",
                            "keyRaw": "taxable",
                            "visible": true
                        },

                        "typeField1": {
                            "type": "InventoryTypeField",
                            "keyRaw": "typeField1",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "data": {
                                        "type": "String",
                                        "keyRaw": "data",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "typeField2": {
                            "type": "InventoryTypeField",
                            "keyRaw": "typeField2",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "data": {
                                        "type": "String",
                                        "keyRaw": "data",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "typeField3": {
                            "type": "InventoryTypeField",
                            "keyRaw": "typeField3",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "data": {
                                        "type": "String",
                                        "keyRaw": "data",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "typeField4": {
                            "type": "InventoryTypeField",
                            "keyRaw": "typeField4",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "data": {
                                        "type": "String",
                                        "keyRaw": "data",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userStatus": {
                            "type": "String",
                            "keyRaw": "userStatus",
                            "nullable": true,
                            "visible": true
                        },

                        "useVendorOrderMultiplier": {
                            "type": "Boolean",
                            "keyRaw": "useVendorOrderMultiplier",
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Vehicle",
                            "keyRaw": "vehicle",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "vin": {
                                        "type": "String",
                                        "keyRaw": "vin",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "year": {
                                        "type": "UInt",
                                        "keyRaw": "year",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "bodyStyle": {
                                        "type": "String",
                                        "keyRaw": "bodyStyle",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "stockNumber": {
                                        "type": "String",
                                        "keyRaw": "stockNumber",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "topImage": {
                                        "type": "File",
                                        "keyRaw": "topImage",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "vehicleId": {
                            "type": "UInt",
                            "keyRaw": "vehicleId",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicleMake": {
                            "type": "String",
                            "keyRaw": "vehicleMake",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicleModel": {
                            "type": "String",
                            "keyRaw": "vehicleModel",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicleVin": {
                            "type": "String",
                            "keyRaw": "vehicleVin",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicleYear": {
                            "type": "UInt",
                            "keyRaw": "vehicleYear",
                            "nullable": true,
                            "visible": true
                        },

                        "vendorLeadTime": {
                            "type": "Int",
                            "keyRaw": "vendorLeadTime",
                            "visible": true
                        },

                        "vendorPopularityCode": {
                            "type": "String",
                            "keyRaw": "vendorPopularityCode",
                            "visible": true
                        },

                        "vendorProductCode": {
                            "type": "String",
                            "keyRaw": "vendorProductCode",
                            "nullable": true,
                            "visible": true
                        },

                        "wholesaleCorePrice": {
                            "type": "Decimal2",
                            "keyRaw": "wholesaleCorePrice",
                            "visible": true
                        },

                        "wholesalePrice": {
                            "type": "Decimal2",
                            "keyRaw": "wholesalePrice",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "InventoryData": {
                            "arguments": {
                                "serialFilter": {
                                    "kind": "Variable",

                                    "name": {
                                        "kind": "Name",
                                        "value": "serialFilter"
                                    }
                                }
                            }
                        },

                        "EnteredBy": {
                            "arguments": {}
                        },

                        "InventoryOptionValues": {
                            "arguments": {}
                        },

                        "ShippingDimensions": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "innodbInventoryid": "UInt",
            "serialFilter": "InventorySerialFilter"
        },

        "types": {
            "InventorySerialFilter": {
                "number": "String",
                "statuses": "InventoryStatusEnum"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1e13ca264f438924bbec907171ea1fe0a7e96cfe474b651f4e8229af7a5abd13";