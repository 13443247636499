import pProps from 'p-props'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import template from './teardown.html'
import myTeardownItem from 'classes/myTeardownItem'
import DefaultPart from 'classes/DefaultPart'
import * as querylib from 'common/graphql-queries'
import debugLog from 'debug'
debugLog('enterpriseweb')
import makeTeardown from 'components/teardown'
import makeCheckbox from '@isoftdata/checkbox'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.vehicle.teardown',
		route: 'teardown',
		querystringParameters: [ 'vehicleId', 'storeId', 'vehicleBidId' ],
		defaultParameters: { 'vehicleBidId': '' },
		template: {
			twoway: false,
			template,
			components: {
				teardown: makeTeardown(mediator),
				itCheckbox: makeCheckbox({ twoway: true }),
			},
		},
		async resolve(data, parameters) {
			const vehicleId = parseInt(parameters.vehicleId, 10)
			const defaultPart = new DefaultPart(vehicleId, parameters.storeId)

			const res = await pProps({
				inventoryFieldAccess: (async() => (await mediator.publish('graphqlFetchWithCache', { query: querylib.fieldAccessQuery }))?.session.fieldAccess.inventory)(),
				userPartStatusList: (async() => (await mediator.publish('graphqlFetchWithCache', { query: querylib.userStatusListQuery }))?.userPartStatusList)(),
				inventoryConditions: (async() => (await mediator.publish('graphqlFetchWithCache', { query: querylib.inventoryConditions }))?.inventoryConditions)(),
				inventoryTypeList: (async() => (await mediator.publish('graphqlFetchWithCache', { query: querylib.inventoryTypesQuery, minutesToLive: 300, mutator: res => {
					const inventoryTypeList = sortArrayByObjectKey({ array: res.inventoryTypeList, key: 'name' })
						.map(obj => {
							obj.partManufacturers = sortArrayByObjectKey({ array: obj.partManufacturers, key: 'name' })
							return obj
						})

					return inventoryTypeList
				} })))(),
				parts: await mediator.publish('graphqlFetchPath', querylib.vehicleinventoryquery, { vehicleId }, 'vehicle.inventories'),
				vehicle: await mediator.publish('graphqlFetchPath', querylib.vehiclequery, { vehicleId }, 'vehicle'),
				breakdownTemplate: (async() => {
					const res = await mediator.publish('graphqlFetchPath', querylib.vehiclebreakdowntemplatequery, { vehicleId }, 'vehicle.wholeUnit.inventoryType.breakdownTemplates')

					return res.map(template => {
						const { breakdownTemplateId, default: isDefault, description, name } = template
						return template.components.map(item => {
							return {
								...item,
								breakdownTemplateId,
								default: isDefault,
								description,
								name,
							}
						})
					}).flat()
				})(),
				defaultPart,
				teardownType: 'INVENTORY',
				partModelList: [],
				fileCounts: [],

			})

			res.parts = res.parts.map(part => {
				return new myTeardownItem({
					...part,
					modelId: part?.model?.id || null,
					manufacturerId: part?.manufacturer?.id || null,
					parentModelId: part.parentModel?.id || null,
					parentManufacturerId: part.parentManufacturer?.id || null,
					locked: true,
				},	res.inventoryTypeList)
			})

			res.template = res.breakdownTemplate.map(template => {
				return { ...defaultPart, inventoryTypeId: template.inventoryTypeId,	breakdownTemplateId: template.breakdownTemplateId, rank: template.rank }
			})

			const listViewSelection = parseInt(parameters.listViewSelection, 10) || 0

			return {
				...res,
				parentType: 'VEHICLE',
				parent: {
					vehicleId: parameters.vehicleId,
					storeId: parameters.storeId,
				},
				listViewSelection: (listViewSelection >= res.parts.length) ? 0 : listViewSelection,
			}
		},
		activate(context) {
			const ractive = context.domApi

			ractive.observe('vehicle.dismantled', async dismantled => {
				console.log(dismantled)
				const variables = {
					VehicleUpdateData: {
						id: parseInt(ractive.get('parent.vehicleId'), 10),
						dismantled,
					},
				}

				await mediator.publish('graphqlFetch', querylib.updateVehicleMutation, variables)
			}, { init: false })

			ractive.observe('listViewSelection', listViewSelection => {
				stateRouter.go(null, { listViewSelection }, { replace: true, inherit: true })
			})
		},
	})
}

