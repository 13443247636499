<script lang="ts">
	import type { Model, InventoryType, Manufacturer, SearchField } from 'utility/search-fields'
	type Type = 'ASSEMBLY' | 'PART' | 'VEHICLE'

	import Pin from 'components/Pin.svelte'
	import { startsWithVowel } from 'utility/language-tools'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import { loadPartModelsForInventoryTypeAndManufacturer } from 'utility/search-fields'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let model: Model | null = null
	export let inventoryType: InventoryType | null = null
	export let manufacturer: Manufacturer | null
	export let modelList: Promise<Model[]> | Model[] = []
	export let type: Type // no default, require that they pass it
	export let disabled: boolean = false

	async function loadModels({ inventoryType, manufacturer }: { inventoryType: InventoryType | null; manufacturer: Manufacturer | null }) {
		if (inventoryType || manufacturer) {
			modelList = loadPartModelsForInventoryTypeAndManufacturer({
				inventoryTypeId: type === 'ASSEMBLY' ? inventoryType?.setId ?? inventoryType?.id : inventoryType?.id,
				manufacturerId: manufacturer?.id,
			})
			modelList.then(loadedModels => {
				//If we have a selected model but after loading the new list, it's not in the list, then we need to the clear the selection
				if (model && !loadedModels.find(loadedModel => loadedModel.id === model?.id)) {
					model = null
				}
			})
		} else {
			modelList = []
			model = null
		}
	}

	function computePlaceholder(type: Type, inventoryType: InventoryType | null, manufacturer: Manufacturer | null) {
		const typeLabel = typeLabelMap.get(type)
		if (inventoryType && !manufacturer) {
			return `Select ${startsWithVowel(typeLabel?.parentLabel ?? '') ? 'an' : 'a'} ${typeLabel?.parentLabel ?? ''} to shorten this list`
		} else if (!inventoryType && type !== 'VEHICLE') {
			return 'Select an inventory type first'
		} else {
			return `Select ${startsWithVowel(typeLabel?.label ?? '') ? 'an' : 'a'} ${typeLabel?.label ?? ''}`
		}
	}

	interface Label {
		label: string
		parentLabel: string
	}
	const typeLabelMap = new Map<Type, Label>([
		[
			'ASSEMBLY',
			{
				label: 'Assembly Model',
				parentLabel: 'Assembly Manufacturer',
			},
		],
		[
			'PART',
			{
				label: 'Part Model',
				parentLabel: 'Part Manufacturer',
			},
		],
		[
			'VEHICLE',
			{
				label: 'Vehicle Model',
				parentLabel: 'Vehicle Make',
			},
		],
	])

	$: loadModels({ inventoryType, manufacturer })
	$: placeholder = computePlaceholder(type, inventoryType, manufacturer)
	$: label = typeLabelMap.get(type)?.label ?? 'Model'
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		{label}
		getLabel={option => option?.name ?? ''}
		options={modelList}
		bind:value={model}
		emptyValue={null}
		{placeholder}
		disabled={disabled || (!inventoryType && type !== 'VEHICLE') || !manufacturer}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
