export default {
    "name": "VehicleComponents",
    "kind": "HoudiniQuery",
    "hash": "7a8bbeb1004fdf01fd329aaa3a76d9580a269c6423f64d1c7d98b7f3ea4c16f0",

    "raw": `query VehicleComponents($vehicleId: Int!) {
  vehicle(id: $vehicleId) {
    components {
      id
      tagNumber
      retailPrice
      inventoryType {
        name
        id
      }
      manufacturer {
        name
        id
      }
      model {
        name
        id
      }
      category {
        name
        id
      }
      topImage {
        url
        id
      }
    }
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "vehicle": {
                "type": "Vehicle",
                "keyRaw": "vehicle(id: $vehicleId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "components": {
                            "type": "Inventory",
                            "keyRaw": "components",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "tagNumber": {
                                        "type": "String",
                                        "keyRaw": "tagNumber",
                                        "visible": true
                                    },

                                    "retailPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailPrice",
                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "category": {
                                        "type": "Category",
                                        "keyRaw": "category",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "topImage": {
                                        "type": "File",
                                        "keyRaw": "topImage",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "vehicleId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ddec3b0213010fd961760637bb24b16c5daf44e42e095a0dc82259429461eec9";