export default {
    "name": "Categories",
    "kind": "HoudiniQuery",
    "hash": "9f37831433d6329c4ad8c1cb21f2b235f470c15cbf8f34ed228b1fa632d6f197",

    "raw": `query Categories($inventoryTypeId: Int!) {
  inventoryType(id: $inventoryTypeId) {
    categories {
      id
      name
    }
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType(id: $inventoryTypeId)",

                "selection": {
                    "fields": {
                        "categories": {
                            "type": "Category",
                            "keyRaw": "categories",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=78a32dcfadad0a2380762be55ef3d1fc60a8b610b2d75c794f6c9112ba2ad94f";