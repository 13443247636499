<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import type { Vehicle$result } from '$houdini'
	type Vehicle = Vehicle$result['vehicle']

	import { graphql } from '$houdini'
	import Icon from '@isoftdata/svelte-icon'
	import titleCase from 'to-title-case'
	import Button from '@isoftdata/svelte-button'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher<{ imageClicked: void }>()

	const vehicleQuery = graphql(`
		query Vehicle($vehicleId: Int!) {
			vehicle(id: $vehicleId) {
				topImage {
					url
				}
				attachmentCount
				bodyStyle
				category
				claimNumber
				componentCount
				condition
				dateEntered
				description
				dismantled
				externalColor
				externalColorCode
				make
				mileage
				model {
					name
				}
				receivedDate
				stockNumber
				storeId
				vin
				internalColor
				internalColorCode
				location
				notes
				status
				userStatus
				lastChanged
				titleNumber
				titleStatus
				teardownDate
				titleType
				titleReceived
				purchasedFrom
				sellerContact
				sellerPhone
				sellerInfo
				purchaseDate
				titleState
				year
				id
			}
		}
	`)

	export let vehicleId: number | undefined
	export let asr: SvelteAsr
	export let showEditButton: boolean = true

	let vehicle: Vehicle | Promise<Vehicle | undefined> | undefined = undefined

	async function loadVehicle(vehicleId?: number) {
		return vehicleId ? (await vehicleQuery.fetch({ variables: { vehicleId }, policy: 'CacheOrNetwork' }))?.data?.vehicle ?? undefined : undefined
	}

	function loadAndSetVehicle(vehicleId?: number) {
		vehicle = loadVehicle(vehicleId)
	}

	$: loadAndSetVehicle(vehicleId)
</script>

{#await vehicle}
	<Icon
		icon="spinner"
		class="fa-xl fa-spin"
	/>
{:then loadedVehicle}
	{#if loadedVehicle}
		{#if loadedVehicle.topImage?.url}
			<div class="d-flex justify-content-center">
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
				<img
					class="w-50 rounded mb-2 cursor-pointer"
					src={loadedVehicle.topImage.url}
					alt="A photograph of the vehicle"
					on:click={() => dispatch('imageClicked')}
				/>
			</div>
		{/if}
		{#if showEditButton}
			<Button
				outline
				iconClass="pencil"
				class="mt-2 mb-2"
				size="sm"
				href={asr.makePath('app.vehicle', { vehicleId: loadedVehicle.id, storeId: loadedVehicle.storeId })}>Edit Vehicle</Button
			>
		{/if}
		<ul class="list-group">
			{#each Object.entries(loadedVehicle) as [key, value]}
				{#if value && (value instanceof Date || typeof value !== 'object')}
					<li class="list-group-item">
						<strong>{titleCase(key)}</strong>: {value instanceof Date ? value.toLocaleDateString() : value}
					</li>
				{/if}
			{/each}
		</ul>
	{:else}
		<div
			class="alert alert-info mt-3 mb-0"
			role="alert"
		>
			No vehicle info available for this part.
		</div>
	{/if}
{:catch error}
	<p>Error loading part Vehicles.</p>
{/await}
