import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/InventoryLocations'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class InventoryLocationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "InventoryLocationsStore",
			variables: true,
		})
	}
}

export async function load_InventoryLocations(params) {
	await initClient()

	const store = new InventoryLocationsStore()

	await store.fetch(params)

	return {
		InventoryLocations: store,
	}
}
