<script lang="ts">
	import type { SavedAttachmentFile } from '@isoftdata/svelte-attachments'
	interface Attachment {
		readonly id: number
		readonly public: boolean
		readonly rank: number | null
		readonly file: {
			readonly id: number
			readonly name: string
			readonly path: string
			readonly mimetype: string
			readonly size: number
		}
	}

	import { v4 as uuid } from '@lukeed/uuid'
	import { Table, ObjectViewerModal } from '@isoftdata/svelte-attachments'
	import ImageViewerModal from '@isoftdata/svelte-image-viewer'

	export let attachments: Attachment[] = []
	let fileToView: SavedAttachmentFile | undefined = undefined
	let showObjectViewerModal: boolean = false
	let showImageViewerModal: boolean = false
	let attachmentsTableFileList: SavedAttachmentFile[] = []
	let filePaths: string[] = []
	let index: number = 0

	$: attachmentsTableFileList = attachments.map(attachment => {
		return {
			fileId: attachment.id,
			public: attachment.public,
			rank: attachment.rank || 1,
			mimeType: attachment.file.mimetype,
			name: attachment.file.name,
			size: attachment.file.size,
			path: attachment.file.path,
			uuid: uuid(),
		}
	})

	function attachmentClicked(file: SavedAttachmentFile) {
		const isImageFile = file.mimeType.startsWith('image/')
		if (isImageFile) {
			openImageViewerModal(file.fileId)
		} else {
			openObjectViewerModal(file)
		}
	}

	function openObjectViewerModal(file: SavedAttachmentFile) {
		showObjectViewerModal = true
		fileToView = file
	}

	export function openImageViewerModal(fileId: number) {
		const imageFiles = attachmentsTableFileList.filter(file => file.mimeType.startsWith('image/'))
		filePaths = imageFiles.reduce((acc: string[], attachment) => {
			return attachment.path ? [...acc, attachment.path] : acc
		}, [])
		index = imageFiles.findIndex(file => file.fileId === fileId)
		showImageViewerModal = attachmentsTableFileList.length > 0
		fileToView = imageFiles[index]
	}
</script>

<Table
	fileList={attachmentsTableFileList}
	modificationDisabled
	on:rowClick={e => {
		if (e.detail) {
			const file = attachmentsTableFileList.find(attachment => attachment.uuid === e.detail)
			if (file) {
				attachmentClicked(file)
			}
		}
	}}
/>

{#if fileToView}
	<ImageViewerModal
		bind:show={showImageViewerModal}
		files={filePaths}
		bind:currentPhotoIndex={index}
		showIndicators={false}
	/>
	<ObjectViewerModal
		bind:show={showObjectViewerModal}
		file={fileToView}
	/>
{/if}
