export default {
    "name": "ServerInformation",
    "kind": "HoudiniQuery",
    "hash": "12893e9ead10ea7d628a27abc00f411a6f9f64ecdbf0b069caa08726e6722353",

    "raw": `query ServerInformation {
  serverInformation {
    schemaVersion
    releaseVersionNumber
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "serverInformation": {
                "type": "ServerInformation",
                "keyRaw": "serverInformation",

                "selection": {
                    "fields": {
                        "schemaVersion": {
                            "type": "String",
                            "keyRaw": "schemaVersion",
                            "visible": true
                        },

                        "releaseVersionNumber": {
                            "type": "String",
                            "keyRaw": "releaseVersionNumber",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=aac07ed6d92a17baf864662a5d76b23373a0eb652e45a7297fab0f72a3f0bda3";