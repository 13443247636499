export default {
    "name": "Models",
    "kind": "HoudiniQuery",
    "hash": "40a1f5bcf8242e0a1aad29e3ad37b9dd41d90d2378f56ec8f83683ae1d4d52b6",

    "raw": `query Models($filter: ModelFilter) {
  models(filter: $filter) {
    name
    id
    active
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "models": {
                "type": "Model",
                "keyRaw": "models(filter: $filter)",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ModelFilter"
        },

        "types": {
            "ModelFilter": {
                "active": "Boolean",
                "inventoryTypeId": "UInt",
                "manufacturerId": "UInt",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=fe46eabc335f9f72200ed2d3cd9dc5b7013c5dc7178896d81c789a1261afa144";