<script lang="ts">
	import type { SearchField } from 'utility/search-fields'

	import Pin from 'components/Pin.svelte'
	import EditModeControls from 'components/EditModeControls.svelte'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher<{ change: { field: SearchField; value: boolean } }>()

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let checked: boolean = true

	/* We need to ensure that we dispatch a change event when the boolean search field is initally added
	This is different from all of the other fields, which don't have a value until the user interacts with them */
	$: dispatch('change', { field: searchField, value: checked })
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Checkbox
		label="Dismantled"
		type="radio"
		btnGroupClass="mb-1 w-100"
		bind:checked
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Checkbox>
</EditModeControls>
