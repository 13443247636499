import { writable, type Writable } from 'svelte/store'

/**
 * The idea here is to basically allow sending an object as a key to a map,
 * and then the key will be constructed from a couple properties of that object.
 * For example, I may want to store references to an Inventory Option by its name, type, and associated serial #.
 */

type MapValue<KeyObj, ValueType> = { value: ValueType; rawKey: Partial<KeyObj> }
type MapType<KeyObj, ValueType> = Map<string, MapValue<KeyObj, ValueType>>

export default class ObjectMap<KeyObj extends Record<string, unknown>, ValueType> {
	constructor(keyParts: Array<keyof KeyObj> = [], entries: Array<[Partial<KeyObj>, ValueType]>) {
		this.keyParts = keyParts
		this.map = new Map<string, MapValue<KeyObj, ValueType>>(
			entries.map(([obj, value]) => this.#getKeyValue(obj, value))
		)
		this.store = writable(this.map)
		this.subscribe = this.store.subscribe
	}

	keyParts: Array<keyof KeyObj>
	store: Writable<MapType<KeyObj, ValueType>>
	map: MapType<KeyObj, ValueType>

	public subscribe: (typeof this.store)['subscribe']

	#getKeyValue(obj: Partial<KeyObj>, value: ValueType): [string, MapValue<KeyObj, ValueType>] {
		const key = this.getStringKey(obj)
		if (!key) {
			throw new Error('Cannot set an object with no key parts')
		}
		return [key, { value, rawKey: obj }]
	}

	get(obj: Partial<KeyObj> | string) {
		if (typeof obj === 'string') {
			return this.map.get(obj)?.value
		}
		const key = this.getStringKey(obj)
		return key ? this.map.get(key)?.value : undefined
	}

	set(obj: Partial<KeyObj>, value: ValueType) {
		const key = this.getStringKey(obj)
		if (!key) {
			throw new Error('Cannot set an object with no key parts')
		}
		this.map.set(...this.#getKeyValue(obj, value))
		this.store.set(this.map)
	}

	has(obj: Partial<KeyObj>) {
		const key = this.getStringKey(obj)
		return key ? this.map.has(key) : false
	}

	delete(obj: Partial<KeyObj>) {
		const key = this.getStringKey(obj)
		return key ? this.map.delete(key) : false
	}

	clear() {
		return this.map.clear()
	}

	keys() {
		return Array.from(this.map.values()).map(({ rawKey }) => rawKey)
	}

	values() {
		return Array.from(this.map.values()).map(({ value }) => value)
	}

	entries(): Array<[Partial<KeyObj>, ValueType]> {
		return Array.from(this.map.values()).map(({ rawKey, value }) => [rawKey, value])
	}

	get [Symbol.toStringTag]() {
		return this.map[Symbol.toStringTag]
	}

	get size() {
		return this.map.size
	}

	getStringKey(obj: Partial<KeyObj>) {
		return ObjectMap.getStringKeyStatic(obj, this.keyParts)
	}

	static getStringKeyStatic<T extends Record<string, unknown>>(obj: Partial<T>, keyParts: Array<keyof T>) {
		return keyParts.length
			? keyParts
					.map(keyPart => obj[keyPart])
					.filter(value => value !== undefined && value !== null)
					.join('-')
			: null
	}
}
