<script lang="ts">
	import type { InventoryItem } from './results.ts'
	interface ItemDetailValue {
		name: string
		value: string
	}

	import { format as formatCurrency } from '@isoftdata/utility-currency'

	export let item: InventoryItem

	const itemDetailInfoMap: Map<keyof InventoryItem, (inventoryItem: InventoryItem) => ItemDetailValue> = new Map([
		[
			'storeId',
			item => {
				return {
					name: 'Store',
					value: item['storeId'].toString(),
				}
			},
		],
		[
			'category',
			item => {
				return {
					name: 'Category',
					value: item['category']?.name ?? '',
				}
			},
		],
		[
			'stockCategory',
			item => {
				return {
					name: 'Stock Category',
					value: item['stockCategory'] ?? '',
				}
			},
		],
		[
			'quantity',
			item => {
				return {
					name: 'Quantity',
					value: item['quantity'] ? parseFloat(item['quantity']).toString() : '',
				}
			},
		],
		[
			'retailPrice',
			item => {
				return {
					name: 'Retail Price',
					value: item['retailPrice'] ? formatCurrency(item['retailPrice']) : '',
				}
			},
		],
		[
			'description',
			item => {
				return {
					name: 'Description',
					value: item['description'] ?? '',
				}
			},
		],
		[
			'notes',
			item => {
				return {
					name: 'Notes',
					value: item['notes'] ?? '',
				}
			},
		],
		[
			'typeField1',
			item => {
				return {
					name: item['typeField1']?.label ?? '',
					value: item['typeField1']?.data ?? '',
				}
			},
		],
		[
			'typeField2',
			item => {
				return {
					name: item['typeField2']?.label ?? '',
					value: item['typeField2']?.data ?? '',
				}
			},
		],
		[
			'typeField3',
			item => {
				return {
					name: item['typeField3']?.label ?? '',
					value: item['typeField3']?.data ?? '',
				}
			},
		],
		[
			'typeField4',
			item => {
				return {
					name: item['typeField4']?.label ?? '',
					value: item['typeField4']?.data ?? '',
				}
			},
		],
	])
</script>

<ul class="list-group mt-2">
	{#each itemDetailInfoMap.keys() as itemDetailKey}
		{@const itemDetailListItem = itemDetailInfoMap.get(itemDetailKey)?.(item)}
		{#if item && itemDetailListItem?.value}
			<li
				class="list-group-item"
				style="white-space: wrap;"
			>
				<span class="font-weight-bold">{itemDetailListItem.name}: </span>
				{itemDetailListItem.value}
			</li>
		{/if}
	{/each}
</ul>
