export default {
    "name": "VehicleTitleStatuses",
    "kind": "HoudiniQuery",
    "hash": "77ad980b1cfae4be1271823e3d9785f967564b1f359239b086a1c7e9e6b6773d",

    "raw": `query VehicleTitleStatuses {
  vehicleTitleStatuses
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "vehicleTitleStatuses": {
                "type": "String",
                "keyRaw": "vehicleTitleStatuses",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a0078c531a10b370c7092eab648cd2b64408e19752fd8c17e42d3b56639bf421";