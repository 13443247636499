export default {
    "name": "TypeFields",
    "kind": "HoudiniQuery",
    "hash": "f563b7f52bed72731cf445097f27a35f51ef71af2e46e2471c03a9f98defddda",

    "raw": `query TypeFields($inventoryTypeId: Int!) {
  inventoryType(id: $inventoryTypeId) {
    id
    setId
    typeData1History
    typeData2History
    typeData3History
    typeData4History
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType(id: $inventoryTypeId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "typeData1History": {
                            "type": "String",
                            "keyRaw": "typeData1History",
                            "visible": true
                        },

                        "typeData2History": {
                            "type": "String",
                            "keyRaw": "typeData2History",
                            "visible": true
                        },

                        "typeData3History": {
                            "type": "String",
                            "keyRaw": "typeData3History",
                            "visible": true
                        },

                        "typeData4History": {
                            "type": "String",
                            "keyRaw": "typeData4History",
                            "visible": true
                        },

                        "typeLabel1": {
                            "type": "String",
                            "keyRaw": "typeLabel1",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel2": {
                            "type": "String",
                            "keyRaw": "typeLabel2",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel3": {
                            "type": "String",
                            "keyRaw": "typeLabel3",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel4": {
                            "type": "String",
                            "keyRaw": "typeLabel4",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b347a3c7c27f9522084e2f8732e2134887f2dd7551db43ad94680aab9238b42d";