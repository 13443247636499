export default {
    "name": "InventoryTypes",
    "kind": "HoudiniQuery",
    "hash": "f87f8424fc6db778e096941afeeaa734cab8b3b60241a5b5b55485eb4c11550e",

    "raw": `query InventoryTypes($active: Boolean, $vehicle: Boolean) {
  inventoryTypes(active: $active, vehicle: $vehicle) {
    name
    setId
    id
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventoryTypes": {
                "type": "InventoryType",
                "keyRaw": "inventoryTypes(active: $active, vehicle: $vehicle)",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "typeLabel1": {
                            "type": "String",
                            "keyRaw": "typeLabel1",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel2": {
                            "type": "String",
                            "keyRaw": "typeLabel2",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel3": {
                            "type": "String",
                            "keyRaw": "typeLabel3",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel4": {
                            "type": "String",
                            "keyRaw": "typeLabel4",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "active": "Boolean",
            "vehicle": "Boolean"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0ec5dbd23bdf1f2a155cd229dec84021a5bbaacf377203c912d1281c3f695e9b";