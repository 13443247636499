import { makeDashboardConfigurationComponent } from '@isoftdata/web-dashboard'
import { dashboardReportsForConfiguration, deleteDashboardReportMutation } from 'common/graphql-queries'
import { saveDashboardReportHelper } from 'utility/dashboard-helper'
import { checkSessionPermission } from 'utility/permission'
import template from './dashboard.html'

export default function({ stateRouter, mediator, hasPermission }) {
	stateRouter.addState({
		name: 'app.configuration.dashboard',
		route: 'dashboard',
		querystringParameters: [ 'dashboardReportId' ],
		template: {
			template,
			hasPermission,
			components: {
				itDashboardConfig: makeDashboardConfigurationComponent({
					loadDashboardReportsForConfiguration: async() => {
						const { dashboardReports } = await mediator.publish('graphqlFetch', dashboardReportsForConfiguration, { args: { editableByCurrentUser: true } })
						return dashboardReports
					},
					saveDashboardReport: ({ dashboardReport }) => saveDashboardReportHelper(mediator, { dashboardReport }),
					deleteDashboardReport: async({ dashboardReportId }) => { // Mutation needed
						const { deleteDashboardReport } = await mediator.publish('graphqlFetch', deleteDashboardReportMutation, { dashboardReportId })
						if (!deleteDashboardReport.success) {
							console.error("Report not deleted:\n", deleteDashboardReport)
						}
					},
				}),
			},
		},
		resolve(data, { dashboardReportId }) {
			if (!checkSessionPermission('DASHBOARD_REPORT_EDIT_OWN') && !checkSessionPermission('DASHBOARD_REPORT_EDIT_ALL')) {
				// return new Error("You don't have permission to view the dashboard.")
				console.error("You don't have permission to edit dashboard reports.")
				throw {
					redirectTo: {
						name: 'app', // change this once we have more configuration screens?
						params: {},
					},
				}
			}
			return Promise.resolve({
				dashboardReportId: Number(dashboardReportId),
				defaultParameters: [
					{ name: "date_range", type: "dateRange", title: "Date Range", default: "This Year" },
					{ name: 'site', type: 'siteSelection', title: 'Store', default: '0' },
				],
			})
		},
		activate(context) {
			const { domApi: ractive } = context

			ractive.on('itDashboardConfig.selected-report-changed', (context, dashboardReportId) => {
				if (dashboardReportId) {
					stateRouter.go('app.configuration.dashboard.manage', { dashboardReportId })
				} else {
					// If there's no dashboardReportId, don't go to the manage state
					stateRouter.go('app.configuration.dashboard', { dashboardReportId: null })
				}
			})

			ractive.on('itDashboardConfig.open-report-clicked', (context, dashboardReportId) => {
				stateRouter.go('app.dashboard', { dashboardReportId })
			})
		},

	})
}
