export default {
    "name": "InventoryTypesFull",
    "kind": "HoudiniQuery",
    "hash": "1bd08a4a2a147759026c8c99a30a1a32b6ed68eff11a5a074c458766c9f85b3c",

    "raw": `query InventoryTypesFull {
  inventoryTypes {
    active
    id
    name
    setId
    vehicle
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventoryTypes": {
                "type": "InventoryType",
                "keyRaw": "inventoryTypes",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Boolean",
                            "keyRaw": "vehicle",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=62d8a8112cc0ac62ec35fd303a898eef6b01830ace4ae2da90971cbb673454be";