export default {
    "name": "LIB_settingValues",
    "kind": "HoudiniQuery",
    "hash": "89f4908f343d70bb19b0cc1bd371070d44359d1b64b244ad8c99912e4ed7fd72",

    "raw": `query LIB_settingValues {
  settingValues {
    vehicle {
      userStatusRequired
      minVinLength
      mileageRequired
      flexField4Required
      flexField3Required
      flexField2Required
      flexField1Required
      conditionRequired
    }
    inventory {
      defaultNonReplenishablePartsArePublic
      categoryEditable
      categoryRequired
      categoryVisible
      conditionEditable
      conditionRequired
      conditionVisible
      coreCostEditable
      coreCostRequired
      coreCostVisible
      costEditable
      costRequired
      costVisible
      defaultDaysToReturn
      defaultDaysToReturnCore
      defaultDaysToReturnCoreToVendor
      defaultDaysToReturnToVendor
      defaultLocationName
      defaultQuantityForMisc
      defaultQuantityForReplenishable
      defaultQuantityForStandard
      defaultReplenishablePartsArePublic
      defaultReturnable
      defaultGlCategoryId
      defaultNonReplenishablePartsArePublic
      defaultReturnableToVendor
      distributorCorePriceEditable
      distributorCorePriceRequired
      distributorCorePriceVisible
      distributorPriceEditable
      distributorPriceRequired
      distributorPriceVisible
      glCategoryEditable
      glCategoryRequired
      glCategoryVisible
      inventoryTypeEditable
      inventoryTypeRequired
      inventoryTypeVisible
      jobberCorePriceEditable
      jobberCorePriceRequired
      jobberCorePriceVisible
      jobberPriceEditable
      jobberPriceRequired
      jobberPriceVisible
      listPriceEditable
      listPriceRequired
      listPriceVisible
      oemNumberEditable
      oemNumberRequired
      oemNumberVisible
      retailPriceEditable
      retailPriceRequired
      retailPriceVisible
      retailCorePriceEditable
      retailCorePriceRequired
      retailCorePriceVisible
      sideEditable
      sideRequired
      sideVisible
      userStatusEditable
      userStatusRequired
      userStatusVisible
      varianceLocationName
      vendorEditable
      vendorRequired
      vendorVisible
      wholesalePriceEditable
      wholesalePriceRequired
      wholesalePriceVisible
      wholesaleCorePriceEditable
      wholesaleCorePriceRequired
      wholesaleCorePriceVisible
    }
    fileAttachments {
      maxImageHeight
      maxImageWidth
    }
    location {
      enforceLocationHierarchy
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "vehicle": {
                            "type": "VehicleSettingValues",
                            "keyRaw": "vehicle",

                            "selection": {
                                "fields": {
                                    "userStatusRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusRequired",
                                        "visible": true
                                    },

                                    "minVinLength": {
                                        "type": "Int",
                                        "keyRaw": "minVinLength",
                                        "visible": true
                                    },

                                    "mileageRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "mileageRequired",
                                        "visible": true
                                    },

                                    "flexField4Required": {
                                        "type": "Boolean",
                                        "keyRaw": "flexField4Required",
                                        "visible": true
                                    },

                                    "flexField3Required": {
                                        "type": "Boolean",
                                        "keyRaw": "flexField3Required",
                                        "visible": true
                                    },

                                    "flexField2Required": {
                                        "type": "Boolean",
                                        "keyRaw": "flexField2Required",
                                        "visible": true
                                    },

                                    "flexField1Required": {
                                        "type": "Boolean",
                                        "keyRaw": "flexField1Required",
                                        "visible": true
                                    },

                                    "conditionRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionRequired",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventory": {
                            "type": "InventorySettingValues",
                            "keyRaw": "inventory",

                            "selection": {
                                "fields": {
                                    "defaultNonReplenishablePartsArePublic": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultNonReplenishablePartsArePublic",
                                        "visible": true
                                    },

                                    "categoryEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "categoryRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "categoryVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "costEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "costRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "costVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturn": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturn",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnCore": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnCore",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnCoreToVendor": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnCoreToVendor",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnToVendor": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnToVendor",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultLocationName": {
                                        "type": "String",
                                        "keyRaw": "defaultLocationName",
                                        "visible": true
                                    },

                                    "defaultQuantityForMisc": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForMisc",
                                        "visible": true
                                    },

                                    "defaultQuantityForReplenishable": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForReplenishable",
                                        "visible": true
                                    },

                                    "defaultQuantityForStandard": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForStandard",
                                        "visible": true
                                    },

                                    "defaultReplenishablePartsArePublic": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReplenishablePartsArePublic",
                                        "visible": true
                                    },

                                    "defaultReturnable": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReturnable",
                                        "visible": true
                                    },

                                    "defaultGlCategoryId": {
                                        "type": "Int",
                                        "keyRaw": "defaultGlCategoryId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultReturnableToVendor": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReturnableToVendor",
                                        "visible": true
                                    },

                                    "distributorCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "sideEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "sideRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "sideVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "varianceLocationName": {
                                        "type": "String",
                                        "keyRaw": "varianceLocationName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "fileAttachments": {
                            "type": "FileAttachmentSettingValues",
                            "keyRaw": "fileAttachments",

                            "selection": {
                                "fields": {
                                    "maxImageHeight": {
                                        "type": "Int",
                                        "keyRaw": "maxImageHeight",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "maxImageWidth": {
                                        "type": "Int",
                                        "keyRaw": "maxImageWidth",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "location": {
                            "type": "LocationSettingValues",
                            "keyRaw": "location",

                            "selection": {
                                "fields": {
                                    "enforceLocationHierarchy": {
                                        "type": "Boolean",
                                        "keyRaw": "enforceLocationHierarchy",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6c67aac2aee85cc2afb4994fd1a0ed4f9ce9bbd36bb1dcea40cd4cc068684b76";