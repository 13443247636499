import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/PartSearchData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class PartSearchDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "PartSearchDataStore",
			variables: true,
		})
	}
}

export async function load_PartSearchData(params) {
	await initClient()

	const store = new PartSearchDataStore()

	await store.fetch(params)

	return {
		PartSearchData: store,
	}
}
