import { HoudiniClient, cache } from '$houdini'
import sessionStore, { getSession } from 'stores/session'
import buildApiUrl from 'utility/build-api-url'

let session = getSession()
sessionStore.subscribe(newSession => {
	session = newSession
	// Clear cache on logout
	if (!session?.authToken) {
		cache.reset()
	}
})

const client = new HoudiniClient({
	url: buildApiUrl().apiURL,
	fetchParams() {
		return {
			headers: {
				'auth-token': session.authToken ?? '',
				'apollographql-client-name': 'EnterpriseWeb',
				'apollographql-client-version': '__buildVersion__',
				'Content-Type': 'application/json',
			},
		}
	},
	// We're used to errors throwing with our custom api fetch, so let's keep that behavior
	// TODO, maybe we can add errors to a more permanent log here?
	throwOnError: {
		operations: ['all'],
		error: (errors, ctx) => {
			console.error(errors, ctx)
			throw errors[0]
		},
	},
})
export default client
