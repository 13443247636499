<script
	lang="ts"
	context="module"
>
	import { graphql, type UserAccountData$result } from '$houdini'

	//end of module context
</script>

<script lang="ts">
	import { onMount } from 'svelte'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import type { SearchField } from 'utility/search-fields'
	import Pin from 'components/Pin.svelte'

	interface UserAccount extends Partial<UserAccountData$result['userAccounts'][0]> {
		id: UserAccountData$result['userAccounts'][0]['id']
		name: UserAccountData$result['userAccounts'][0]['name']
	}

	interface DisplayUserAccount extends Partial<UserAccount> {
		displayName: string
	}

	const userAccountsQuery = graphql(`
		query UserAccountData {
			userAccounts {
				lastName
				id
				firstName
				name
				status
			}
		}
	`)

	export let userAccount: DisplayUserAccount | UserAccount | null = null
	export let userAccounts: UserAccount[] = []
	export let label: string = 'User Account'
	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let displayUserAccounts: DisplayUserAccount[] = []

	onMount(async () => {
		userAccounts = (await userAccountsQuery.fetch({ policy: 'CacheOrNetwork' }))?.data?.userAccounts ?? []
	})

	function getDisplayUserAccounts(userAccounts: UserAccount[]) {
		return userAccounts
			.map(userAccount => {
				return {
					...userAccount,
					displayName: userAccount?.firstName && userAccount?.lastName ? `${userAccount?.firstName} ${userAccount?.lastName}` : userAccount?.name ?? '',
				}
			})
			.sort((a, b) => {
				const aStatus = a.status ?? ''
				const bStatus = b.status ?? ''
				if (aStatus === bStatus) {
					return a.displayName > b.displayName ? 1 : -1
				}
				return aStatus.localeCompare(bStatus)
			})
	}

	$: displayUserAccounts = getDisplayUserAccounts(userAccounts)
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		{label}
		options={displayUserAccounts}
		getLabel={option => {
			let displayName = option?.firstName && option?.lastName ? `${option?.firstName} ${option?.lastName}` : option?.name ?? ''
			return option?.status && option?.status.toLowerCase() !== 'active' ? `${displayName} (${option.status.toLowerCase()})` : displayName
		}}
		bind:value={userAccount}
		emptyValue={null}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
