import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/InventoryData'


// create the fragment store

export class InventoryDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "InventoryDataStore",
			variables: true,
			
		})
	}
}
