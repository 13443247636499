const documentTypes: Record<string, DocumentType> = {
	NONE: {
		name: 'None',
		abbreviation: 'N/A',
	},
	PAYMENT: {
		name: 'Payment',
		abbreviation: 'P',
	},
	PURCHASE_ORDER: {
		name: 'Purchase Order',
		abbreviation: 'PO',
	},
	PURCHASE_ORDER_LINE: {
		name: 'Purchase Order Line',
		abbreviation: 'POL',
		parentAbbreviation: 'PO',
	},
	SALES_ORDER: {
		name: 'Sales Order',
		abbreviation: 'SO',
	},
	SALES_ORDER_LINE: {
		name: 'Sales Order Line',
		abbreviation: 'SOL',
		parentAbbreviation: 'SO',
	},
	TRANSFER_ORDER: {
		name: 'Transfer Order',
		abbreviation: 'TO',
	},
	TRANSFER_ORDER_LINE: {
		name: 'Transfer Order Line',
		abbreviation: 'TOL',
		parentAbbreviation: 'TO',
	},
	WORK_ORDER: {
		name: 'Work Order',
		abbreviation: 'WO',
	},
	WORK_ORDER_PART: {
		name: 'Work Order Part',
		abbreviation: 'WOP',
		parentAbbreviation: 'WO',
	},
} as const

export default documentTypes

type DocumentType = {
	name: string
	abbreviation: string
	parentAbbreviation?: string
}
export type DocumentTypeName = keyof typeof documentTypes
