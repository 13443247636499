<!-- This component is designed handle inventorysetting fields(inventoryFields in the API) -->
<script lang="ts">
	import type { ComponentEvents } from 'svelte'
	import type {
		SearchField,
		NumberRange as NumberRangeType,
		StringRange as StringRangeType,
		CurrencyRange as CurrencyRangeType,
		DateRange as DateRangeType,
		AdditionalFieldValue,
	} from 'utility/search-fields'

	import Pin from 'components/Pin.svelte'
	import DateRange from 'components/DateRange.svelte'
	import Input from '@isoftdata/svelte-input'
	import InputRange from './InputRange.svelte'
	import NumberRange from './NumberRange.svelte'
	import { createEventDispatcher } from 'svelte'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CurrencyRange from 'components/CurrencyRange.svelte'
	import EditModeControls from 'components/EditModeControls.svelte'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import CurrencyInput from '@isoftdata/svelte-currency-input'

	export let inEditMode: boolean
	export let searchField: SearchField
	export let disabled: boolean = false
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let value: AdditionalFieldValue = getDefaultValue(searchField)
	export let id: string = ''

	function getDefaultValue(searchField: SearchField) {
		if (searchField?.additionalFieldData?.searchByRange) {
			return { from: '', to: '' }
		} else if (searchField?.additionalFieldData?.fieldType === 'BOOLEAN') {
			return true
		} else {
			return ''
		}
	}

	let label: string = ''
	let disableInput: boolean = false

	const numberTypes = ['ID', 'INT', 'UINT', 'FLOAT']
	const dateTypes = ['DATE', 'DATETIME']
	const dispatch = createEventDispatcher<{
		change: { field: SearchField; value: AdditionalFieldValue }
	}>()

	const isRange = (field: SearchField) => !!field?.additionalFieldData?.searchByRange

	// Type guards
	function isNumber(field: SearchField, value): value is number | null {
		return !!(field?.additionalFieldData?.fieldType && numberTypes.includes(field?.additionalFieldData?.fieldType) && !isRange(field))
	}

	function isNumberRange(field: SearchField, value): value is NumberRangeType {
		return !!(field?.additionalFieldData?.fieldType && numberTypes.includes(field?.additionalFieldData?.fieldType) && isRange(field))
	}

	function isString(field: SearchField, value): value is string | null {
		return field?.additionalFieldData?.fieldType === 'TEXT' && !isRange(field)
	}

	function isStringRange(field: SearchField, value): value is StringRangeType {
		return field?.additionalFieldData?.fieldType === 'TEXT' && isRange(field)
	}

	function isCurrency(field: SearchField, value): value is string {
		return field?.additionalFieldData?.fieldType === 'CURRENCY' && !isRange(field)
	}

	function isCurrencyRange(field: SearchField, value): value is CurrencyRangeType {
		return field?.additionalFieldData?.fieldType === 'CURRENCY' && isRange(field)
	}

	function isDate(field: SearchField, value): value is string {
		return !!(field?.additionalFieldData?.fieldType && dateTypes.includes(field?.additionalFieldData?.fieldType) && !isRange(field))
	}

	function isDateRange(field: SearchField, value): value is DateRangeType {
		return !!(field?.additionalFieldData?.fieldType && dateTypes.includes(field?.additionalFieldData?.fieldType) && isRange(field))
	}

	function isBoolean(field: SearchField, value): value is boolean {
		return field?.additionalFieldData?.fieldType === 'BOOLEAN' && !isRange(field)
	}

	// Change event handlers
	function currencyChangeHandler(e: CustomEvent<{ value: string }>, field: SearchField) {
		dispatch('change', { field, value: e.detail.value })
	}

	function dateRangeHandler(e: ComponentEvents<DateRange>['change'], field: SearchField) {
		dispatch('change', { field, value: e.detail.dates })
	}

	function genericComponentHandler(e: CustomEvent<NumberRangeType | StringRangeType | string | null>, field: SearchField) {
		dispatch('change', { field, value: e.detail })
	}

	function elementChangeHandler(e: Event, field: SearchField) {
		const eventValue = (e.target as HTMLInputElement)?.value
		if (field?.additionalFieldData?.fieldType && numberTypes.includes(field?.additionalFieldData?.fieldType)) {
			value = parseFloat(eventValue.replace(/[^\d.]/g, '')) //remove any non-numeric values
		} else {
			value = eventValue
		}
		dispatch('change', { field, value })
	}

	/* This function is called when the value changes, which is only from the checkbox component
	We need to ensure that we dispatch a change event when the boolean search field is initally added
	This is different from all of the other fields, which don't have a value until the user interacts with them */
	function handleBooleanField(searchField: SearchField, value: AdditionalFieldValue) {
		if (searchField.additionalFieldData?.fieldType === 'BOOLEAN') {
			dispatch('change', { field: searchField, value: value })
		}
	}

	$: label = searchField.name
	$: disableInput = disabled || inEditMode
	$: handleBooleanField(searchField, value)
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	{#if searchField?.additionalFieldData}
		{#if isNumberRange(searchField, value)}
			<NumberRange
				{id}
				{label}
				from={value.from}
				to={value.to}
				disabled={disableInput}
				on:change={e => genericComponentHandler(e, searchField)}
				isPinned={pinnedSearchFields.has(searchField.key)}
			/>
		{:else if isStringRange(searchField, value)}
			<InputRange
				{id}
				{label}
				from={value.from}
				to={value.to}
				disabled={disableInput}
				on:change={e => genericComponentHandler(e, searchField)}
				isPinned={pinnedSearchFields.has(searchField.key)}
			/>
		{:else if isCurrencyRange(searchField, value)}
			<CurrencyRange
				{id}
				{label}
				from={value.from}
				to={value.to}
				disabled={disableInput}
				on:change={e => genericComponentHandler(e, searchField)}
				isPinned={pinnedSearchFields.has(searchField.key)}
			/>
		{:else if isDateRange(searchField, value)}
			<DateRange
				{id}
				{label}
				dates={value}
				disabled={disableInput}
				on:change={e => dateRangeHandler(e, searchField)}
				isPinned={pinnedSearchFields.has(searchField.key)}
			/>
		{:else if isCurrency(searchField, value)}
			<CurrencyInput
				{id}
				{label}
				{value}
				disabled={disableInput}
				on:change={e => currencyChangeHandler(e, searchField)}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</CurrencyInput>
		{:else if isNumber(searchField, value) || isString(searchField, value) || isDate(searchField, value)}
			<Input
				{id}
				{label}
				{value}
				disabled={disableInput}
				type={isDate(searchField, value) ? 'date' : 'text'}
				on:change={e => elementChangeHandler(e, searchField)}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Input>
		{:else if isBoolean(searchField, value)}
			<Checkbox
				{id}
				{label}
				bind:checked={value}
				disabled={disableInput}
				type="radio"
				btnGroupClass="mb-1 w-100"
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Checkbox>
		{:else if searchField?.additionalFieldData?.fieldType === 'CHOICE' && searchField?.additionalFieldData?.fieldOptions}
			<Autocomplete
				{id}
				{label}
				{value}
				disabled={disableInput}
				options={searchField.additionalFieldData.fieldOptions}
				emptyValue={null}
				on:change={e => genericComponentHandler(e, searchField)}
			>
				<svelte:fragment slot="hint">
					<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
				</svelte:fragment>
			</Autocomplete>
		{:else}
			<!-- TODO: remove this maybe? In theory there could be a combination of fieldType and searchByRange that I'm not accounting for, so maybe they should stay? Or something better? -->
			<p>Unsupported field type: {searchField.additionalFieldData.fieldType}</p>
		{/if}
	{/if}
</EditModeControls>
