<script lang="ts">
	import type { StatusOption, SearchField } from 'utility/search-fields'

	import Select from '@isoftdata/svelte-select'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher<{ change: { field: SearchField; value: StatusOption['abbrev'] } }>()

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let status: StatusOption['abbrev'] | '@' | null = '@'
	export let disabled: boolean = false

	let statusList: StatusOption[] = [
		{ abbrev: '@', description: 'Status A or H' },
		{ abbrev: 'A', description: 'Available' },
		{ abbrev: 'H', description: 'On Hold' },
		{ abbrev: 'S', description: 'Sold' },
		{ abbrev: 'C', description: 'Crushed' },
		{ abbrev: 'D', description: 'Deleted' },
		{ abbrev: 'T', description: 'Transferred' },
		{ abbrev: 'B', description: 'Bidding' },
	]

	function isStatusAbbreviation(string: string): string is StatusOption['abbrev'] {
		return !!statusList.find(status => status.abbrev === string)
	}

	function change(e: Event) {
		const status = (e.target as HTMLSelectElement).value
		if (isStatusAbbreviation(status)) {
			dispatch('change', { field: searchField, value: status })
		}
	}
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Select
		bind:value={status}
		label="Status"
		emtpyValue={null}
		emptyText="Any Status"
		disabled={disabled || inEditMode}
		options={statusList}
		let:option={status}
		on:change={change}
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
		<option value={status?.abbrev ?? null}>{status?.abbrev} - {status?.description}</option>
	</Select>
</EditModeControls>
