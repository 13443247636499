<script
	lang="ts"
	context="module"
>
	import type { InventoryType, SearchField } from 'utility/search-fields'

	import { onMount } from 'svelte'
	import { loadInventoryTypes } from 'utility/search-fields'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'
</script>

<script lang="ts">
	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let inventoryTypeList: InventoryType[] = []
	export let inventoryType: InventoryType | null = null
	export let disabled: boolean = false
	export let vehicle: boolean | undefined = undefined //Defines if the loaded list of inventory types should be filtered to just vehicle types

	onMount(async () => {
		inventoryTypeList = await loadInventoryTypes({ vehicle })
	})
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		label="{vehicle ? 'Unit' : 'Inventory'} Type"
		placeholder="-- Select {vehicle ? 'a Unit' : 'an Inventory'} Type --"
		options={inventoryTypeList}
		emptyValue={null}
		bind:value={inventoryType}
		getLabel={option => (option ? `${option?.id} - ${option?.name}` : '')}
		disabled={disabled || inEditMode}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
		<svelte:fragment
			slot="option"
			let:option
		>
			<span class="badge bg-primary text-white">{option?.id}</span>
			{option?.name}
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
