import type { AppContext } from 'types/common'

import PartSearch from './PartSearch.svelte'
import { graphql } from '$houdini'
import { getSession } from 'stores/session'

const dataQuery = graphql(`
	query PartSearchData($userAccountId: Int!, $filter: InventoryFieldsFilter!) {
		userAccount(id: $userAccountId) {
			authorizedStores {
				id
				name
				code
			}
		}
		session {
			userAccount {
				searchModules {
					default
					module {
						id
						name
					}
				}
				settings {
					searchParts {
						pinnedSearchFields
					}
				}
			}
		}
		inventoryFields(filter: $filter) {
			id
			displayName
			fieldType
			fieldOptions
			searchByRange
			searchMode
			fieldName
		}
	}
`)

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.part-search',
		route: 'part-search',
		template: {
			svelte: true,
			component: PartSearch,
		},
		async resolve(_stateRouterData, _parameters) {
			const { id: userAccountId, currentStore } = getSession()

			const { data } = await dataQuery.fetch({ variables: { userAccountId, filter: { searchable: true } } })
			return {
				searchModules: data?.session?.userAccount?.searchModules ?? [],
				authorizedStores: data?.userAccount?.authorizedStores ?? [],
				searchableInventoryFields: data?.inventoryFields ?? [],
				stores: currentStore ? [currentStore] : [], //This is the list of selected stores, so by default it will be their currently logged in store
				userPinnedSearchFields: data?.session?.userAccount?.settings?.searchParts?.pinnedSearchFields ?? [],
			}
		},
	})
}
