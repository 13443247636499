import type { ModelsForManufacturer$result } from '$houdini'
import { graphql } from '$houdini'

// import { sortArrayByObjectKey } from '@isoftdata/utility-array'

const modelsQuery = graphql(`
	query ModelsForManufacturer($manufacturerId: Int!, $inventoryTypeId: Int) {
		manufacturer(id: $manufacturerId) {
			models(inventoryTypeId: $inventoryTypeId) {
				id
				name
				active
				defaultShippingDimensions {
					height
					length
					measurementUnit
					weight
					weightUnit
					width
				}
				inventoryTypeId
			}
		}
	}
`)

export default async function loadModelsForManufacturer(manufacturerId: number, inventoryTypeId: number): Promise<Array<PartModel>> {
	const { data } = await modelsQuery.fetch({
		variables: {
			manufacturerId,
			inventoryTypeId,
		},
		policy: 'CacheOrNetwork',
	})
	return data?.manufacturer?.models ?? []
}

export type PartModel = ModelsForManufacturer$result['manufacturer']['models'][0]
