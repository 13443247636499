import { persist, createSessionStorage } from '@macfja/svelte-persistent-store'
import { writable, get } from 'svelte/store'
import type { LoginMutation$result } from '$houdini'

const session = persist(writable<ClientSession | Record<string, never>>(), createSessionStorage(), 'user')

export default session

/** Runs `get` from `svelte/store` on the session store. You should only use this in the case where subscribing doesn't make sense. */
export function getSession(): ClientSession | Record<string, never> {
	return get(session)
}

export type ClientSession = LoginMutation$result['createSession']['userAccount'] & {
	authToken: string
	currentStore: number
	sessionPermissions: LoginMutation$result['createSession']['permissions']
}
