<svelte:options accessors />

<script lang="ts">
	import type { PartScreenStageOne$result, LIB_storeLocations$result, InventoryLookup$result, DataTypeEnum } from '$houdini'
	import type { FlatAttachment, OptionValueMap, PartForClient as Part, SettingValues, TypeField } from './part'
	import type { PartModel } from 'utility/load-models-for-manufacturer'
	import type { Writable } from 'svelte/store'
	import type { Mediator, SvelteAsr } from 'types/common'

	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import { NavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import LoadItemModal from '@isoftdata/svelte-load-item-modal'
	import Attachments from '@isoftdata/svelte-attachments'
	import PartBasic from './Basic.svelte'

	import { getContext, onDestroy, onMount, tick, type ComponentProps } from 'svelte'
	import { graphql } from '$houdini'
	import { checkSessionPermission } from 'utility/permission'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import { getObject, setObject } from '@isoftdata/utility-storage'
	import { readAsDataURL } from '@isoftdata/promise-file-reader'
	import { newMiscInventoryMutation, newReplenishableInventoryMutation, newInventoryMutation as newStandardInventoryMutation } from 'common/graphql-queries'
	import { dequal } from 'dequal'
	import { fade } from 'svelte/transition'
	import debounced from 'utility/debounce'
	import { formatISO } from 'date-fns'

	export let asr: SvelteAsr

	export let settingValues: SettingValues
	export let partStoreLocations: LIB_storeLocations$result['locations']
	export let part: Part
	export let attachments: Array<FlatAttachment>
	export let typeFields: Array<TypeField>
	export let origPart: Readonly<Part>
	export let optionValueMap: OptionValueMap
	export let loadCachedPart: boolean
	// exported so we can access in canLeaveState
	export let partChanged: boolean
	export let sellPriceClasses: PartScreenStageOne$result['sellPriceClasses']
	export let trueModels: Array<PartModel>
	export let assyModels: Array<PartModel>
	export let storeId: number
	export let tab: 'basic' | 'attachments' = 'basic'
	export let glCategories: PartScreenStageOne$result['glCategories']
	// todo change schema to not include null
	export let inventoryConditions: PartScreenStageOne$result['inventoryConditions']
	export let inventoryTypeList: PartScreenStageOne$result['inventoryTypeList']
	export let saleClasses: PartScreenStageOne$result['saleClasses']
	export let userPartStatusList: PartScreenStageOne$result['userPartStatusList']
	// export let userVehicleStatusList: PartScreenStageOne$result['userVehicleStatusList']
	export let vehicleMakes: PartScreenStageOne$result['vehicleMakes']
	export let vehicleModels: Array<string> = []
	export let vendorList: PartScreenStageOne$result['vendors']['items']

	const mediator = getContext<Mediator>('mediator')
	const isHotUpdating = getContext<Writable<boolean>>('isHotUpdating')
	const tabs: ComponentProps<NavTabBar>['tabs'] = [
		{
			name: 'basic',
			title: 'Basic Info',
		},
		{
			name: 'attachments',
			title: 'Attachments',
		},
	]

	let showNoLocationOnSaveModal = false
	let showPartHistoryModal: boolean
	let loadPartModal: LoadItemModal<InventoryLookup$result['inventories']['items'][number]>
	let skipChangeDetection = true
	let scrollY = 0
	// TODO replace with the queryStore.fetching or w/e when we use houdini to create parts too
	let isSaving = false

	$: partTitle = part ? { title: part.tagNumber || part.innodbInventoryid || 'New Part', subtitle: part.inventoryTypeId } : { title: 'New Part', subtitle: '' }
	$: inventoryTypeData = inventoryTypeList.find(it => it.inventoryTypeId === part.inventoryTypeId) ?? null
	$: missingRequiredFields = getMissingRequiredFields(part)
	$: missingRequiredFieldsList = missingRequiredFields.join(', ') || ''
	$: requiredFieldsComplete = missingRequiredFields.length === 0
	$: debouncedCachePart(part)

	function getMissingRequiredFields(part: Part) {
		const isNew = !part.innodbInventoryid
		const missingFields: Array<string> = []

		if (!settingValues?.inventory) {
			return missingFields
		}

		const checks: Array<{
			name: string
			missingRequiredField: boolean | null | undefined
		}> = [
			{
				name: 'Category',
				missingRequiredField: settingValues.inventory.categoryRequired && !part.category?.id,
			},
			{
				name: 'Condition',
				missingRequiredField: settingValues.inventory.conditionRequired && !part.condition,
			},
			{
				name: 'Cost',
				missingRequiredField: settingValues.inventory.costRequired && !part.cost,
			},
			{
				name: 'GL Category',
				missingRequiredField: settingValues.inventory.glCategoryRequired && !part.glCategory?.id,
			},
			{
				name: 'Inventory Type',
				missingRequiredField: !part.inventoryTypeId,
			},
			{
				name: 'Jobber Price',
				missingRequiredField: settingValues.inventory.jobberPriceRequired && !part.jobberPrice,
			},
			{
				name: 'OEM Number',
				missingRequiredField: settingValues.inventory.oemNumberRequired && (isNew || part.oemNumber !== origPart.oemNumber) && !part.oemNumber,
			},
			{
				name: 'Retail Price',
				missingRequiredField: settingValues.inventory.retailPriceRequired && !part.retailPrice,
			},
			{
				name: 'Retail Core Price',
				missingRequiredField: settingValues.inventory.retailCorePriceRequired && !part.retailCorePrice,
			},
			{
				name: 'Side',
				missingRequiredField: settingValues.inventory.sideRequired && !part.side,
			},
			{
				name: 'User Status',
				missingRequiredField: settingValues.inventory.userStatusRequired && !part.userStatus,
			},
			{
				name: 'Wholesale Price',
				missingRequiredField: settingValues.inventory.wholesalePriceRequired && !part.wholesalePrice,
			},
			{
				name: 'Serial Number',
				missingRequiredField: inventoryTypeData?.requireSerialization && !part.serialized && !part.newSerial,
			},
		]

		for (const check of checks) {
			if (check.missingRequiredField) {
				missingFields.push(check.name)
			}
		}
		return missingFields
	}

	function getOptionValuesForSave() {
		const optionIdSet = new Set(part.inventoryOptions.map(({ id }) => id))

		type OvForSave = {
			inventoryOptionId: number
			value: string
		}

		const optionValuesToSave = Array.from(optionValueMap.entries()).reduce(
			(acc: Record<string | number, Array<OvForSave>> & { optionValues: Array<OvForSave> }, [{ serialId, optionId, serialUuid }, value]) => {
				if (optionId && !optionIdSet.has(optionId)) {
					return acc
				}
				// serialUuid is used for new serials
				const serialKey = serialId || serialUuid
				// Have to include ALL optionValues for a given serial or else the absent ones will be cleared
				// Could be made more efficient if we exclude serials with no changes made to them. But if any are modified, we need to send the whole list.
				if (serialKey && optionId) {
					// Serial Q&A
					if (!acc[serialKey]) {
						acc[serialKey] = []
					}
					if (value) {
						acc[serialKey].push({
							inventoryOptionId: optionId,
							value: value?.toString?.() ?? '',
						})
					}
				} else if (optionId && value) {
					// Default / non-serial Q&A
					acc.optionValues.push({
						inventoryOptionId: optionId,
						value: value?.toString?.() ?? '',
					})
				}
				return acc
			},
			{ optionValues: [] },
		)

		return optionValuesToSave
	}

	function showLoadPartModal(searchString?: string) {
		loadPartModal.show(searchString)
	}

	async function doTagNumberSearch(searchString: string) {
		const { data } = await inventoryLookupQuery.fetch({
			variables: {
				filter: {
					tagNumber: searchString,
				},
			},
		})
		return data?.inventories.items ?? []
	}

	function addLocationAndSave(locationName: string) {
		locationName = locationName.toLowerCase()
		const existingLocation = partStoreLocations.find(location => location.name.toLowerCase() === locationName)
		part.locations.push({
			description: existingLocation?.description ?? '',
			allowInventory: true,
			id: null,
			locationId: existingLocation?.id ?? null,
			name: locationName,
			rank: 1,
			quantity: part.quantity,
			holdQuantity: 0,
			permanent: false,
			uuid: uuid(),
			deleted: false,
		})
		part.locations = part.locations
		showNoLocationOnSaveModal = false
		savePart()
	}

	function clearCachedPart() {
		localStorage.removeItem('cachedPart')
		localStorage.removeItem('cachedOptionValues')
		localStorage.removeItem('cachedPartAttachments')
		void mediator.publish('removeActivity', 'Unsaved Part')
	}

	function createUnsavedPartActivity() {
		mediator.publish('activity', {
			title: 'Unsaved Part',
			route: 'app.part',
			parameters: {
				inventoryId: part.innodbInventoryid ? part.innodbInventoryid.toString() : 'null',
				storeId: storeId.toString(),
				loadCachedPart: 'True',
			},
			type: 'PART',
			name: 'Part',
		})
		partChanged = true
	}

	const debouncedCachePart = debounced(500, (thePart: Part) => cachePart(thePart)) as (part: Part) => void
	// observe part.*
	async function cachePart(part: Part) {
		// Rendering a component that binds to the part will trigger this observer, so ignore it if the actual value hasn't changed
		if (skipChangeDetection || (!partChanged && dequal(part, origPart))) {
			return
		}
		if (!partChanged) {
			// Remove old "Unsaved Part"s before we add the new one since we only store one unsaved part in memory
			clearCachedPart()
			createUnsavedPartActivity()
		}
		const partToCache = klona(part)

		setObject(localStorage, 'cachedPart', partToCache)
	}

	function cacheOptionValueMap() {
		if (skipChangeDetection) {
			return
		}
		console.log('cacheOptionValueMap', optionValueMap)
		if (!partChanged) {
			clearCachedPart()
			createUnsavedPartActivity()
			cachePart(part)
		}
		// Unfortunately if we want to cache the part on every change, we have to loop through all option values every time one is changed
		setObject(localStorage, 'cachedOptionValues', {
			keyParts: optionValueMap.keyParts,
			entries: optionValueMap.entries(),
		})
	}

	async function cacheAttachments() {
		if (skipChangeDetection || !attachments.length) {
			return
		}
		console.log('cacheAttachments')
		if (!partChanged) {
			clearCachedPart()
			createUnsavedPartActivity()
			// need to also cache the part if we want to reload it later
			cachePart(part)
		}
		const attachmentsToCache = await Promise.all(
			klona(attachments).map(async attachment => {
				if (attachment.path?.startsWith('blob:') && attachment.File) {
					return {
						...attachment,
						path: await readAsDataURL(attachment.File), // so we can restore the image on reload
					}
				}
				return attachment
			}),
		)
		setObject(localStorage, 'cachedPartAttachments', attachmentsToCache)
	}

	function getSaveEndpoint({ innodbInventoryid, replenishable, vehicleId }: Part) {
		if (innodbInventoryid) {
			throw new Error('Cannot save existing parts with this method. Use updateInventory query store.')
		}
		if (replenishable) {
			return newReplenishableInventoryMutation
		}
		if (vehicleId) {
			return newStandardInventoryMutation
		}
		return newMiscInventoryMutation
	}

	async function savePart() {
		if (!checkSessionPermission('PARTS_CAN_SAVE_PARTS')) {
			return alert('You do not have permission to save parts.')
		}

		if (part.quantity > 0 && !part.locations.length && !part.serialized) {
			showNoLocationOnSaveModal = true
			return
		}

		const originalPart = origPart
		const varianceLocationName = settingValues?.inventory.varianceLocationName || 'Variance'
		const { optionValues, ...serialOptionValues } = getOptionValuesForSave()

		if (settingValues?.location.enforceLocationHierarchy && part.locations.some(location => !Number.isInteger(location.locationId) && location.name !== varianceLocationName)) {
			const virtualLocationName = part.locations.find(location => !Number.isInteger(location.locationId))?.name ?? 'Unknown'
			return alert(
				`You are trying to use a location (${virtualLocationName}) that does not exist.\r\n\r\nMake sure are using locations that exist, or get permission from an administrator to use virtual locations.`,
			)
		}

		console.log('savedPart', part)
		// TODO, use mutation input types?
		const variables: Record<string, any> = {}

		if (part.innodbInventoryid) {
			variables.innodbInventoryid = part.innodbInventoryid
		}

		type LocationsToSave = {
			create: Array<{
				locationId: number | null
				locationName: string | null
				permanent: boolean
				quantity: number
				rank: number | null
			}>
			update: Array<{
				id: number
				permanent: boolean
				quantity: number
				rank: number | null
			}>
		}

		// API currently doesn't properly save location rank on serialized parts
		const locationsForSave = /* part.serialized
			? undefined
			:  */ part.locations.reduce(
			({ create, update }: LocationsToSave, { id: inventoryLocationId, locationId, name: locationName, rank, quantity, permanent, deleted }) => {
				if (!inventoryLocationId) {
					create.push({
						locationId: Number.isInteger(locationId) ? locationId : null,
						locationName,
						permanent,
						quantity,
						rank,
					})
				} else {
					update.push({
						id: inventoryLocationId,
						permanent: deleted ? false : permanent,
						quantity: deleted ? 0 : quantity,
						rank,
					})
				}
				// quantity 0, permanent false -> remove
				return { create, update }
			},
			{ create: [], update: [] },
		)

		type AttachmentsForSave = {
			create: Array<{
				file: File
				public: boolean
				rank: number
			}>
			remove: Array<number>
			update: Array<{
				id: number
				public: boolean
				rank: number
			}>
		}

		const attachmentsForSave = attachments.reduce(
			({ create, remove, update }: AttachmentsForSave, attachment) => {
				if (attachment.action === 'CREATE' && attachment.File) {
					create.push({
						file: attachment.File,
						public: attachment.public,
						rank: attachment.rank,
					})
				} else if (attachment.action === 'UPDATE' && attachment.fileId) {
					update.push({
						id: attachment.fileId,
						public: attachment.public,
						rank: attachment.rank,
					})
				} else if (attachment.action === 'DELETE' && attachment.fileId) {
					remove.push(attachment.fileId)
				}

				return { create, remove, update }
			},
			{ create: [], remove: [], update: [] },
		)

		type SerialForSave = {
			id?: number | null
			action?: 'CREATE' | 'UPDATE' | 'DELETE'
			location?: string | null
			number: string
			optionValues: Array<{ inventoryOptionId: number; value: string }>
		}

		const serialsForSave = part.serials.reduce((serials: Array<SerialForSave>, serial) => {
			const newSerial = {
				number: serial.number,
				location: serial.location?.name, // Does the API handle this being absent?
				optionValues: serialOptionValues[serial.id || serial.uuid] || undefined,
			}
			let action: 'CREATE' | 'UPDATE' | 'DELETE' = 'CREATE'

			if (serial.deleted) {
				action = 'DELETE'
			} else if (serial.id) {
				action = 'UPDATE'
			}

			if (part.innodbInventoryid) {
				// Only need id and action on existing parts
				serials.push({
					...newSerial,
					id: serial.id,
					action,
				})
			} else if (!serial.deleted) {
				// on new parts, don't need to send deleted serials
				serials.push(newSerial)
			}
			return serials
		}, [])

		// some fields are commented out here because they're in the UI but the API doesn't support saving them yet
		variables.part = {
			id: part.innodbInventoryid ?? undefined,
			attachments: part.innodbInventoryid ? { update: attachmentsForSave.update, remove: attachmentsForSave.remove } : undefined, // Create is handled later
			bodyStyle: !part.vehicleId || part.innodbInventoryid ? part.bodyStyle : undefined, // Standard inventory will inherit from vehicle
			buyPackage: part.replenishable ? part.buyPackage : undefined, // replenishable only
			categoryId: part.category?.id ?? null,
			condition: part.condition,
			coreClass: part.replenishable ? part.coreClass : undefined,
			coreCost: part.coreCost,
			coreRequired: part.coreRequired,
			coreRequiredToVendor: part.coreRequiredToVendor,
			cost: part.cost,
			daysToReturn: part.daysToReturn,
			daysToReturnCoreToVendor: part.daysToReturnCoreToVendor,
			daysToReturnToVendor: part.daysToReturnToVendor,
			daysToReturnCore: part.daysToReturnCore,
			defaultVendorId: part.defaultVendor?.id || null,
			deplete: part.deplete,
			description: part.description,
			distributorCorePrice: part.distributorCorePrice,
			distributorPrice: part.distributorPrice,
			freezeUntil: part.replenishable ? part.freezeUntil : undefined, // replenishable only
			glCategoryId: part.glCategory?.id || null,
			interchangeNumber: part.interchangeNumber,
			inventoryTypeId: part.innodbInventoryid ? undefined : part.inventoryTypeId, // only for creation
			jobberCorePrice: part.jobberCorePrice,
			jobberPrice: part.jobberPrice,
			listPrice: part.listPrice,
			locations: part.innodbInventoryid ? locationsForSave : locationsForSave?.create, // new = only create
			manufacturerId: part.manufacturer?.id || null,
			maxQuantity: part.replenishable ? part.maxQuantity : undefined, // replenishable only
			minQuantity: part.replenishable ? part.minQuantity : undefined, // replenishable only
			modelId: part.model?.id ?? null,
			notes: part.notes,
			oemNumber: part.oemNumber,
			optionValues,
			parentManufacturerId: part.parentManufacturer?.id ?? null,
			parentModelId: part.parentModel?.id || null,
			partNumber: part.replenishable ? part.partNumber : undefined,
			popularityCode: part.popularityCode,
			printTag: !part.tagPrinted,
			public: part.public,
			purchaseFactor: part.replenishable ? part.useVendorOrderMultiplier : undefined,
			quantity: checkSessionPermission('PARTS_CAN_EDIT_QUANTITY') ? part.quantity : undefined,
			replenishable: part.innodbInventoryid ? part.replenishable : undefined, // only for update
			retailCorePrice: part.retailCorePrice,
			retailPrice: part.retailPrice,
			returnable: part.returnable,
			returnableToVendor: part.returnableToVendor,
			safetyStockPercent: part.replenishable ? part.safetyStockPercent : undefined,
			saleClassCode: part.saleClass?.code ?? null,
			seasonal: part.replenishable ? part.seasonal : undefined,
			sellPackage: part.sellPackage,
			sellPriceClassId: part.sellPriceClassId,
			serialized: part.serialized || originalPart.serialized, // do not allow un-serializing a part
			serials: part.serialized ? serialsForSave : [],
			shippingDimensions: part.shippingDimensions,
			side: part.side || 'NA', // have to pass a side option to the API
			singleQuantity: part.singleQuantity, // Not on Update
			status: part.status || 'A',
			stockingDays: part.replenishable ? part.stockingDays : undefined,
			stockMethod: part.replenishable ? part.stockMethod : undefined,
			stockType: part.replenishable ? part.stockType : undefined,
			storeId: part.innodbInventoryid ? undefined : part.storeId, // Only for creation
			subInterchangeNumber: part.subInterchangeNumber,
			tagNumber: part.tagNumber || undefined,
			taxable: part.taxable,
			typeData1: part.typeField1?.data,
			typeData2: part.typeField2?.data,
			typeData3: part.typeField3?.data,
			typeData4: part.typeField4?.data,
			userStatus: part.userStatus || null,
			vehicleId: part.vehicleId || undefined, // only for Standard Inventory & Updates
			vehicleYear: !part.vehicleId || part.innodbInventoryid ? part.vehicleYear || null : undefined, // Standard inventory will inherit from vehicle
			vehicleModel: !part.vehicleId || part.innodbInventoryid ? part.vehicleModel : undefined, // Standard inventory will inherit from vehicle
			vehicleMake: !part.vehicleId || part.innodbInventoryid ? part.vehicleMake : undefined, // Standard inventory will inherit from vehicle
			vehicleVin: !part.vehicleId || part.innodbInventoryid ? part.vehicleVin : undefined, // Standard inventory will inherit from vehicle
			vendorPopularityCode: part.replenishable ? part.vendorPopularityCode : undefined, // only replenishable
			vendorProductCode: part.replenishable || part.innodbInventoryid ? part.vendorProductCode : undefined, // only replenishable and Update
			wholesaleCorePrice: part.wholesaleCorePrice,
			wholesalePrice: part.wholesalePrice,
		}

		if (part.category?.id === -1) {
			delete variables.part.categoryId
		}

		try {
			let savedPart: {
				innodbInventoryid: number
				storeId: number
				sku: number
			} | null = null
			if (part.innodbInventoryid) {
				const { data } = await updateInventory.mutate({
					part: variables.part,
				})
				savedPart = data?.part ?? null
			} else {
				// todo fully switch to houdini when new mutation is done
				const query = getSaveEndpoint(part)
				const res = await mediator.publish('graphqlFetch', query, variables)
				// @ts-expect-error todo types better
				savedPart = res.part
			}

			if (!savedPart) {
				throw new Error('No part returned from API. Maybe there was an error?')
			}

			// The input type for creating part attachments on part save is weird, so just do it here I guess
			if (attachmentsForSave.create.length) {
				await uploadFiles.mutate({
					input: {
						attachments: attachmentsForSave.create,
						sku: savedPart.sku,
					},
				})
			}

			// Clear the cached part if we're saving it
			const cachedPart = getObject<{ innodbInventoryid: number }>(localStorage, 'cachedPart')
			if (cachedPart && (loadCachedPart || cachedPart.innodbInventoryid === savedPart.innodbInventoryid)) {
				clearCachedPart()
			}

			mediator.publish('showMessage', {
				type: 'success',
				heading: 'Saved!',
				message: 'Part saved successfully.',
				time: 10000,
			})
			partChanged = false
			asr.go(null, { loadCachedPart: false, inventoryId: savedPart.innodbInventoryid, lastSavedTime: Date.now() }, { replace: true })
		} catch (err: any) {
			let message = err.message
			if (Array.isArray(err)) {
				message = err.map(e => e.message).join('\n')
			}
			isSaving = false
			return mediator.publish('showMessage', {
				type: 'danger',
				heading: 'Failed to save part',
				message,
				time: false,
			})
		} finally {
			// ractive.get('partValueChangeObserver')?.resume()
		}
	}

	onMount(() => {
		if (part.inventoryId && !loadCachedPart) {
			mediator.publish('activity', {
				title: `#${part.tagNumber || part.inventoryId} (${inventoryTypeData?.inventoryTypeId})`,
				type: 'PART',
				route: 'app.part',
				name: 'Part',
				parameters: {
					inventoryId: part.innodbInventoryid ? part.innodbInventoryid.toString() : 'null',
					storeId: part.storeId ? part.storeId.toString() : 'null',
				},
				icon: 'engine',
			})
		}
		// Tick so everything that modifies/binds the part on init/mount doesn't trigger change detection 10 times
		tick().then(() => {
			skipChangeDetection = false
		})
	})

	onDestroy(() => {
		if (partChanged && !$isHotUpdating) {
			mediator.publish('showMessage', {
				heading: 'Changes saved for later...',
				message: 'Click "Unsaved Part" in "Recent Activity" to resume your work.',
				type: 'info',
				time: 10000,
			})
		}
	})

	// #region Qureries

	const updateInventory = graphql(`
		mutation LIB_updateInventoryBasic($part: InventoryUpdate!) {
			part: updateInventory(input: $part) {
				innodbInventoryid: id
				storeId
				sku
			}
		}
	`)

	const inventoryLookupQuery = graphql(`
		query InventoryLookup($filter: InventoryFilter) {
			inventories(filter: $filter) {
				items {
					id
					store {
						id
						code
						name
					}
					tagNumber
					sku
					inventoryType {
						id
						name
					}
				}
			}
		}
	`)

	const uploadFiles = graphql(`
		mutation LIB_CreateItemAttachments($input: NewItemAttachments!) {
			createItemAttachments(input: $input) {
				fileId: id
				rank
				public
				file {
					id
					name
					path: url
					type
					size
					mimeType: mimetype
					extension
					updated
					createdDate: created
				}
			}
		}
	`)
	// #endregion
</script>

<div class="card mb-3">
	<div class="card-header">
		<div class="d-flex flex-wrap justify-content-between">
			<div class="mr-auto">
				<h4 class="inline">
					<i class="fas fa-engine"></i>
					<span class="d-inline-block">
						{partTitle.title}
						{#if partTitle.subtitle}
							<small>({partTitle.subtitle})</small>{/if}
					</span>
				</h4>
			</div>
			<div
				class="d-flex flex-wrap d-sm-block mb-2 mb-sm-0"
				style="gap: 5px"
			>
				<Button
					size="sm"
					class="flex-grow-1"
					iconClass="plus"
					disabled={!checkSessionPermission('PARTS_CAN_SAVE_PARTS') || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
					href={asr.makePath(null, { storeId, lastResetTime: Date.now() })}>New Part</Button
				>
				<Button
					size="sm"
					outline
					iconClass="shop"
					title="Click to search for parts with this Tag # at other stores"
					disabled={!checkSessionPermission('PARTS_CAN_VIEW_PARTS')}
					on:click={() => showLoadPartModal(part.tagNumber ?? undefined)}
				></Button>
				<Button
					outline
					size="sm"
					iconClass="magnifying-glass"
					title="Click to search for parts by Tag #"
					disabled={!checkSessionPermission('PARTS_CAN_VIEW_PARTS')}
					on:click={() => showLoadPartModal()}
				></Button>
				<Button
					outline
					size="sm"
					iconClass="list"
					disabled={!checkSessionPermission('PARTS_CAN_VIEW_PARTS')}
					on:click={() => (showPartHistoryModal = true)}>Part History</Button
				>
				<Button
					size="sm"
					class="flex-grow-1"
					iconClass="floppy-disk"
					isLoading={isSaving}
					color={requiredFieldsComplete ? 'primary' : 'danger'}
					disabled={!requiredFieldsComplete || !partChanged || !checkSessionPermission('PARTS_CAN_SAVE_PARTS')}
					colorGreyDisabled={requiredFieldsComplete}
					on:click={() => {
						isSaving = true
						savePart().finally(() => (isSaving = false))
					}}
					>Save Part{#if !requiredFieldsComplete}*{/if}</Button
				>
			</div>
		</div>
		<NavTabBar
			{tabs}
			bind:selectedTab={tab}
			on:tabChange={event => asr.go(null, { tab: event.detail }, { inherit: true })}
		>
			<svelte:fragment slot="append">
				{#if !requiredFieldsComplete}
					<span class="text-danger mb-2 mr-2 ml-auto mt-auto">Missing required fields: {missingRequiredFieldsList} </span>
				{/if}
			</svelte:fragment>
		</NavTabBar>
	</div>
	<div class="card-body">
		{#if tab == 'basic'}
			<PartBasic
				{asr}
				{assyModels}
				{attachments}
				{glCategories}
				{inventoryConditions}
				{inventoryTypeData}
				{inventoryTypeList}
				{partStoreLocations}
				{saleClasses}
				{sellPriceClasses}
				{settingValues}
				{userPartStatusList}
				{vehicleMakes}
				{vehicleModels}
				{vendorList}
				partModels={trueModels}
				bind:part
				bind:optionValueMap
				bind:origPart
				bind:typeFields
				on:qaInputChange={cacheOptionValueMap}
			></PartBasic>
		{:else if tab == 'attachments'}
			<Attachments
				bind:fileList={attachments}
				modificationDisabled={!checkSessionPermission('PARTS_CAN_EDIT_ATTACHMENTS')}
				on:filesAdded={cacheAttachments}
				on:filesDeleted={cacheAttachments}
				on:publicChange={cacheAttachments}
				on:rankChange={cacheAttachments}
			></Attachments>
		{/if}
	</div>
</div>

<Modal
	title="Part History"
	bind:show={showPartHistoryModal}
	confirmButtonText="Close"
	cancelShown={false}
	on:confirm={() => (showPartHistoryModal = false)}
	on:close={() => (showPartHistoryModal = false)}
>
	<form class="form-row">
		<div class="col-12 col-md-6">
			<Input
				readonly
				value={part.enteredBy?.name ?? 'Unknown'}
				label="Entered By"
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				readonly
				value={part.dateViewed ? part.dateViewed.toLocaleString() : null}
				label="Date Viewed"
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				readonly
				value={part.dateModified ? part.dateModified.toLocaleString() : null}
				label="Date Modified"
				title="The date this inventory record was modified by a user"
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				readonly
				value={part.dateEntered ? part.dateEntered.toLocaleString() : null}
				label="Date Entered"
				title="The date this inventory record was first created in the system"
			/>
		</div>
	</form>
</Modal>

{#if showNoLocationOnSaveModal}
	<Modal
		bind:show={showNoLocationOnSaveModal}
		title="No Location Selected"
		confirmShown={false}
		cancelButtonText="Cancel"
		on:close={() => (showNoLocationOnSaveModal = false)}
	>
		<p>This item has {part.quantity} quantity on hand, but no locations.</p>
		<p class="p-0">Either add the default or variance location, or cancel and add a different location.</p>
		<svelte:fragment slot="modalFooter">
			<Button
				disabled={!settingValues?.inventory.defaultLocationName}
				on:click={() => addLocationAndSave(settingValues?.inventory.defaultLocationName)}>Use Default</Button
			>
			<Button
				disabled={!settingValues?.inventory.varianceLocationName}
				on:click={() => addLocationAndSave(settingValues?.inventory.varianceLocationName ?? 'Variance')}>Use Variance</Button
			>
		</svelte:fragment>
	</Modal>
{/if}

<LoadItemModal
	itemTitle="Parts by Tag Number"
	itemIdProp="id"
	itemDisplayProp="tagNumber"
	doSearch={doTagNumberSearch}
	on:lookupError={({ detail: { error } }) => alert(error.message)}
	on:chooseItem={({ detail: { item } }) => asr.go(null, { inventoryId: item.id, storeId: item.store.id })}
	bind:this={loadPartModal}
	let:item
>
	<div class="w-100 d-flex justify-content-between">
		<h5 class="mb-1">{item.tagNumber}</h5>
		<h5
			class="mb-1"
			title={item.store.name}
		>
			Store {item.store.code}
		</h5>
	</div>
	<div class="w-100">{item.inventoryType?.id} - {item.inventoryType?.name}</div>
	<small>SKU #{item.id}</small>
</LoadItemModal>

<svelte:window bind:scrollY />

{#if scrollY > 100}
	<div
		style="position: fixed; bottom: 10px; right: 10px; z-index: 10000;"
		transition:fade={{ duration: 200 }}
	>
		<Button
			tabindex={-1}
			color="secondary"
			iconClass="arrow-up"
			title="Scroll to top"
			on:click={() =>
				scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				})}
		></Button>
	</div>
{/if}
