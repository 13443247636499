export default {
    "name": "VehicleTitleTypes",
    "kind": "HoudiniQuery",
    "hash": "fc1c99865213330fa00bd0d5c70f210a9d1651c6c13923b379ea8c3ad3b0f144",

    "raw": `query VehicleTitleTypes {
  vehicleTitleTypes
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "vehicleTitleTypes": {
                "type": "String",
                "keyRaw": "vehicleTitleTypes",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=3f14c216f3f05f81662ff0ccfce2d5baed24dac11eda5b3e840ca6916076ce2d";