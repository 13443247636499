export default {
    "name": "LIB_storeLocations",
    "kind": "HoudiniQuery",
    "hash": "61b7c9952d65f2da4cfcfc0897b8565fa05a7667ec887434a652d54e422c5536",

    "raw": `query LIB_storeLocations($storeIds: [Int!]) {
  locations(filter: {allowInventory: true, storeIds: $storeIds}) {
    id
    name
    description
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "locations": {
                "type": "Location",
                "keyRaw": "locations(filter: {allowInventory: true, storeIds: $storeIds})",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "storeIds": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9ce09963f0773b27247fd53a84e535a108bdf61a352a9e5c407a6134fdba82ef";