<script lang="ts">
	import type { CurrencyRange } from 'utility/search-fields'

	import Pin from 'components/Pin.svelte'
	import { createEventDispatcher } from 'svelte'
	import CurrencyInput from '@isoftdata/svelte-currency-input'

	export let label: string
	export let from: string = ''
	export let to: string = ''
	export let disabled: boolean = false
	export let isPinned: boolean = false

	const dispatch = createEventDispatcher<{
		change: CurrencyRange
	}>()
</script>

<label
	for="currencyRangeFrom"
	class="col-form-label pb-0"
	>{label} <Pin {isPinned} />
</label>
<div class="form-row">
	<div class="col-12 col-md-6">
		<CurrencyInput
			id="currencyRangeFrom"
			label="From"
			bind:value={from}
			{disabled}
			on:change={() => {
				if (from && (!to || parseFloat(to) === 0)) {
					to = from
				}
				dispatch('change', { from, to })
			}}
			{...$$restProps}
		></CurrencyInput>
	</div>
	<div class="col-12 col-md-6">
		<CurrencyInput
			label="To"
			bind:value={to}
			{disabled}
			on:change={() => dispatch('change', { from, to })}
			{...$$restProps}
		></CurrencyInput>
	</div>
</div>
