<script lang="ts">
	import type { Category, InventoryType, SearchField } from 'utility/search-fields'

	import { loadCategories } from 'utility/search-fields'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let category: Category | null = null
	export let inventoryType: InventoryType | null
	export let disabled: boolean = false
	export let vehicle: boolean = false

	let categoryList: Promise<Category[]> | Category[] = []

	async function loadCategoryListAndSet(inventoryType: InventoryType | null) {
		if (!inventoryType) {
			categoryList = []
			category = null
		} else {
			categoryList = loadCategories(inventoryType?.id)
			categoryList.then(categories => {
				categoryList = categories
				//If we have a selected category but after loading the new list, it's not in the list, then we need to the clear the selection
				if (category && !categories.find(categoryListItem => categoryListItem.id === category?.id)) {
					category = null
				}
			})
		}
	}

	$: loadCategoryListAndSet(inventoryType)
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		label="Category"
		placeholder={inventoryType ? '-- Select a Category --' : `Choose ${vehicle ? 'a unit' : 'an inventory'} type first`}
		disabled={disabled || !inventoryType || inEditMode}
		options={categoryList}
		emptyValue={null}
		bind:value={category}
		getLabel={option => option?.name ?? ''}
		{...$$restProps}
		on:change
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
