export default {
    "name": "InventoryLocations",
    "kind": "HoudiniQuery",
    "hash": "04bf0b077fe1feffba456799adec10d3bd2aa0771bb5174abaafd2f3f66f144e",

    "raw": `query InventoryLocations($inventoryId: UInt!) {
  inventory(id: $inventoryId) {
    locations {
      location {
        name
        id
      }
      quantity
      id
    }
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $inventoryId)",

                "selection": {
                    "fields": {
                        "locations": {
                            "type": "InventoryLocation",
                            "keyRaw": "locations",

                            "selection": {
                                "fields": {
                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "quantity": {
                                        "type": "Decimal6",
                                        "keyRaw": "quantity",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=19f3f2ecef9aa071ff8b33558f7a808e12d3f6c9d2f26af083569436351e189c";