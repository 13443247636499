<script lang="ts">
	import type { InventoryTypesFull$result } from '$houdini'
	import type { Mediator } from 'types/common'
	import type { SvelteAsr } from 'types/common'
	import type { Writable } from 'type-fest'
	type InventoryType = Writable<InventoryTypesFull$result['inventoryTypes'][0]>

	import Modal from '@isoftdata/svelte-modal'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import { klona } from 'klona'
	import { graphql } from '$houdini'
	import { upsert } from '@isoftdata/utility-array'
	import { getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	export let asr: SvelteAsr
	export let inventoryTypeList: InventoryType[]
	export let showModal: boolean = false

	let inventoryType: InventoryType | undefined
	let title: string = ''
	let isNew: boolean = false
	let isSaving: boolean = false

	$: isDuplicate = !!inventoryTypeList.find(({ name, id }) => name.toUpperCase() === inventoryType?.name.toUpperCase() && id !== inventoryType?.id)
	$: isDuplicateID = !!inventoryTypeList.find(({ id }) => id === inventoryType?.id)

	function newInventoryType() {
		const newInventoryTypeId =
			Math.max.apply(
				Math,
				inventoryTypeList.map(inventoryType => inventoryType.id),
			) + 1

		return {
			id: newInventoryTypeId,
			setId: newInventoryTypeId,
			name: '',
			vehicle: false,
			active: true,
		}
	}

	export function add() {
		inventoryType = newInventoryType()
		title = 'New Inventory Type'
		showModal = true
		isNew = true
	}

	export function edit(inventoryTypeToEdit: InventoryType) {
		inventoryType = klona(inventoryTypeToEdit)
		title = 'Edit Inventory Type'
		showModal = true
		isNew = false
	}

	export async function save() {
		isSaving = true

		try {
			if (isNew && inventoryType) {
				await createMutation.mutate({ input: inventoryType })
				asr.go(null, { inventoryTypeId: inventoryType.id })
			} else if (inventoryType) {
				await updateMutation.mutate({ input: inventoryType })
				inventoryTypeList = upsert(inventoryTypeList, 'id', inventoryType)
			}
			showModal = false
		} catch (err: any) {
			mediator.publish('showMessage', {
				heading: 'Error Saving Inventory Type',
				message: err.message,
				type: 'danger',
				time: false,
			})
		} finally {
			isSaving = false
		}
	}
	//#region GraphQL
	const updateMutation = graphql(`
		mutation UpdateInventoryType($input: InventoryTypeUpdate!) {
			updateInventoryType(input: $input) {
				active
				id
				name
				setId
				vehicle
			}
		}
	`)

	const createMutation = graphql(`
		mutation CreateInventoryType($input: NewInventoryType!) {
			createInventoryType(input: $input) {
				active
				id
				name
				setId
				vehicle
			}
		}
	`)
	//#endregion
</script>

<Modal
	{title}
	bind:show={showModal}
	on:confirm={save}
	on:close={() => (showModal = false)}
	backdropClickCancels
	confirmButtonText="Save"
	confirmButtonIsLoading={isSaving}
	confirmButtonDisabled={isDuplicate || !inventoryType?.name || (isNew && isDuplicateID)}
>
	{#if inventoryType}
		<fieldset disabled={isSaving}>
			<div class="form-row">
				<div class="col-12 col-sm-8">
					<Input
						label="Name"
						bind:value={inventoryType.name}
					/>
					{#if isDuplicate}
						<small class="text-danger">Name already exists</small>
					{/if}
				</div>
				<div class="col-12 col-sm-4">
					<Input
						label="Type #"
						type="number"
						disabled={!isNew}
						bind:value={inventoryType.id}
						on:change={() => {
							if (inventoryType) {
								inventoryType.setId = inventoryType.id
							}
						}}
					/>
					{#if isNew && isDuplicateID}
						<small class="text-danger">ID already exists</small>
					{/if}
				</div>
				<div class="col-12 col-sm-6">
					<Select
						label="Set #"
						bind:value={inventoryType.setId}
					>
						{#if isNew}
							<option value={inventoryType.id}>{inventoryType.name} - {inventoryType.id}</option>
						{/if}
						{#each inventoryTypeList as { id, name }}
							<option value={id}>{name} - {id}</option>
						{/each}
					</Select>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col-6 col-sm-4">
					<Checkbox
						bind:checked={inventoryType.active}
						label="Active"
					/>
					<Checkbox
						bind:checked={inventoryType.vehicle}
						label="Vehicle Unit"
					/>
				</div>
			</div>
		</fieldset>
	{/if}
</Modal>
