<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import type { AsrTabs } from './configuration'

	export let hasVisibleChildState: boolean = false
	export let childStates: AsrTabs = []
	export let asr: SvelteAsr
</script>

<div class="card mb-3">
	<div class="card-header">
		<div>
			<p class="h4"><i class="fas fa-cog fa-fw mr-1"></i><span class="d-none d-sm-inline-block">Configuration</span></p>
			{#if hasVisibleChildState}
				<AsrNavTabBar
					tabs={childStates}
					{asr}
					breakpoint="md"
				/>
			{/if}
		</div>
	</div>
	<div class="card-body">
		{#if hasVisibleChildState}
			<uiView></uiView>
		{:else}
			<div class="jumbotron">
				<h1 class="display-4 small-2-rem-font">
					<i class="fa-solid fa-triangle-exclamation mr-sm-3"></i>
					No configuration options available.
				</h1>
				<p class="lead">You do not have permission for any configuration options. Contact your administrator if you think this is in error.</p>
			</div>
		{/if}
	</div>
</div>
