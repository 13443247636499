<script lang="ts">
	import type { SearchField } from 'utility/search-fields'
	import { graphql } from '$houdini'
	import { onMount } from 'svelte'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import Pin from 'components/Pin.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let titleStatus: string | null = null
	export let label: string = 'Title Status'

	let titleStatuses: string[] = []

	const getTitleStatusQuery = graphql(`
		query VehicleTitleStatuses {
			vehicleTitleStatuses
		}
	`)

	onMount(async () => {
		const res = await getTitleStatusQuery.fetch({ policy: 'CacheOrNetwork' })
		titleStatuses = res.data?.vehicleTitleStatuses ?? []
	})
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		{label}
		options={titleStatuses}
		bind:value={titleStatus}
		emptyValue={null}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
