/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { v4 as uuid } from '@lukeed/uuid'
import pProps from 'p-props'

import { storesQueryForStoresConfig, statesQuery } from 'common/graphql-queries'
import svelteComponent from './Store.svelte'
import type { Store, State } from 'types/store'
import { stringToBoolean } from '@isoftdata/utility-string'

export default function ({ stateRouter, mediator }) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
	stateRouter.addState({
		name: 'app.configuration.store',
		route: 'store',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			component: svelteComponent, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		canLeaveState(domApi) {
			// Kind of ugly, but this gets the `canLeave` prop from the svelte component
			// return !domApi || domApi.$$.ctx[domApi.$$.props.canLeave]
			return !domApi.storesChanged || confirm('You have unsaved changes. Are you sure you want to leave?')
		},
		async resolve(_data, parameters: { lastResetTime: string; lastSavedTime: string }) {
			const { lastSavedTime, lastResetTime } = parameters
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { storesRes, sessionRes, statesRes }: { storesRes: any; sessionRes: any; statesRes: { states: { stateAbbreviation: string; stateName: string }[] } } = await pProps({
				storesRes: mediator.publish('graphqlFetch', storesQueryForStoresConfig),
				sessionRes: mediator.publish(
					'graphqlFetch',
					`query permissionQuery {
				session {
					permissions {
						CONFIGURATION_CAN_CONFIGURE_STORES
					}
				}
			}`
				),
				statesRes: mediator.publish('graphqlFetch', statesQuery),
			})
			const currentSession = JSON.parse(sessionStorage.getItem('user') ?? '{}')
			const loggedInPlant = currentSession.currentStore ?? null
			const filteredStores: Store[] = storesRes.stores.reduce((acc: Store[], store) => {
				if (stringToBoolean(store.private) && store.id !== loggedInPlant) {
					return acc
				}
				let remittanceIsMainAddress = false
				if (
					store.remittanceAddress === store.address &&
					store.remittanceCity === store.city &&
					store.remittanceState === store.state &&
					store.remittanceZip === store.zip &&
					store.remittanceCountry === store.country &&
					store.remittanceAddress !== '' &&
					store.remittanceCity !== '' &&
					store.remittanceState !== '' &&
					store.remittanceZip !== '' &&
					store.remittanceCountry !== ''
				) {
					remittanceIsMainAddress = true
				}
				acc.push({
					...store,
					remittanceIsMainAddress,
					private: stringToBoolean(store.private),
					street: store.address,
					uuid: uuid(),
					deleted: false,
					dirty: false,
				})
				return acc
			}, [])

			const states: State[] = statesRes.states.map(state => {
				return {
					abbreviation: state.stateAbbreviation,
					name: state.stateName,
				}
			})

			const selectedStoreUuid = filteredStores[0].uuid
			const userStores = currentSession?.stores ?? []
			return {
				lastResetTime,
				lastSavedTime,
				states,
				selectedStoreUuid,
				stores: filteredStores,
				canConfigureStores: sessionRes.session.permissions.CONFIGURATION_CAN_CONFIGURE_STORES,
				userStores,
			}
		},
	})
}
