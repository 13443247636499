<script lang="ts">
	import type { SearchField } from 'utility/search-fields'

	import { slide } from 'svelte/transition'
	import Button from '@isoftdata/svelte-button'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required

	function pinField() {
		if (pinnedSearchFields.has(searchField.key)) {
			pinnedSearchFields.delete(searchField.key)
			if (searchField.dependants?.length) {
				for (const dependant of searchField.dependants) {
					pinnedSearchFields.delete(dependant)
				}
			}
		} else {
			pinnedSearchFields.add(searchField.key)
			//Also pin any dependencies the search field might have
			for (const dependency of searchField.dependencies ?? []) {
				pinnedSearchFields.add(dependency)
			}
		}
		pinnedSearchFields = pinnedSearchFields
	}

	function removeSearchField() {
		selectedSearchFieldsKeys.delete(searchField.key)
		if (searchField.dependants?.length) {
			for (const dependant of searchField.dependants) {
				selectedSearchFieldsKeys.delete(dependant)
			}
		}
		selectedSearchFieldsKeys = selectedSearchFieldsKeys
	}
</script>

<div
	class="col-12 col-md-6 col-lg-4 col-xl-3 parent-col"
	class:in-edit-mode={inEditMode}
>
	<div class="form-row align-items-end">
		<div class="col">
			<slot />
			<!-- Search Field goes here -->
		</div>
		{#if inEditMode}
			<div
				class="col-auto mb-1 d-flex flex-nowrap"
				transition:slide={{ duration: 200, axis: 'x' }}
			>
				<Button
					iconClass="thumbtack"
					class="mr-1"
					size="sm"
					outline={!pinnedSearchFields.has(searchField.key)}
					on:click={pinField}
					title={pinnedSearchFields.has(searchField.key) ? 'Unpin this search field' : 'Pin this search field'}
				/>
				{#if !searchField.alwaysVisible}
					<Button
						iconClass="trash"
						size="sm"
						outline
						color="danger"
						on:click={removeSearchField}
						title="Remove this search field"
					/>
				{/if}
			</div>
		{/if}
	</div>
</div>

<!-- We want to display a border when you mouse over a search fields. In order to not have the UI shift during that, we need to adjust the padding based on the hover/edit mode state -->
<style>
	.parent-col:not(:hover),
	.parent-col:hover:not(.in-edit-mode) {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.parent-col.in-edit-mode:hover {
		border: 1px solid #dee2e6 !important;
		border-color: #007bff !important;
		border-radius: 0.25rem !important;
		padding-right: 4px !important;
		padding-left: 4px !important;
	}
</style>
