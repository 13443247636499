export default {
    "name": "LoginMutation",
    "kind": "HoudiniMutation",
    "hash": "9d6dbe90e0f3de4f159cee908f29cd4ecfa1d02d9b38d79f725b265a114d9218",

    "raw": `mutation LoginMutation($username: String!, $passphrase: String!, $selectedStoreId: UInt!) {
  createSession(user: $username, password: $passphrase, storeId: $selectedStoreId) {
    token
    id
    userName
    currentUserAccountId
    accessType
    accessStatus
    sessionStatus
    storeId
    recordType
    recordId
    ...Permissions
    userAccount {
      id
      username: name
      email: workEmail
      wageRateId
      deliveryZoneId
      glDepartmentId
      driver
      salesPerson
      worker
      firstName
      lastName
      groups {
        id
        name
      }
      stores
      highestAccessPermissions {
        id
        codeName
        category
        description
        level
      }
      settings {
        preferences {
          optInToTestingFeatures
        }
      }
    }
  }
}

fragment Permissions on Session {
  permissions {
    WEB_CAN_LOGIN
    VEHICLES_CAN_EDIT_STOCK_NUMBER
    VEHICLES_CAN_EDIT_STATUS
    VEHICLES_CAN_EDIT_BASIC
    VEHICLES_CAN_EDIT_ATTACHMENTS
    VEHICLES_CAN_EDIT_BIDS
    VEHICLES_CAN_EDIT_NON_BID_UNITS
    DASHBOARD_REPORT_VIEW
    DASHBOARD_REPORT_EDIT_OWN
    DASHBOARD_REPORT_EDIT_ALL
    PARTS_CAN_CLONE_PARTS
    PARTS_CAN_CREATE_CONDITIONS
    PARTS_CAN_EDIT_ATTACHMENTS
    PARTS_CAN_EDIT_COST
    PARTS_CAN_EDIT_GLCATEGORY
    PARTS_CAN_EDIT_INFO
    PARTS_CAN_EDIT_LOCATION
    PARTS_CAN_EDIT_PRICES
    PARTS_CAN_EDIT_QUANTITY
    PARTS_CAN_EDIT_STATUS
    PARTS_CAN_EDIT_STOCKING_DATA
    PARTS_CAN_REPLICATE_PARTS
    PARTS_CAN_SAVE_PARTS
    PARTS_CAN_VIEW_COST
    PARTS_CAN_VIEW_PARTS
    PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES
    PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR
    PART_CONFIGURATION_CAN_EDIT_PART_TYPES
    CONFIGURATION_CAN_CONFIGURE_STORES
    GLOBAL_USER_ADMINISTRATOR
  }
  id
  __typename
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createSession": {
                "type": "Session",
                "keyRaw": "createSession(password: $passphrase, storeId: $selectedStoreId, user: $username)",

                "selection": {
                    "fields": {
                        "permissions": {
                            "type": "SessionPermissions",
                            "keyRaw": "permissions",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "WEB_CAN_LOGIN": {
                                        "type": "Boolean",
                                        "keyRaw": "WEB_CAN_LOGIN",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "VEHICLES_CAN_EDIT_STOCK_NUMBER": {
                                        "type": "Boolean",
                                        "keyRaw": "VEHICLES_CAN_EDIT_STOCK_NUMBER",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "VEHICLES_CAN_EDIT_STATUS": {
                                        "type": "Boolean",
                                        "keyRaw": "VEHICLES_CAN_EDIT_STATUS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "VEHICLES_CAN_EDIT_BASIC": {
                                        "type": "Boolean",
                                        "keyRaw": "VEHICLES_CAN_EDIT_BASIC",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "VEHICLES_CAN_EDIT_ATTACHMENTS": {
                                        "type": "Boolean",
                                        "keyRaw": "VEHICLES_CAN_EDIT_ATTACHMENTS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "VEHICLES_CAN_EDIT_BIDS": {
                                        "type": "Boolean",
                                        "keyRaw": "VEHICLES_CAN_EDIT_BIDS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "VEHICLES_CAN_EDIT_NON_BID_UNITS": {
                                        "type": "Boolean",
                                        "keyRaw": "VEHICLES_CAN_EDIT_NON_BID_UNITS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "DASHBOARD_REPORT_VIEW": {
                                        "type": "Boolean",
                                        "keyRaw": "DASHBOARD_REPORT_VIEW",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "DASHBOARD_REPORT_EDIT_OWN": {
                                        "type": "Boolean",
                                        "keyRaw": "DASHBOARD_REPORT_EDIT_OWN",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "DASHBOARD_REPORT_EDIT_ALL": {
                                        "type": "Boolean",
                                        "keyRaw": "DASHBOARD_REPORT_EDIT_ALL",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_CLONE_PARTS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_CLONE_PARTS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_CREATE_CONDITIONS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_CREATE_CONDITIONS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_ATTACHMENTS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_ATTACHMENTS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_COST": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_COST",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_GLCATEGORY": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_GLCATEGORY",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_INFO": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_INFO",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_LOCATION": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_LOCATION",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_PRICES": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_PRICES",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_QUANTITY": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_QUANTITY",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_STATUS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_STATUS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_EDIT_STOCKING_DATA": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_EDIT_STOCKING_DATA",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_REPLICATE_PARTS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_REPLICATE_PARTS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_SAVE_PARTS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_SAVE_PARTS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_VIEW_COST": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_VIEW_COST",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_CAN_VIEW_PARTS": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_CAN_VIEW_PARTS",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR": {
                                        "type": "Boolean",
                                        "keyRaw": "PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "PART_CONFIGURATION_CAN_EDIT_PART_TYPES": {
                                        "type": "Boolean",
                                        "keyRaw": "PART_CONFIGURATION_CAN_EDIT_PART_TYPES",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "CONFIGURATION_CAN_CONFIGURE_STORES": {
                                        "type": "Boolean",
                                        "keyRaw": "CONFIGURATION_CAN_CONFIGURE_STORES",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "GLOBAL_USER_ADMINISTRATOR": {
                                        "type": "Boolean",
                                        "keyRaw": "GLOBAL_USER_ADMINISTRATOR",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        },

                        "token": {
                            "type": "String",
                            "keyRaw": "token",
                            "nullable": true,
                            "visible": true
                        },

                        "userName": {
                            "type": "String",
                            "keyRaw": "userName",
                            "nullable": true,
                            "visible": true
                        },

                        "currentUserAccountId": {
                            "type": "Int",
                            "keyRaw": "currentUserAccountId",
                            "nullable": true,
                            "visible": true
                        },

                        "accessType": {
                            "type": "String",
                            "keyRaw": "accessType",
                            "nullable": true,
                            "visible": true
                        },

                        "accessStatus": {
                            "type": "String",
                            "keyRaw": "accessStatus",
                            "nullable": true,
                            "visible": true
                        },

                        "sessionStatus": {
                            "type": "String",
                            "keyRaw": "sessionStatus",
                            "nullable": true,
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "nullable": true,
                            "visible": true
                        },

                        "recordType": {
                            "type": "String",
                            "keyRaw": "recordType",
                            "nullable": true,
                            "visible": true
                        },

                        "recordId": {
                            "type": "Int",
                            "keyRaw": "recordId",
                            "nullable": true,
                            "visible": true
                        },

                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "username": {
                                        "type": "String",
                                        "keyRaw": "username",
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "wageRateId": {
                                        "type": "UInt",
                                        "keyRaw": "wageRateId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "deliveryZoneId": {
                                        "type": "UInt",
                                        "keyRaw": "deliveryZoneId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glDepartmentId": {
                                        "type": "UInt",
                                        "keyRaw": "glDepartmentId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "driver": {
                                        "type": "Boolean",
                                        "keyRaw": "driver",
                                        "visible": true
                                    },

                                    "salesPerson": {
                                        "type": "Boolean",
                                        "keyRaw": "salesPerson",
                                        "visible": true
                                    },

                                    "worker": {
                                        "type": "Boolean",
                                        "keyRaw": "worker",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "groups": {
                                        "type": "Group",
                                        "keyRaw": "groups",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "stores": {
                                        "type": "UInt",
                                        "keyRaw": "stores",
                                        "visible": true
                                    },

                                    "highestAccessPermissions": {
                                        "type": "UserAccountPermission",
                                        "keyRaw": "highestAccessPermissions",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "codeName": {
                                                    "type": "String",
                                                    "keyRaw": "codeName",
                                                    "visible": true
                                                },

                                                "category": {
                                                    "type": "String",
                                                    "keyRaw": "category",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "level": {
                                                    "type": "PermissionLevel",
                                                    "keyRaw": "level",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "settings": {
                                        "type": "UserSettings",
                                        "keyRaw": "settings",

                                        "selection": {
                                            "fields": {
                                                "preferences": {
                                                    "type": "UserSettingsPreferencesCategory",
                                                    "keyRaw": "preferences",

                                                    "selection": {
                                                        "fields": {
                                                            "optInToTestingFeatures": {
                                                                "type": "Boolean",
                                                                "keyRaw": "optInToTestingFeatures",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    },

                    "fragments": {
                        "Permissions": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "username": "String",
            "passphrase": "String",
            "selectedStoreId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c3f89477c455b40ce72cf99f51b8754f129dd770a2e399d5781f922b0ba5263c";