<script lang="ts">
	import type { Side, SearchField } from 'utility/search-fields'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import Pin from 'components/Pin.svelte'

	interface SideOption {
		label: string
		value: Side
	}

	const sideList: SideOption[] = [
		{ label: 'Both', value: 'BOTH' },
		{ label: 'Left', value: 'LEFT' },
		{ label: 'N/A', value: 'NA' },
		{ label: 'Right', value: 'RIGHT' },
	]

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let side: SideOption | null = null
	export let disabled: boolean = false
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		label="Side"
		options={sideList}
		emptyValue={null}
		getLabel={option => option?.label ?? 'Any'}
		disabled={disabled || inEditMode}
		bind:value={side}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
