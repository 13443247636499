<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import { graphql } from '$houdini'
	import { format as currencyFormat } from '@isoftdata/utility-currency'

	interface VehicleComponent {
		id: number
		inventoryType: string
		manufacturer: string
		model: string
		category: string
		tagNumber: string
		retailPrice: string
		topImage: string | null
	}

	const vehicleComponentsQuery = graphql(`
		query VehicleComponents($vehicleId: Int!) {
			vehicle(id: $vehicleId) {
				components {
					id
					tagNumber
					retailPrice
					inventoryType {
						name
					}
					manufacturer {
						name
					}
					model {
						name
					}
					category {
						name
					}
					topImage {
						url
					}
				}
			}
		}
	`)

	export let vehicleId: number | undefined
	export let asr: SvelteAsr

	let components: VehicleComponent[] = []

	async function loadAndSetComponents(vehicleId: number | undefined) {
		if (vehicleId) {
			const { data } = await vehicleComponentsQuery.fetch({ variables: { vehicleId } })
			components =
				data?.vehicle?.components.map(part => {
					return {
						...part,
						inventoryType: part.inventoryType?.name ?? '',
						manufacturer: part.manufacturer?.name ?? '',
						model: part.model?.name ?? '',
						category: part.category?.name ?? '',
						topImage: part.topImage?.url ?? null,
					}
				}) ?? []
		} else {
			components = []
		}
	}

	$: loadAndSetComponents(vehicleId)
</script>

<div class="list-group">
	{#each components as part}
		<a
			class="list-group-item list-group-item-action"
			href={asr.makePath('app.part', { inventoryId: part.id })}
		>
			<div class="d-flex">
				<img
					style="max-height: 100px; max-width: 100px;"
					alt={[part.inventoryType, part.manufacturer, part.model].join(' ')}
					src={part.topImage ?? 'images/noimage.jpg'}
					loading="lazy"
					class="mr-3"
				/>
				<div style="overflow: hidden;">
					<h5 class="mb-1 text-truncate">{part.inventoryType}</h5>
					<p
						class="mb-1"
						style="white-space: wrap;"
					>
						{part.manufacturer}
						{part.model}
						{part.category}
					</p>
					<small class="d-block">Tag #: {part.tagNumber}</small>
					<small>Retail Price: {currencyFormat(part.retailPrice)}</small>
				</div>
			</div>
		</a>
	{/each}
</div>
