import { makeDashboardComponent } from '@isoftdata/web-dashboard'
import { dashboardReportTitles as dashboardReportTitlesQuery } from '../../queries'
import { loadChartDataHelper, loadReportMetadataHelper } from 'utility/dashboard-helper'
import { checkSessionPermission } from 'utility/permission'
import template from './dashboard.html'

export default function({ stateRouter, mediator, hasPermission }) {
	stateRouter.addState({
		name: 'app.dashboard',
		route: 'dashboard/:dashboardReportId',
		querystringParameters: [ 'dashboardReportId' ],
		defaultParameters: { dashboardReportId: null },
		template: {
			template,
			components: {
				itDashboard: makeDashboardComponent({
					loadDashboardReportMetadata: ({ dashboardReportId, chartId }) => {
						return loadReportMetadataHelper(mediator, { dashboardReportId, chartId })
					},
					loadChartDataForReport: ({ dashboardReportId, chartId, parameterSelectionList }) => {
						return loadChartDataHelper(mediator, { dashboardReportId, chartId, parameterSelectionList })
					},
					loadDashboardReportTitlesForUser: async() => { // TODO: Filter by share_type and share_id on the server, unless we tell it not to? Or have two different queries?
						const { dashboardReports } = await mediator.publish('graphqlFetch', dashboardReportTitlesQuery, { args: { sharedWithCurrentUser: true } })
						return dashboardReports
					},
				}),
			},
			hasPermission,
		},
		async resolve(data, { dashboardReportId }) {
			if (!checkSessionPermission('DASHBOARD_REPORT_VIEW')) {
				console.error("You don't have permission to view the dashboard.")
				throw {
					redirectTo: {
						name: 'app',
						params: {},
					},
				}
			}
			const { dashboardReports } = await mediator.publish('graphqlFetch', dashboardReportTitlesQuery, { args: { sharedWithCurrentUser: true } })

			if (Number(dashboardReportId)) { // Check if the supplied id matches a valid report (shared with user, exists)
				dashboardReportId = Number(dashboardReportId)
				const isValidReport = dashboardReports.some(report => report?.dashboardReportId === dashboardReportId)
				if (isValidReport) {
					return { dashboardReportId }
				}
			}
			// Otherwise just redirect them to the first valid report
			dashboardReportId = dashboardReports.length ? dashboardReports[0].dashboardReportId : null
			throw {
				redirectTo: {
					name: null,
					params: { dashboardReportId },
				},
			}
		},
		activate(context) {
			const { domApi: ractive } = context
			ractive.on('itDashboard.configure-report-clicked', (context, currentReportId) => {
				stateRouter.go('app.configuration.dashboard.manage', { dashboardReportId: currentReportId })
			})

			ractive.on('itDashboard.report-tab-clicked', (context, dashboardReportId) => {
				stateRouter.go('app.dashboard', { dashboardReportId })
			})
		},

	})
}
