<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import type { Store, SearchField, StaticSearchField, StaticSearchFieldKey, VehicleStaticSearchFieldValue, SearchFieldsByCategory, InventoryQuestionValue } from 'utility/search-fields'

	import Icon from '@isoftdata/svelte-icon'
	import DateRange from 'components/DateRange.svelte'
	import UserAccountsAutocomplete from './UserAccountAutocomplete.svelte'
	import EditModeControls from 'components/EditModeControls.svelte'
	import InputSearchField from 'components/InputSearchField.svelte'
	import StoreSelect from 'components/StoreSelect.svelte'
	import StatusSelect from 'components/StatusSelect.svelte'
	import YearRangeInput from 'components/YearRangeInput.svelte'
	import CategoryAutocomplete from 'components/CategoryAutocomplete.svelte'
	import UserStatusAutocomplete from 'components/UserStatusAutocomplete.svelte'
	import InventoryQuestionField from 'components/InventoryQuestionField.svelte'
	import ModelAutocomplete from 'components/ModelAutocomplete.svelte'
	import InventoryTypeAutocomplete from 'components/InventoryTypeAutocomplete.svelte'
	import ManufacturerAutocomplete from 'components/ManufacturerAutocomplete.svelte'
	import AddEditSearchFilters from 'components/AddEditSearchFilters.svelte'
	import TitleTypeAutocomplete from './TitleTypeAutocomplete.svelte'
	import TitleStatusAutocomplete from './TitleStatusAutocomplete.svelte'
	import LocationAutocomplete from 'components/LocationAutocomplete.svelte'
	import DismantledCheckbox from './DismantledCheckbox.svelte'
	import CurrencyRange from 'components/CurrencyRange.svelte'
	import {
		loadInventoryQuestions,
		getStaticSearchFieldsWithDependencyGraph,
		getInitialSelectedSearchFields,
		getSearchFieldsByCategory,
		getValidTypeFields,
		saveUserSetting,
		computeSearchParameterValues,
	} from 'utility/search-fields'

	// #region props
	export let authorizedStores: Store[] = []
	export let stores: number[] = []
	export let asr: SvelteAsr
	export let userPinnedSearchFields: string[] = []
	// #endregion

	/** List of all static search fields, with their dependencies. (All possible search fields) */
	const staticSearchFields = getStaticSearchFieldsWithDependencyGraph('VEHICLE')
	const staticSearchFieldsMap: Map<StaticSearchFieldKey, StaticSearchField> = new Map(staticSearchFields.map(field => [field.key, field]))
	/** List of all search fields. Set via reactive statement as it can change based on the selection of other search fields */
	let searchFields: SearchField[] = []
	/** List of selected search fields */
	let selectedSearchFieldsKeys = getInitialSelectedSearchFields(staticSearchFields, userPinnedSearchFields)
	/** List of search field values that will be passed to Result state */
	let searchParameterValues: Record<string, unknown> = {}

	let inEditMode: boolean = false
	let typeFields: SearchField[] = []
	let inventoryQuestions: SearchField[] = []
	let pinnedSearchFields: Set<string> = new Set(userPinnedSearchFields)
	let inventoryQuestionValues: Record<string, InventoryQuestionValue> = {}
	let typeFieldValues: Record<string, string> = {} //Type fields can only be strings()
	const vehicleStaticSearchFields = [
		'STORE',
		'STOCK_NUMBER',
		'VIN',
		'INVENTORY_TYPE',
		'STATUS',
		'USER_STATUS',
		'YEAR_RANGE',
		'VEHICLE_MANUFACTURER',
		'VEHICLE_MODEL',
		'CATEGORY',
		'ADJUSTER',
		'BODY_STYLE',
		'CLAIM_NUMBER',
		'LOCATION',
		'JUNKING_NUMBER',
		'FILE_NUMBER',
		'EXTERNAL_COLOR',
		'ENTERED_BY_USER',
		'DISMANTLER',
		'DISMANTLED',
		'DESCRIPTION',
		'DATE_ENTERED',
		'PICKUP_LOCATION',
		'PURCHASED_FROM',
		'RETAIL_PRICE',
		'SELLER_CONTACT',
		'SELLER_INFO',
		'TITLE_STATUS',
		'TITLE_NUMBER',
		'TITLE_TYPE',
	]

	let staticSearchFieldValues: VehicleStaticSearchFieldValue = {
		VIN: '',
		STATUS: '@',
		CATEGORY: null,
		ADJUSTER: null,
		LOCATION: null,
		CONDITION: null,
		STOCK_NUMBER: '',
		BODY_STYLE: null,
		DISMANTLER: null,
		DISMANTLED: undefined,
		TITLE_TYPE: null,
		USER_STATUS: null,
		FILE_NUMBER: null,
		DESCRIPTION: null,
		SELLER_INFO: null,
		CLAIM_NUMBER: null,
		DATE_ENTERED: { from: null, to: null },
		RETAIL_PRICE: { from: '', to: '' },
		TITLE_STATUS: null,
		TITLE_NUMBER: null,
		VEHICLE_MODEL: null,
		INVENTORY_TYPE: null,
		JUNKING_NUMBER: null,
		EXTERNAL_COLOR: null,
		PURCHASED_FROM: null,
		SELLER_CONTACT: null,
		ENTERED_BY_USER: null,
		PICKUP_LOCATION: null,
		VEHICLE_MANUFACTURER: null,
		YEAR_RANGE: { from: null, to: null },
		STORE: stores, //default to their current store, passed from part-search.ts
	}

	async function toggleEditMode(forceExit = false) {
		inEditMode = forceExit ? false : !inEditMode

		if (!inEditMode) {
			const searchFieldsSet = new Set(searchFields.map(field => field.key))
			await saveUserSetting.mutate({
				input: {
					searchVehicles: {
						//Just in case, filter out any invalid keys
						pinnedSearchFields: Array.from(pinnedSearchFields).filter(field => searchFieldsSet.has(field)),
					},
				},
			})
		}
	}

	async function loadAndSetInventoryQuestions({
		inventoryTypeId,
		manufacturerId,
		modelId,
		category,
	}: {
		inventoryTypeId?: number
		manufacturerId?: number
		modelId?: number
		category?: string | null
	}) {
		const loadedInventoryQuestions = await loadInventoryQuestions({ inventoryTypeId, manufacturerId, modelId, category: category })
		let inventoryQuestionNames = new Set()
		inventoryQuestions = loadedInventoryQuestions.reduce((acc: SearchField[], question) => {
			//Maybe the API should be ensuring that we don't get multiple questions with the same name, but as of writing this comment(2024-04-10), it doesn't.
			if (inventoryQuestionNames.has(question.name)) {
				return acc
			} else {
				inventoryQuestionNames.add(question.name)
				return [
					...acc,
					{
						key: `QA_${question.name}`, //we use name as the key because id is specific to a type/man/model/category combo, but we want to be able to use the same question across different combinations
						name: question.name,
						dependencies: [],
						qaFieldData: question,
					},
				]
			}
		}, [])
	}

	$: loadAndSetInventoryQuestions({
		inventoryTypeId: staticSearchFieldValues.INVENTORY_TYPE?.id,
		manufacturerId: staticSearchFieldValues.VEHICLE_MANUFACTURER?.id,
		modelId: staticSearchFieldValues.VEHICLE_MODEL?.id,
		category: staticSearchFieldValues.CATEGORY?.name,
	})
	$: typeFields = getValidTypeFields(staticSearchFieldValues.INVENTORY_TYPE)

	$: searchFields = [...staticSearchFields, ...typeFields, ...inventoryQuestions]
	$: searchFieldsByCategory = getSearchFieldsByCategory(staticSearchFields, typeFields, inventoryQuestions)
	$: searchParameterValues = computeSearchParameterValues(selectedSearchFieldsKeys, staticSearchFieldValues, {}, typeFieldValues, inventoryQuestionValues, staticSearchFieldsMap)

	$: console.log(searchParameterValues)
</script>

<div class="card">
	<div class="card-header">
		<div class="d-flex justify-content-between align-items-end">
			<div class="h4 mb-0">
				<div>
					<Icon
						icon="truck"
						class="mr-2"
					/><span class="d-none d-sm-inline-block">Vehicle Search</span>
				</div>
			</div>
			<div>
				<a
					id="newVehicle"
					href={asr.makePath('app.vehicle')}
					class="btn btn-sm btn-success"
				>
					<i
						class="fas fa-plus"
						aria-hidden="true"
					></i> New Vehicle
				</a>
			</div>
		</div>
	</div>
	<div class="card-body">
		<form
			id="search-field-form"
			on:submit|preventDefault={() => {
				toggleEditMode(true)
				asr.go('app.vehicle-search.results', searchParameterValues)
			}}
		>
			{#if selectedSearchFieldsKeys.size}
				<div class="form-row row-cols-2 align-items-end">
					{#each selectedSearchFieldsKeys as searchFieldKey (searchFieldKey)}
						{@const searchField = searchFields.find(field => field.key === searchFieldKey)}
						{#if searchField}
							{#if searchField?.key === 'STORE'}
								<StoreSelect
									id={searchField?.key}
									{searchField}
									stores={authorizedStores}
									bind:selectedStores={staticSearchFieldValues.STORE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'INVENTORY_TYPE'}
								<InventoryTypeAutocomplete
									id={searchField?.key}
									{searchField}
									bind:inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									vehicle
									{inEditMode}
								/>
							{:else if searchField?.key === 'STATUS'}
								<StatusSelect
									id={searchField?.key}
									{searchField}
									bind:status={staticSearchFieldValues.STATUS}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'USER_STATUS'}
								<UserStatusAutocomplete
									id={searchField?.key}
									{searchField}
									bind:userStatus={staticSearchFieldValues.USER_STATUS}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'CATEGORY'}
								<CategoryAutocomplete
									id={searchField?.key}
									{searchField}
									bind:category={staticSearchFieldValues.CATEGORY}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									vehicle
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									{inEditMode}
								/>
							{:else if searchField?.key === 'YEAR_RANGE'}
								<YearRangeInput
									id={searchField?.key}
									label="Year"
									{searchField}
									bind:value={staticSearchFieldValues.YEAR_RANGE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'VEHICLE_MANUFACTURER'}
								<ManufacturerAutocomplete
									id={searchField?.key}
									type="VEHICLE"
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									{searchField}
									bind:manufacturer={staticSearchFieldValues.VEHICLE_MANUFACTURER}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'VEHICLE_MODEL'}
								<ModelAutocomplete
									id={searchField?.key}
									{searchField}
									type="VEHICLE"
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									manufacturer={staticSearchFieldValues.VEHICLE_MANUFACTURER}
									bind:model={staticSearchFieldValues.VEHICLE_MODEL}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField.key.startsWith('QA_')}
								<InventoryQuestionField
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									on:change={e => {
										inventoryQuestionValues[searchField.key] = e.detail.value
									}}
								/>
							{:else if searchField?.key === 'ENTERED_BY_USER'}
								<UserAccountsAutocomplete
									label="Entered By User"
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									bind:userAccount={staticSearchFieldValues.ENTERED_BY_USER}
								/>
							{:else if searchField?.key === 'DISMANTLER'}
								<UserAccountsAutocomplete
									label="Dismantler"
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									bind:userAccount={staticSearchFieldValues.DISMANTLER}
								/>
							{:else if searchField?.key === 'DATE_ENTERED'}
								<EditModeControls
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
								>
									<DateRange
										id={searchField?.key}
										label="Date Entered"
										dates={staticSearchFieldValues.DATE_ENTERED}
										on:change={e => {
											staticSearchFieldValues.DATE_ENTERED = e.detail.dates
										}}
										isPinned={pinnedSearchFields.has(searchField.key)}
									/>
								</EditModeControls>
							{:else if searchField?.key === 'TITLE_TYPE'}
								<TitleTypeAutocomplete
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									bind:titleType={staticSearchFieldValues.TITLE_TYPE}
								/>
							{:else if searchField?.key === 'TITLE_STATUS'}
								<TitleStatusAutocomplete
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									bind:titleStatus={staticSearchFieldValues.TITLE_STATUS}
								/>
							{:else if searchField?.key === 'LOCATION'}
								<LocationAutocomplete
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:inventoryLocation={staticSearchFieldValues.LOCATION}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									stores={staticSearchFieldValues.STORE}
								/>
							{:else if searchField?.key === 'DISMANTLED'}
								<DismantledCheckbox
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:checked={staticSearchFieldValues.DISMANTLED}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
								/>
							{:else if searchField?.key === 'RETAIL_PRICE'}
								<EditModeControls
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
								>
									<CurrencyRange
										label="Retail Price"
										id={searchField?.key}
										{searchField}
										{inEditMode}
										from={staticSearchFieldValues[searchField.key]?.from}
										to={staticSearchFieldValues[searchField.key]?.to}
										on:change={e => {
											staticSearchFieldValues[searchField.key].from = e.detail.from
											staticSearchFieldValues[searchField.key].to = e.detail.to
										}}
										bind:pinnedSearchFields
										bind:selectedSearchFieldsKeys
									/>
								</EditModeControls>
							{:else if searchField.key.startsWith('TYPE_FIELD_') || vehicleStaticSearchFields.includes(searchField.key)}
								<InputSearchField
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									on:change={e => {
										staticSearchFieldValues[searchField.key] = e.detail.value
									}}
								/>
							{:else}
								<p>Unsupported field type: {searchField.key}</p>
							{/if}
						{/if}
					{/each}
				</div>
			{:else}
				<div class="jumbotron my-3">
					<h1 class="display-4">No selected search filters.</h1>
					<p class="lead">Use the "Add Search Filters" dropdown below.</p>
				</div>
			{/if}
		</form>
	</div>

	<div class="card-footer">
		<AddEditSearchFilters
			label="Vehicles"
			{searchFieldsByCategory}
			{searchFields}
			{inEditMode}
			bind:selectedSearchFieldsKeys
			on:toggleEditMode={e => {
				toggleEditMode(e.detail)
			}}
		/>
	</div>
</div>

<uiView></uiView>
