import type { AppContext } from 'types/common'

import { graphql } from '$houdini'
import { getSession } from 'stores/session'
import VehicleSearch from './VehicleSearch.svelte'

const dataQuery = graphql(`
	query VehicleSearchData {
		session {
			userAccount {
				authorizedStores {
					id
					name
					code
				}
				settings {
					searchVehicles {
						pinnedSearchFields
					}
				}
			}
		}
	}
`)

/* settings {
	searchVehicles {
		pinnedSearchFields
	}
} */

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.vehicle-search',
		route: 'vehicle-search',
		querystringParameters: [],
		template: {
			svelte: true,
			component: VehicleSearch,
		},
		async resolve(_stateRouterData, _parameters) {
			const { currentStore } = getSession()
			const { data } = await dataQuery.fetch()

			return {
				authorizedStores: data?.session?.userAccount?.authorizedStores ?? [],
				stores: currentStore ? [currentStore] : [], //This is the list of selected stores, so by default it will be their currently logged in store
				userPinnedSearchFields: data?.session?.userAccount?.settings?.searchVehicles?.pinnedSearchFields ?? [],
			}
		},
	})
}
