import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/UserAccountData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class UserAccountDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "UserAccountDataStore",
			variables: false,
		})
	}
}

export async function load_UserAccountData(params) {
	await initClient()

	const store = new UserAccountDataStore()

	await store.fetch(params)

	return {
		UserAccountData: store,
	}
}
