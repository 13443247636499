export default {
    "name": "LIB_CreateItemAttachments",
    "kind": "HoudiniMutation",
    "hash": "66a3fea31d06a6c2b44c95f1e98613724fd9727621ed8512d548fc97621ae717",

    "raw": `mutation LIB_CreateItemAttachments($input: NewItemAttachments!) {
  createItemAttachments(input: $input) {
    fileId: id
    rank
    public
    file {
      id
      name
      path: url
      type
      size
      mimeType: mimetype
      extension
      updated
      createdDate: created
    }
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createItemAttachments": {
                "type": "ItemAttachment",
                "keyRaw": "createItemAttachments(input: $input)",

                "selection": {
                    "fields": {
                        "fileId": {
                            "type": "UInt",
                            "keyRaw": "fileId",
                            "visible": true
                        },

                        "rank": {
                            "type": "Int",
                            "keyRaw": "rank",
                            "nullable": true,
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "file": {
                            "type": "File",
                            "keyRaw": "file",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "FileType",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "size": {
                                        "type": "Int",
                                        "keyRaw": "size",
                                        "visible": true
                                    },

                                    "mimeType": {
                                        "type": "String",
                                        "keyRaw": "mimeType",
                                        "visible": true
                                    },

                                    "extension": {
                                        "type": "String",
                                        "keyRaw": "extension",
                                        "visible": true
                                    },

                                    "updated": {
                                        "type": "Date",
                                        "keyRaw": "updated",
                                        "visible": true
                                    },

                                    "createdDate": {
                                        "type": "DateTime",
                                        "keyRaw": "createdDate",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewItemAttachments"
        },

        "types": {
            "NestedNewInventoryAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "NewItemAttachments": {
                "attachments": "NestedNewInventoryAttachment",
                "sku": "UInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=55f726c1ca3bb26de47bf00a01f3c2711257eccf54f3f96ce6b474c0b8ef45db";