import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/UserStatuses'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class UserStatusesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "UserStatusesStore",
			variables: false,
		})
	}
}

export async function load_UserStatuses(params) {
	await initClient()

	const store = new UserStatusesStore()

	await store.fetch(params)

	return {
		UserStatuses: store,
	}
}
