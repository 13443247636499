<script lang="ts">
	import type { PartModel } from 'utility/load-models-for-manufacturer'
	import type { FlatAttachment, OptionValueMap, PartForClient as Part, SettingValues, TypeField } from './part'
	import type { PartScreenStageOne$result, LIB_storeLocations$result, VehicleLookup$result, AppraisalQuery$result, AppraisalQuery$input } from '$houdini'
	import type { ConditionalKeys } from 'type-fest'
	import type { Mediator, SvelteAsr } from 'types/common'

	import Table, { type Column } from '@isoftdata/svelte-table'
	import Input from '@isoftdata/svelte-input'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Select from '@isoftdata/svelte-select'
	import TextArea from '@isoftdata/svelte-textarea'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Button from '@isoftdata/svelte-button'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import LoadItemModal from '@isoftdata/svelte-load-item-modal'

	import LocationTable from './LocationTable.svelte'
	import QaList from './QaList.svelte'

	import { format as currencyFormat } from '@isoftdata/utility-currency'
	import { graphql } from '$houdini'
	import { localWritable } from '@macfja/svelte-persistent-store'
	import { checkSessionPermission } from 'utility/permission'
	import { getContext, onMount, tick, type ComponentEvents } from 'svelte'
	import { reduceToTruthyValues } from '@isoftdata/utility-object'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import loadModelsForManufacturer from 'utility/load-models-for-manufacturer'
	import loadVehicleModelsForMake from 'utility/load-models-for-make'
	import getEventValue from 'utility/get-event-value'
	import { formatISO } from 'date-fns'

	export let asr: SvelteAsr
	export let assyModels: Array<PartModel>
	export let glCategories: PartScreenStageOne$result['glCategories']
	export let inventoryConditions: PartScreenStageOne$result['inventoryConditions']
	// export let inventoryOptionsLoading: boolean
	export let inventoryTypeList: PartScreenStageOne$result['inventoryTypeList']
	export let inventoryTypeData: PartScreenStageOne$result['inventoryTypeList'][number] | null
	export let optionValueMap: OptionValueMap
	export let origPart: Part
	export let part: Part
	export let attachments: Array<FlatAttachment>
	export let typeFields: Array<TypeField>
	/** "True" Models*/
	export let partModels: Array<PartModel>
	export let partStoreLocations: LIB_storeLocations$result['locations']
	export let saleClasses: PartScreenStageOne$result['saleClasses']
	export let sellPriceClasses: PartScreenStageOne$result['sellPriceClasses']
	export let settingValues: SettingValues
	export let userPartStatusList: PartScreenStageOne$result['userPartStatusList']
	export let vehicleMakes: PartScreenStageOne$result['vehicleMakes']
	export let vehicleModels: Array<string>
	export let vendorList: PartScreenStageOne$result['vendors']['items']

	let coreClassHistoryLoading = false
	let showAllPriceFields = false
	let selectedSerialIndex = -1
	let trueModelsLoading = false
	let assyModelsLoading = false
	let allowVirtualLocations = !settingValues.location.enforceLocationHierarchy

	let vehicleLookupModal: LoadItemModal<VehicleLookup$result['vehicles']['items'][number]> | undefined = undefined
	let qaList: QaList | undefined = undefined
	let serialQaList: QaList | undefined = undefined
	let locationTable: LocationTable | undefined = undefined

	let disableSerialQaInputs = false
	let appraisal: AppraisalQuery$result['appraisal'][number] = null
	let appraisalDetails = ''

	$: inventoryTypeCategories = inventoryTypeData?.categories || []
	$: inventoryTrueManufacturers = inventoryTypeData?.partManufacturers || []
	$: typeSetPartType = inventoryTypeList.find(type => type.inventoryTypeId === inventoryTypeData?.typeSetId)
	$: inventoryAssyManufacturers = typeSetPartType?.partManufacturers || []
	$: selectedSerialId = selectedSerialIndex === -1 ? null : part.serials[selectedSerialIndex].id
	$: selectedSerialUuid = selectedSerialIndex === -1 ? null : part.serials[selectedSerialIndex].uuid
	// $optionValueMap accesses the value of the store and should make this reactive
	$: selectedSerialQuestions =
		selectedSerialIndex === -1
			? $optionValueMap &&
				part.inventoryOptions.map(option => ({
					...option,
					value: optionValueMap.get({ optionId: option.id }) ?? '',
				}))
			: $optionValueMap &&
				part.inventoryOptions.map(option => ({
					...option,
					value:
						optionValueMap.get({
							optionId: option.id,
							serialId: selectedSerialId,
							serialUuid: selectedSerialUuid,
						}) ?? '',
				}))

	const mediator = getContext<Mediator>('mediator')
	const cardsOpen = localWritable('partScreenCardsOpen', {
		basic: true,
		marketing: true,
		fulfillment: true,
		replenishable: true,
		stocking: true,
		salesAndShipping: true,
		vehicle: true,
	})

	const noneSaleClass = saleClasses.find(saleClass => saleClass.code === 'NONE') ?? null

	const serialTableColumns: Array<Column> = [
		{ property: 'number', minWidth: '150px', name: 'Serial #' },
		{ property: 'displayStatus', name: 'Status', width: '1rem' },
		{ property: 'location[name]', minWidth: '150px', name: 'Location' },
		{ property: 'source', name: 'Source', width: '1rem' },
		{ property: 'usedOn', name: 'Used On', width: '1rem' },
	]

	function refetchQa() {
		const list = serialQaList ?? qaList
		if (part.inventoryTypeId) {
			list?.fetchNewQuestions({
				categoryName: part.category?.name ?? '',
				manufacturerId: part.manufacturer?.id ?? null,
				modelId: part.model?.id ?? null,
				inventoryTypeId: part.inventoryTypeId,
			})
		}
	}

	async function appraise(manual = false) {
		const vehicle = part.vehicle ?? null

		const obj = {
			category: part.category?.name ?? '',
			manufacturer: part.manufacturer ? inventoryTrueManufacturers.find(manufacturer => manufacturer.id === part.manufacturer?.id)?.name : null,
			model: part.model ? partModels.find(model => model.id === part.model?.id)?.name : null,
			inventoryTypeId: part.inventoryTypeId,
			originCode: 'HTP_LIVE',
			year: Number(vehicle?.year || part.vehicleYear) || null, //TODO figure out why this isn't typed right. Type is set to number, but it can be string at runtime. Svelte Input component since the type attribute isn't number?
			vehicleMake: vehicle?.manufacturer?.name || part.vehicleMake,
			vehicleModel: vehicle?.model?.name || part.vehicleModel,
		}

		const appraisalInput = reduceToTruthyValues({
			object: obj,
		}) as Partial<typeof obj>

		// Type narrow the object so we know we have all the required fields
		function isAppraisalInput(input: any): input is AppraisalQuery$input['inputs'][number] {
			return !!input.inventoryTypeId && !!input.originCode
		}

		// We get a graqhql query error if we don't have all the required fields
		if (manual && !isAppraisalInput(appraisalInput)) {
			mediator.publish('showMessage', {
				heading: 'Missing Required Fields',
				message: 'Please select a part type before running an appraisal.',
				color: 'warning',
				time: false,
			})
			return
		} else if (!isAppraisalInput(appraisalInput)) {
			// fail silently if it wasn't triggered by a user action
			appraisalDetails = 'Missing required fields to appraise.'
			return
		}

		const { data } = await appraisalQuery.fetch({
			variables: {
				inputs: [appraisalInput],
			},
		})
		if (!data) {
			return
		}
		const partAppraisal: AppraisalQuery$result['appraisal'][number] = data?.appraisal[0]
		appraisal = partAppraisal
		appraisalDetails = [`Min: ${currencyFormat(partAppraisal?.min ?? 0)}`, `Max: ${currencyFormat(partAppraisal?.max ?? 0)}`, `Total Items: ${partAppraisal?.itemsAffected || 0}`].join('\n')
	}

	function useSuggestedQuantities() {
		part.minQuantity = Number(part.suggestedMinQuantity) || 0
		part.maxQuantity = Number(part.suggestedMaxQuantity) || 0
	}

	function shouldShowPriceField(price: number | string, replenishable: boolean, showAllPriceFields: boolean) {
		return replenishable || showAllPriceFields || Number(price) !== 0
	}

	//#region change handlers
	function qaInputChange(event: ComponentEvents<QaList>['qaInputChange']) {
		const option = event.detail
		if (selectedSerialIndex > -1 && option.value) {
			optionValueMap.set(
				{
					optionId: option.id,
					serialId: part.serials[selectedSerialIndex].id,
					serialUuid: part.serials[selectedSerialIndex].uuid,
				},
				option.value,
			)
		} else if (selectedSerialIndex > 1 && !option.value) {
			optionValueMap.delete({
				optionId: option.id,
				serialId: part.serials[selectedSerialIndex].id,
				serialUuid: part.serials[selectedSerialIndex].uuid,
			})
		} else if (option.value) {
			optionValueMap.set({ optionId: option.id }, option.value)
		} else {
			optionValueMap.delete({ optionId: option.id })
		}
	}

	function replenishableChange() {
		let newStockCategory = part.stockCategory
		let newPublic = part.public

		// The desktop will, when you uncheck 'Replenishable', set the stock category to 'STANDARD' if there's a vehicleId, or 'MISC' if there's not.
		// When you check 'Replenishable', it will always set the stock category to 'REPLENISHABLE'
		if (part.replenishable) {
			newStockCategory = 'REPLENISHABLE'
			newPublic = !!settingValues.inventory.defaultReplenishablePartsArePublic
		} else if (part.vehicleId) {
			newStockCategory = 'STANDARD'
			newPublic = !!settingValues.inventory.defaultNonReplenishablePartsArePublic
		} else {
			newStockCategory = 'MISC'
			newPublic = !!settingValues.inventory.defaultNonReplenishablePartsArePublic
		}

		part.stockCategory = newStockCategory
		part.public = newPublic
		part.saleClass = newPublic ? part.saleClass : noneSaleClass
	}

	function numberChange(event: Event, key: ConditionalKeys<Part, number>) {
		part[key] = Number(getEventValue(event))
	}

	function serializePart(serialize: boolean, serial: string) {
		if (serialize && confirm('Are you sure you want to serialize this part? After saving, this cannot be undone.')) {
			part.serialized = true
			if (!part.serials.length) {
				addSerial(serial, part.locations[0])
			}
		} else if (serialize) {
			part.newSerial = ''
		} else if (
			!serialize &&
			(!part.serials.some(serial => serial.number && !serial.deleted) || confirm('Are you sure you want to remove serial numbers from this part? Any unsaved serial information will be discarded.'))
		) {
			part.serialized = false
		}
	}

	async function addSerial(number = '', location: (typeof part.locations)[number] | null = null) {
		const newSerialUuid = uuid()
		const optionsWithDefaults = part.inventoryOptions.map(option => {
			const defaultValue = optionValueMap.get({ optionId: option.id }) ?? ''
			if (defaultValue) {
				optionValueMap.set({ optionId: option.id, serialId: null, serialUuid: newSerialUuid }, defaultValue)
			}
			return {
				...option,
				value: defaultValue,
			}
		})
		part.serials.push({
			id: null,
			uuid: newSerialUuid, // need a unique id for new, unsaved serials
			inventoryOptions: optionsWithDefaults,
			location: klona(location),
			number,
			status: 'AVAILABLE',
			displayStatus: 'Available',
			enteredOnDocumentId: null,
			enteredOnDocumentLineId: null,
			enteredOnDocumentStoreId: null,
			enteredOnDocumentType: null,
			usedOnDocumentStoreId: null,
			dateEntered: new Date(),
			usedOnDocumentType: null,
			usedOnDocumentId: null,
			usedOn: 'N/A',
			source: 'N/A',
		})
		part.serials = part.serials
		selectedSerialIndex = part.serials.length - 1
		await tick()
		// If a # if provided, focus the location field, otherwise the serial # field
		document.getElementById(`serial-${number ? 'location' : 'number'}-${selectedSerialIndex}`)?.focus()
	}

	function deleteSerial() {
		if (selectedSerialIndex === -1) {
			return
		}

		// Remove quantity at the serial's location, and delete it if needed
		const serialLocation = part.serials[selectedSerialIndex].location
		const serialLocationIndex = part.locations.findIndex(location => location.name === serialLocation?.name)
		if (serialLocationIndex > -1 && !part.locations[serialLocationIndex].permanent && part.locations[serialLocationIndex].quantity <= 1) {
			part.locations = part.locations.toSpliced(serialLocationIndex, 1)
		} else if (serialLocationIndex > -1 && part.locations[serialLocationIndex].quantity > 0) {
			part.locations[serialLocationIndex].quantity = part.locations[serialLocationIndex].quantity - 1
		}

		part.serials[selectedSerialIndex].deleted = true
		selectedSerialIndex = -1
	}

	async function serialLocationChange(newSerialLocation: (typeof part.serials)[number]['location'], serialIndex: number) {
		const oldSerialLocation = part.serials[serialIndex].location
		part.serials[serialIndex].location = {
			id: newSerialLocation?.id ?? null,
			name: newSerialLocation?.name ?? '',
			description: newSerialLocation?.description ?? '',
			unsaved: true,
		}
		const oldPartLocationIndex = part.locations.findIndex(location => location.name === oldSerialLocation?.name)
		const oldPartLocation = oldPartLocationIndex > -1 ? part.locations[oldPartLocationIndex] : null
		const rank: number | undefined = oldPartLocation?.rank ?? undefined

		if (oldPartLocation && oldPartLocation.quantity > 1) {
			// reduce quantity
			oldPartLocation.quantity = oldPartLocation.quantity - 1
		} else if (oldPartLocation) {
			// remove location
			part.locations = part.locations.toSpliced(oldPartLocationIndex, 1)
		}

		// Tick so changes can propagate to LocationTable before we add new ones
		await tick()

		const newPartLocationIndex = part.locations.findIndex(location => location.name === newSerialLocation?.name)
		const newPartLocation = newPartLocationIndex > -1 ? part.locations[newPartLocationIndex] : null

		const newPartStoreLocation = partStoreLocations.find(location => location.name === newSerialLocation?.name)
		if (newPartLocation) {
			// increase quantity
			newPartLocation.quantity = newPartLocation.quantity + 1
			part.locations = part.locations
		} else if (newPartStoreLocation) {
			// associate "real" location
			locationTable?.addLocation(newPartStoreLocation.name, newPartStoreLocation.id, rank)
		} else {
			// virtual location
			locationTable?.addLocation(newSerialLocation?.name, rank)
		}
		locationTable?.normalizeRanks()
	}

	async function onDefaultVendorChange(event: CustomEvent<(typeof vendorList)[number]>) {
		// TODO: vendor pricing stuff
		const vendor = event.detail
		if (!vendor) {
			return
		}

		part.defaultVendor = {
			...event.detail,
			coreClassHistory: [],
		}
		coreClassHistoryLoading = true
		try {
			const { data } = await vendorCoreClassHistoryQuery.fetch({
				variables: { vendorId: vendor.id },
			})

			part.defaultVendor = {
				...vendor,
				coreClassHistory: data?.vendor.coreClassHistory ?? [],
			}
		} catch (err: any) {
			console.error(err)
			mediator.publish('showMessage', {
				heading: 'Error Loading Core Class History',
				message: err.message,
				color: 'danger',
				time: false,
			})
		} finally {
			coreClassHistoryLoading = false
		}
	}
	//#endregion

	async function checkModelCategoryPricing() {
		// TODO use the stored procedure that does this and vendor pricing
		if (part.model && part.category) {
			try {
				const { data } = await modelCategoryPricingQuery.fetch({
					variables: {
						modelId: part.model.id,
						categoryId: part.category.id,
					},
				})
				if (data?.modelCategoryPricing?.pricing) {
					const pricing = data.modelCategoryPricing.pricing

					part.jobberPrice = pricing.jobber
					part.jobberCorePrice = pricing.jobberCore
					part.retailPrice = pricing.retail
					part.retailCorePrice = pricing.retailCore
					part.listPrice = pricing.list
					part.wholesalePrice = pricing.wholesale
					part.wholesaleCorePrice = pricing.wholesaleCore
				}
			} catch (err: any) {
				mediator.publish('showMessage', {
					heading: 'Error Updating Default Pricing',
					message: err.message,
					color: 'danger',
					time: false,
				})
			}
		}
	}

	onMount(() => {
		appraise()
	})

	async function doVehicleSearch(search: string) {
		const { data } = await vehicleSearchQuery.fetch({
			variables: {
				filter: {
					search,
				},
			},
		})

		return data?.vehicles.items ?? []
	}

	const vehicleSearchQuery = graphql(`
		query VehicleLookup($filter: VehicleFilter) {
			vehicles(filter: $filter) {
				items {
					bodyStyle
					id
					manufacturer {
						name
					}
					model {
						name
					}
					year
					stockNumber
					store {
						code
						name
					}
					topImage {
						name
						url
					}
					vin
				}
			}
		}
	`)

	const modelCategoryPricingQuery = graphql(`
		query LIB_modelCategoryPricing($modelId: Int!, $categoryId: Int) {
			modelCategoryPricing(modelId: $modelId, categoryId: $categoryId) {
				modelId
				category
				notes
				pricing {
					jobber
					jobberCore
					list
					retail
					retailCore
					wholesale
					wholesaleCore
				}
			}
		}
	`)

	const vendorCoreClassHistoryQuery = graphql(`
		query Vendor($vendorId: UInt!) {
			vendor(id: $vendorId) {
				coreClassHistory
			}
		}
	`)

	const appraisalQuery = graphql(`
		query AppraisalQuery($inputs: [AppraisalInput!]!) {
			appraisal(inputs: $inputs) {
				value
				relevance
				min
				max
				itemsAffected
				deviance
			}
		}
	`)
</script>

<CollapsibleCard
	headerText="Basic Info"
	cardHeaderClass="card-header d-flex justify-content-between h4"
	entireHeaderToggles
	bind:bodyShown={$cardsOpen.basic}
>
	<div class="row">
		{#if part.topImage}
			<div class="col-sm-12 col-md-2 d-flex justify-content-center">
				<a
					target="_blank"
					href={part.topImage.path}
					style="height: min-content;"
				>
					<img
						class="img-fluid rounded img-thumbnail p-0"
						src="{part.topImage.path}?height=240&width=240&fit=contain"
						title={part.topImage.name}
						alt={`Tag #${part.tagNumber}`}
					/>
				</a>
			</div>
		{/if}
		<div
			class="col-sm-12 col-md-10"
			class:col-md-10={part.topImage}
			class:col-md-12={!part.topImage}
		>
			<div class="form-row">
				<div class="col-12 col-lg-10">
					<div class="form-row">
						<div class="col-12 col-md-2 col-xl-2">
							<Input
								label="Tag #"
								bind:value={part.tagNumber}
								placeholder="Leave blank to auto-generate"
								title="Leave blank to auto-generate"
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
							/>
						</div>
						<div class="col-12 col-md-5 col-xl-4">
							<Autocomplete
								label="Part Type"
								title="The type of the inventory record"
								required={!!settingValues.inventory.inventoryTypeRequired}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO') || !!part.innodbInventoryid}
								options={inventoryTypeList}
								getLabel={type => (type ? `${type?.inventoryTypeId} - ${type?.name}` : '')}
								bind:value={inventoryTypeData}
								on:change={event => {
									part.inventoryTypeId = event.detail?.inventoryTypeId ?? null
									refetchQa()
									appraise()
								}}
							>
								<svelte:fragment
									slot="option"
									let:option
								>
									<span class="badge bg-primary text-white">{option?.inventoryTypeId}</span>
									{option?.name}
								</svelte:fragment>
							</Autocomplete>
						</div>
						<div class="col-sm-12 col-md-6 col-xl-3">
							<Autocomplete
								label="Assembly Manufacturer"
								title="The company who manufactures the assembly that this item fits or came off of&#013(Generally the same as the 'Part Manufacturer' for items that are an assembly)"
								emptyValue={null}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								options={inventoryAssyManufacturers}
								getLabel={manufacturer => manufacturer?.name ?? ''}
								bind:value={part.parentManufacturer}
								on:change={async event => {
									const manufacturerId = event.detail?.id
									const typeSetId = inventoryTypeData?.typeSetId
									if (manufacturerId && typeSetId) {
										assyModelsLoading = true
										assyModels = await loadModelsForManufacturer(manufacturerId, typeSetId)
										assyModelsLoading = false
									} else {
										assyModels = []
									}
								}}
							></Autocomplete>
						</div>
						<div class="col-sm-12 col-md-6 col-xl-3">
							<Autocomplete
								id="assymodel"
								label="Assembly Model"
								title="The model name of the assembly that this item fits or came off of&#013(Generally the same as 'Part Model' for items that are an assembly)"
								isLoading={assyModelsLoading}
								emptyValue={null}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								options={assyModels}
								getLabel={model => (model ? `${model?.name}${model?.active ? '' : ' (inactive)'}` : '')}
								bind:value={part.parentModel}
							></Autocomplete>
						</div>
						<div class="col-sm-12 col-md-6 col-xl-3">
							<Autocomplete
								label="Part Manufacturer"
								title="The company who manufactures this item"
								emptyValue={null}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								options={inventoryTrueManufacturers}
								getLabel={manufacturer => manufacturer?.name ?? ''}
								bind:value={part.manufacturer}
								on:change={async event => {
									const manufacturerId = event.detail?.id
									const typeSetId = inventoryTypeData?.typeSetId
									if (manufacturerId && typeSetId) {
										trueModelsLoading = true
										partModels = await loadModelsForManufacturer(manufacturerId, typeSetId)
										trueModelsLoading = false
									} else {
										partModels = []
									}

									refetchQa()
									appraise()
								}}
							></Autocomplete>
						</div>
						<div class="col-sm-12 col-md-6 col-xl-3">
							<Autocomplete
								id="model"
								label="Part Model"
								title="The name that identifies this item to the manufacturer&#013For aftermarket/interchangeable items this can be the industry standard/OEM model name"
								isLoading={trueModelsLoading}
								emptyValue={null}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								options={partModels}
								getLabel={model => (model ? `${model?.name}${model?.active ? '' : ' (inactive)'}` : '')}
								bind:value={part.model}
								on:change={event => {
									const model = event.detail ? partModels.find(model => model.id === event.detail?.id) : null
									if (model && model.defaultShippingDimensions) {
										part.shippingDimensions.weightUnit = part.shippingDimensions.weightUnit || model.defaultShippingDimensions.weightUnit
										part.shippingDimensions.measurementUnit = part.shippingDimensions.measurementUnit || model.defaultShippingDimensions.measurementUnit
										part.shippingDimensions.weight = part.shippingDimensions.weight || model.defaultShippingDimensions.weight
										part.shippingDimensions.length = part.shippingDimensions.length || model.defaultShippingDimensions.length
										part.shippingDimensions.width = part.shippingDimensions.width || model.defaultShippingDimensions.width
										part.shippingDimensions.height = part.shippingDimensions.height || model.defaultShippingDimensions.height
									}
									checkModelCategoryPricing()
									refetchQa()
									appraise()
								}}
							></Autocomplete>
						</div>
						<div class="col-sm-12 col-md-6 col-xl-3">
							<Autocomplete
								label="Category"
								title="Inventory category can be used to group items by quality and type in advertisement exports"
								emptyValue={null}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								required={!!settingValues.inventory.categoryRequired ?? false}
								options={inventoryTypeCategories}
								getLabel={category => category?.name ?? ''}
								bind:value={part.category}
								on:change={() => {
									checkModelCategoryPricing()
									refetchQa()
									appraise()
								}}
							></Autocomplete>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
							<Input
								id="oemNum"
								label="OEM #"
								type="text"
								title="Optional: how the item is identified by the original manufacturer"
								required={!!settingValues.inventory.oemNumberRequired}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								bind:value={part.oemNumber}
							/>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
							<Select
								id="sideSelect"
								label="Side"
								title="For items that apply to one side of a vehicle or another, what side does this item fit?"
								class="form-control"
								required={!!settingValues.inventory.sideRequired}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								bind:value={part.side}
							>
								<option value="BOTH">Both</option>
								<option value="LEFT">Left</option>
								<option value="RIGHT">Right</option>
								<option value="NA">N/A</option>
							</Select>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group mb-1">
								<label
									class="col-form-label"
									for="interchangeNumber">Interchange #</label
								>
								<div class="input-group input-group-sm">
									<input
										id="interchangeNumber"
										title="An interchange number that can group items that share the same application/fitment&#013To explore/create interchange records, use the Interchange screen"
										class="form-control form-control-sm"
										disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
										bind:value={part.interchangeNumber}
									/>
									<div class="input-group-append w-25">
										<input
											id="subInterchangeNumber"
											class="form-control form-control-sm"
											style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;"
											title="Sub-interchange number can be used to sub-categorize items that are partially interchangeable&#013Generally used when items could be made to fit the same application, but with modification"
											disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
											bind:value={part.subInterchangeNumber}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 col-xl-2">
							<Input
								label="Quantity"
								class="text-right"
								type="number"
								inputmode="decimal"
								title="Total quantity on hand of the item at this store"
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_QUANTITY')}
								bind:value={part.quantity}
							/>
						</div>
						<div class="col-4 col-xl-2">
							<Input
								readonly
								label="On Hold"
								class="text-right"
								type="number"
								inputmode="decimal"
								title="The total quantity of the item on hold at this store (on any documents)"
								tabindex={-1}
								bind:value={part.quantityOnHold}
							/>
						</div>
						<div class="col-4 col-xl-2">
							<Input
								readonly
								label="Available"
								class="text-right"
								type="number"
								inputmode="decimal"
								title="Quantity available for sale at this store. This is the total quantity on hand minus any quantity held."
								tabindex={-1}
								bind:value={part.quantityAvailable}
							/>
						</div>

						<!-- 						<div class="col-sm-12 col-md-6 col-lg-3 col-xl-2">
							{#if !part.serials.length}
								<Input
									label="Serial #"
									title="Enter a Serial # to create a new serial record for this item. Once serialized, edit serial information under Stocking"
									required={inventoryTypeData?.requireSerialization}
									class={inventoryTypeData?.requireSerialization && !part.newSerial ? 'border-danger' : undefined}
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									bind:value={part.newSerial}
									on:blur={() => serializePart(!!part.newSerial, part.newSerial)}
								/>
							{/if}
						</div> -->
					</div>
				</div>
				<div class="col-12 col-lg-2">
					<TextArea
						placeholder="Internal notes won't appear on any customer-facing reports or online."
						label="Internal Notes"
						labelClass="sr-only"
						labelParentClass="h-100 d-flex flex-column"
						class="flex-grow-1"
						for="notes"
						title="Internal notes for this item&#013This information is not shown to the customer or in marketing"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.notes}
					/>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div
				class="card mt-3"
				class:d-none={!typeFields.length && (part.serialized || !part.inventoryOptions.length)}
			>
				<h5 class="card-header">Custom Fields</h5>
				<div class="card-body">
					<!-- "Top" QA List -->
					<QaList
						category={part.category}
						{optionValueMap}
						{inventoryTypeData}
						serials={part.serials}
						options={part.serialized ? [] : selectedSerialQuestions}
						{typeFields}
						disableInputs={part.status !== 'A' || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						on:qaInputChange={qaInputChange}
						on:qaInputChange
						on:typeFieldChange={event => {
							const { label, value } = event.detail
							if (part.typeField1?.label === label) {
								part.typeField1.data = value
							} else if (part.typeField2?.label === label) {
								part.typeField2.data = value
							} else if (part.typeField3?.label === label) {
								part.typeField3.data = value
							} else if (part.typeField4?.label === label) {
								part.typeField4.data = value
							}
						}}
						on:optionListChange={event => {
							// this new array has null for all the values, but we track the values in the map so that's fine
							part.inventoryOptions = event.detail
						}}
						on:typeFieldListChange={event => (typeFields = event.detail)}
						bind:this={qaList}
					/>
				</div>
			</div>
		</div>
	</div>
</CollapsibleCard>

<CollapsibleCard
	headerText="Marketing"
	cardHeaderClass="card-header d-flex justify-content-between h4"
	cardClass="mt-2"
	entireHeaderToggles
	bind:bodyShown={$cardsOpen.marketing}
>
	<div class="form-row">
		<div class="col-12 col-xl-6">
			<div class="form-row">
				<div class="col-12 col-sm-6">
					<Autocomplete
						id="categorySelect"
						label="Category"
						title="Inventory category can be used to group items by quality and type in advertisement exports"
						emptyValue={null}
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						required={!!settingValues.inventory.categoryRequired ?? false}
						options={inventoryTypeCategories}
						getLabel={category => category?.name ?? ''}
						bind:value={part.category}
						on:change={() => {
							checkModelCategoryPricing()
							refetchQa()
							appraise()
						}}
					></Autocomplete>
				</div>
				<div class="col-12 col-sm-6">
					<Autocomplete
						label="Condition"
						title="User specified level of item's quality and functionality"
						maxlength={15}
						placeholder={checkSessionPermission('PARTS_CAN_CREATE_CONDITIONS') ? 'Enter or Select a Condition' : 'Select a Condition'}
						required={!!settingValues.inventory.conditionRequired}
						canAddNew={checkSessionPermission('PARTS_CAN_CREATE_CONDITIONS')}
						options={inventoryConditions}
						getLabel={condition => condition ?? ''}
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.condition}
					/>
				</div>
				<div class="col-12 col-md-6">
					<Select
						label="Online Sale Class"
						showEmptyOption={!part.saleClass}
						title="For items available for online sale, the promised turnaround for orders"
						disabled={!part.public || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.saleClass}
					>
						{#if !part.public}
							<option value={noneSaleClass}>Not for Sale Online</option>
						{:else}
							{#each saleClasses as option}
								<option value={option}>{option.code} - {option.name}</option>
							{/each}
						{/if}
					</Select>
				</div>
				<div class="col-12 col-md-6">
					<TextArea
						label="Description"
						for="description"
						rows="4"
						title="Description of the item. (Shows on document printouts)"
						bind:value={part.description}
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
					/>
				</div>
			</div>
		</div>
		<div class="col-12 col-xl-6">
			<div class="card">
				<div class="card-header d-flex justify-content-between">
					<h5 class="mb-0">Pricing</h5>
					{#if !part.replenishable}
						<Button
							outline
							size="sm"
							iconClass={showAllPriceFields ? 'minus' : 'plus'}
							title="Some price fields are hidden by default on non-replenishable parts if they are set to 0. Click here to show all price fields."
							on:click={() => (showAllPriceFields = !showAllPriceFields)}
							>{#if showAllPriceFields}Less{:else}More{/if}...</Button
						>
					{/if}
				</div>
				<div class="card-body">
					<div class="form-row">
						{#if checkSessionPermission('PARTS_CAN_VIEW_COST')}
							{#if shouldShowPriceField(part.averageCost, part.replenishable, showAllPriceFields)}
								<div class="col-6 col-xl-3">
									<CurrencyInput
										readonly
										id="average-cost"
										label="Average Cost"
										name="averageCost"
										title="This represents any historically accounted expenses for this item, averaged across quantity on hand.&#013For items associated with a vehicle, this represents 'add-on costs' that have been earmarked for this item."
										value={part.averageCost}
									/>
								</div>
							{/if}
							{#if shouldShowPriceField(part.averageCoreCost, part.replenishable, showAllPriceFields)}
								<div class="col-6 col-xl-3">
									<CurrencyInput
										readonly
										id="average-core-cost"
										label="Average Core Cost"
										name="averageCoreCost"
										title="This represents any historically accounted core charges for this item, averaged across quantity on hand."
										value={part.averageCoreCost}
									/>
								</div>
							{/if}
							{#if settingValues.inventory.costVisible}
								<div class="col-6 col-xl-3">
									<CurrencyInput
										id="cost"
										label="Cost"
										name="cost"
										title="For vehicle items, this represents a portion of the vehicle cost to put on this part (should not exceed remaining vehicle cost)&#013For misc items, this represents an approximate value for the item (has no effect)&#013For replenishable items, this is the replacement cost for this item from the default purchase vendor. Note: expense on any particular order may vary."
										disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.costEditable}
										required={!!settingValues.inventory.costRequired}
										bind:value={part.cost}
									/>
								</div>
							{/if}
							{#if shouldShowPriceField(part.coreCost, part.replenishable, showAllPriceFields) && settingValues.inventory.coreCostVisible}
								<div class="col-6 col-xl-3">
									<CurrencyInput
										id="core-cost"
										label="Core Cost"
										name="coreCost"
										title="The replacement core cost for this item from the default purchase vendor (if any). Note: core expense on any particular order may vary."
										bind:value={part.coreCost}
										disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.coreCostEditable}
										required={!!settingValues.inventory.coreCostRequired}
									/>
								</div>
							{/if}
						{/if}
						{#if shouldShowPriceField(part.distributorPrice, part.replenishable, showAllPriceFields) && settingValues.inventory.distributorPriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="distributor-price"
									label="Distributor"
									name="distributorPrice"
									title="The price to charge any customer set up to pay 'distributor' pricing levels.&#013This generally represents the lowest markup price level on an inventory record."
									bind:value={part.distributorPrice}
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.distributorPriceEditable}
									required={!!settingValues.inventory.distributorPriceRequired}
								/>
							</div>
						{/if}
						{#if shouldShowPriceField(part.distributorCorePrice, part.replenishable, showAllPriceFields) && settingValues.inventory.distributorCorePriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="distributor-core-price"
									label="Distributor Core"
									name="distributorCorePrice"
									title="The core charge for any customer set up to pay 'distributor' pricing levels (if any)."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.distributorCorePriceEditable}
									required={!!settingValues.inventory.distributorPriceRequired}
									bind:value={part.distributorCorePrice}
								/>
							</div>
						{/if}
						{#if settingValues.inventory.jobberPriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="jobber-price"
									label="Jobber"
									name="jobberPrice"
									title="The price to charge any customer set up to pay 'jobber' pricing levels.&#013This generally represents a markup level between distributor and wholesale."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.jobberPriceEditable}
									required={!!settingValues.inventory.jobberPriceRequired}
									bind:value={part.jobberPrice}
								></CurrencyInput>
							</div>
						{/if}
						{#if shouldShowPriceField(part.jobberCorePrice, part.replenishable, showAllPriceFields) && settingValues.inventory.jobberCorePriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="jobber-core-price"
									label="Jobber Core"
									name="jobberCorePrice"
									title="The core charge for any customer set up to pay 'jobber' pricing levels (if any)."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.jobberCorePriceEditable}
									required={!!settingValues.inventory.jobberCorePriceRequired}
									bind:value={part.jobberCorePrice}
								/>
							</div>
						{/if}
						{#if settingValues.inventory.wholesalePriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="wholesale-price"
									label="Wholesale"
									name="wholesalePrice"
									title="The price to charge any customer set up to pay 'wholesale' pricing levels.&#013This generally represents a markup level between jobber and retail"
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.wholesalePriceEditable}
									required={!!settingValues.inventory.wholesalePriceRequired}
									bind:value={part.wholesalePrice}
								/>
							</div>
						{/if}
						{#if shouldShowPriceField(part.wholesaleCorePrice, part.replenishable, showAllPriceFields) && settingValues.inventory.wholesaleCorePriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="wholesale-core-price"
									label="Wholesale Core"
									name="wholesaleCorePrice"
									title="The core charge for any customer set up to pay 'wholesale' pricing levels (if any)."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.wholesaleCorePriceEditable}
									required={!!settingValues.inventory.wholesaleCorePriceRequired}
									bind:value={part.wholesaleCorePrice}
								/>
							</div>
						{/if}
						{#if settingValues.inventory.retailPriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="retail-price"
									label="Retail"
									name="retailPrice"
									title="The price to charge any customer set up to pay 'retail' pricing levels (the default)."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.retailPriceEditable}
									required={!!settingValues.inventory.retailPriceRequired}
									bind:value={part.retailPrice}
								/>
							</div>
						{/if}
						{#if settingValues.inventory.retailCorePriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="retail-core-price"
									label="Retail Core"
									name="retailCorePrice"
									title="The core charge for any customer set up to pay 'retail' pricing levels (if any)."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.retailCorePriceEditable}
									required={!!settingValues.inventory.retailCorePriceRequired}
									bind:value={part.retailCorePrice}
								></CurrencyInput>
							</div>
						{/if}
						{#if settingValues.inventory.listPriceVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="list-price"
									label="List"
									name="list"
									title="The chosen list price for this item. This may be used in advertising and/or may be the manufacturer or suppliers suggested price."
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_PRICES') || !checkSessionPermission('PARTS_CAN_EDIT_INFO') || !settingValues.inventory.listPriceEditable}
									required={!!settingValues.inventory.listPriceRequired}
									bind:value={part.listPrice}
								/>
							</div>
						{/if}
						<div class="col-6 col-xl-3">
							<Input
								readonly
								class="text-right"
								label="HTP Appraised"
								title={appraisalDetails}
								tabindex={-1}
								disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
								value={$appraisalQuery.fetching ? 'Loading...' : currencyFormat(appraisal?.value ?? '')}
							>
								<svelte:fragment slot="append">
									<Button
										isLoading={$appraisalQuery.fetching}
										iconClass="redo"
										disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
										on:click={() => appraise(true)}
									></Button>
								</svelte:fragment>
							</Input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</CollapsibleCard>

<CollapsibleCard
	headerText="Stocking"
	cardHeaderClass="card-header d-flex justify-content-between h4"
	cardClass="mt-2"
	entireHeaderToggles
	bind:bodyShown={$cardsOpen.stocking}
>
	<div class="form-row">
		<div class="col-12 col-md-4 col-xl-6">
			<div class="form-row">
				<div class="col-12 col-xl-4">
					<Select
						label="GL Category"
						labelClass="pt-0"
						title="The general ledger category of an item represents its accounting category. This controls which accounts&#013will be hit when this item is used on various documents."
						emptyValue={null}
						required={!!settingValues.inventory.glCategoryRequired}
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_GLCATEGORY') || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.glCategory}
					>
						{#each glCategories as gl}
							<option value={gl}>{gl.name}</option>
						{/each}
					</Select>
				</div>
				<div class="col-12 col-xl-4">
					<Select
						id="statusSelect"
						label="Status"
						labelClass="pt-0"
						title="Part status controls whether the item shows up in searches and reports:&#013A: item is on hand and available for sale&#013D: item is deleted, generally for mistake items&#013C: the item is removed from stock because it has been crushed/scrapped/destroyed&#013H: the item is currently placed on manual hold (different from quantity on hold from open documents)&#013S: stock has been depleted (sold) and will not be replenished&#013T: stock has been transferred to another store"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_STATUS') || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.status}
					>
						<option value="A">A - Available</option>
						<option value="H">H - On Hold</option>
						<option value="S">S - Sold</option>
						<option value="C">C - Crushed</option>
						<option value="D">D - Deleted</option>
						<option value="T">T - Transferred</option>
						<option value="B">B - Bidding</option>
					</Select>
				</div>
				<div class="col-12 col-xl-4">
					<Select
						id="userStatus"
						label="User Status"
						labelClass="pt-0"
						title="User statuses are controlled by the company and represent custom internal processes"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						required={!!settingValues.inventory.userStatusRequired}
						bind:value={part.userStatus}
					>
						{#each userPartStatusList as { status, description }}
							<option value={status}>{status} - {description}</option>
						{/each}
					</Select>
				</div>
			</div>
			<h5 class="card-title mt-3">Stock Configuration</h5>
			<div class="form-row">
				<div class="col-12 col-md-6">
					<Checkbox
						label="Deplete"
						title="If a part is not depletable, it means that the system should not track its stock levels or cost. &#013This is commonly used for items that represent fees, charges, or shop supplies that are (essentially) unlimited"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:checked={part.deplete}
					/>
				</div>
				<div class="col-12 col-md-6">
					<Checkbox
						label="Single Quantity"
						title="Single quantity parts don't allow quantity to go above 1 or multiple stocking locations &#013This is useful for simple parts and can simplify warehouse count operations"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:checked={part.singleQuantity}
					/>
				</div>
				<div class="col-12 col-md-6">
					<Checkbox
						label="Show Online"
						title="If checked, any configured marketing exports are allowed to view this item and advertise it"
						bind:checked={part.public}
						on:change={() => {
							if (!part.public) {
								part.saleClass = noneSaleClass
							}
						}}
					/>
				</div>
				<div class="col-12 col-md-6">
					<Checkbox
						label="Taxable"
						title="Whether this item will be marked as taxable on customer documents by default"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:checked={part.taxable}
					/>
				</div>
				<div class="col-12 col-md-6">
					<Checkbox
						label="Use Serial #s"
						title="Whether this item uses serial numbers, meaning every individual item on hand will be identified by a unique serial # with its own Q&A. Once enabled and saved, this cannot be disabled."
						disabled={origPart.serialized || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:checked={part.serialized}
						on:change={() => serializePart(part.serialized, '')}
					>
						<svelte:fragment slot="hint">
							{#if inventoryTypeData?.requireSerialization && !part.serialized}
								<small class="ml-1 text-danger">Required</small>
							{/if}
						</svelte:fragment>
					</Checkbox>
				</div>
				<div class="col-12 col-md-6">
					<Checkbox
						id="replenishable"
						label="Replenishable"
						title="Replenishable parts have several qualities: &#013 1. The item's status will stay 'A' when quantity on hand reaches 0 (instead of going to 'S'). &#013 2. The part allows editing of the replenishable information card, where additional fields can be filled out that relate to new/aftermarket parts."
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:checked={part.replenishable}
						on:change={replenishableChange}
					/>
				</div>
				<div class="col-12 col-md-6">
					<Checkbox
						label="Tag Printed"
						title="Whether a tag has been printed for this inventory record yet"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:checked={part.tagPrinted}
					/>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-8 col-xl-6">
			<LocationTable
				partQuantity={part.quantity}
				varianceLocationName={settingValues.inventory.varianceLocationName ?? 'Variance'}
				{partStoreLocations}
				{allowVirtualLocations}
				serialized={part.serialized}
				bind:locations={part.locations}
				bind:this={locationTable}
			></LocationTable>
		</div>
	</div>
	{#if part.serialized}
		<div class="form-row">
			<div class="col-12 col-xl-6 mb-3 mb-xl-0">
				<div class="card h-100">
					<div class="card-header">
						<h6 class="d-inline">Serials</h6>
					</div>
					<div class="card-body">
						<Table
							filterColumnClass="col-12 col-sm-6 col-lg-3 col-xl-6 align-self-end"
							rows={part.serials}
							columns={serialTableColumns}
							defaultSortColumn="number"
							filterEnabled
							responsive
						>
							<svelte:fragment
								slot="body"
								let:rows
							>
								{#if !part.singleQuantity}
									<tr
										class="cursor-pointer"
										class:table-primary={selectedSerialIndex === -1}
										on:click={() => (selectedSerialIndex = -1)}
									>
										<td>Default Serial Q&amp;A</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
									</tr>
								{/if}
								{#each rows as serial (serial.uuid)}
									{#if !serial.deleted && (serial.originalIndex || serial.originalIndex === 0)}
										<tr
											class="cursor-pointer"
											class:table-primary={selectedSerialIndex === serial.originalIndex}
											on:click={() => (selectedSerialIndex = serial.originalIndex)}
										>
											<td>
												<Input
													required
													id="serial-number-{serial.originalIndex}"
													placeholder="Enter a Serial Number"
													showLabel={false}
													labelParentClass="form-group m-0"
													disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO') || part.status !== 'A'}
													value={part.serials[serial.originalIndex].number}
													on:change={event => (part.serials[serial.originalIndex].number = getEventValue(event) ?? '')}
												/>
											</td>
											<td>
												<span
													class="badge w-100"
													class:badge-primary={serial.status === 'AVAILABLE'}
													class:badge-secondary={serial.status === 'ON_HOLD' || serial.status === 'IN_TRANSIT'}
													class:badge-danger={!['AVAILABLE', 'ON_HOLD', 'IN_TRANSIT'].includes(serial.status)}>{serial.displayStatus}</span
												>
											</td>
											<td>
												<Autocomplete
													required
													id="serial-location-{serial.originalIndex}"
													placeholder="Select a Location"
													showLabel={false}
													canAddNew={allowVirtualLocations}
													disabled={part.status !== 'A' || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
													labelParentClass="form-group m-0"
													options={partStoreLocations}
													getLabel={location => location?.name ?? ''}
													createOption={name => ({
														id: null,
														name,
														description: '',
													})}
													value={serial.location}
													on:change={event => serialLocationChange(event.detail, serial.originalIndex)}
												/>
											</td>
											<td style:white-space="nowrap">{serial.source}</td>
											<td style:white-space="nowrap">{serial.usedOn}</td>
										</tr>
									{/if}
								{/each}
							</svelte:fragment>
						</Table>
					</div>
					<div class="card-footer">
						<Button
							outline
							size="sm"
							color="success"
							iconClass="plus"
							disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO') || part.status !== 'A' || (part.singleQuantity && part.serials.length > 0)}
							on:click={() => addSerial()}>New Serial</Button
						>

						<Button
							outline
							size="sm"
							color="danger"
							disabled={part.status !== 'A' || selectedSerialIndex === -1 || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
							iconClass="trash"
							on:click={() => deleteSerial()}>Delete Serial</Button
						>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl-6">
				<div class="card h-100">
					<div class="card-header">
						<h6 class="d-inline mr-2">
							{(part.serials[selectedSerialIndex] || {}).number || 'Default'} Custom Fields
						</h6>
						<small class:d-none={selectedSerialIndex !== -1}>(Will affect newly created serials)</small>
					</div>
					<div class="card-body">
						<QaList
							{selectedSerialId}
							{selectedSerialUuid}
							category={part.category}
							{optionValueMap}
							{inventoryTypeData}
							serials={part.serials}
							typeFields={[]}
							options={selectedSerialQuestions}
							disableInputs={disableSerialQaInputs || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
							on:qaInputChange={qaInputChange}
							on:qaInputChange
							on:optionListChange={event => {
								// this new array has null for all the values, but we track the values in the map so that's fine
								part.inventoryOptions = event.detail
							}}
							bind:this={serialQaList}
						/>
					</div>
				</div>
			</div>
		</div>
	{/if}
	{#if part.replenishable}
		<CollapsibleCard
			headerText="Aftermarket & Replenishable Info"
			cardHeaderClass="card-header d-flex justify-content-between h5"
			cardClass="mt-2"
			entireHeaderToggles
			bind:bodyShown={$cardsOpen.replenishable}
		>
			<div class="card-group">
				<div class="card">
					<h6 class="card-header">Vendor Info</h6>
					<div class="card-body">
						<div class="form-row align-items-end">
							<div class="col-12">
								<div class="form-group mb-1">
									<Autocomplete
										label="Default Vendor"
										emptyValue={null}
										title="The default vendor this item is purchased from. This choice affects how the item is ordered on the inventory overview screen and historical ordering information."
										options={vendorList}
										getLabel={vendor => {
											const vendorName = vendor?.contactName ?? vendor?.companyName ?? ''
											return vendor?.code ? `${vendor.code}${vendorName ? ` - ${vendorName}` : ''}` : vendorName
										}}
										value={part.defaultVendor}
										on:change={onDefaultVendorChange}
									></Autocomplete>
								</div>
							</div>
							<div class="col-12">
								<Input
									label="Part Number"
									title="The part number of the item. This is what the item is referred to as by the default purchase vendor"
									bind:value={part.partNumber}
								/>
							</div>
							<div class="col-12">
								<Input
									label="Vendor Product Code"
									title="A vendor product code"
									bind:value={part.vendorProductCode}
								/>
							</div>
							<div class="col-12">
								<Input
									label="Vendor Popularity Code"
									title="The popularity code of this item from the default purchase vendor"
									bind:value={part.vendorPopularityCode}
								/>
							</div>
							<div class="col-12">
								<Select
									label="Core Class"
									title="Items that share a core class are interchangeable when returned to a vendor for a core exchange."
									isLoading={coreClassHistoryLoading}
									bind:value={part.coreClass}
								>
									{#each part.defaultVendor?.coreClassHistory ?? [] as coreClass}
										<option value={coreClass}>{coreClass}</option>
									{/each}
								</Select>
							</div>
							<div class="col-12">
								<Select
									label="Sell Price Class"
									title="The sell price class of an item allows categorizing similar items for easy discount pricing to certain customers.&#013If an item is assigned to a class that is more specific than a customer's discount level, it will be applied.&#013For example: an item assigned to class 'Engines - Used' will trigger a customer's discount on the class 'Engines'"
									emptyValue={null}
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									options={sellPriceClasses}
									bind:value={part.sellPriceClassId}
									let:option
								>
									{#if option}
										<option value={option.id}>
											{#if option.parent}{option.parent.name} -
											{/if}{option.name}
										</option>
									{/if}
								</Select>
							</div>
						</div>
					</div>
				</div>
				<div class="card flex-md-grow-2 flex-xl-grow-3">
					<h6 class="card-header">Ordering Info</h6>
					<div class="card-body">
						<div class="form-row align-items-end">
							<div class="col-12 col-md-3">
								<Select
									label="Stock Type"
									title="This controls how and if this item is stocked regularly"
									bind:value={part.stockType}
								>
									<option value="SPECIAL_ORDER">Special Order</option>
									<option value="STOCKED">Stocked</option>
									<option value="OBSOLETE">Obsolete</option>
								</Select>
							</div>
							<div class="col-12 col-md-3">
								<Select
									label="Stock Method"
									title="If this item is regularly stocked, what method is used to calculate order quantity"
									bind:value={part.stockMethod}
								>
									<option value="NONE">None</option>
									<option value="MIN_MAX">Min/Max</option>
									<option value="HISTORY">History</option>
								</Select>
							</div>
							<div class="col-12 col-md-3">
								<Input
									label="Stocking Days"
									title="On average, how many days it will take to restock the item from the default purchase vendor"
									bind:value={part.stockingDays}
									type="number"
								/>
							</div>
							<div class="col-12 col-md-3">
								<Input
									label="Safety Stock"
									title="An additional quantity to keep in stock, above and beyond the quantity to cover the demand during the lead time"
									type="number"
									inputmode="decimal"
									bind:value={part.safetyStockPercent}
								>
									<span
										slot="append"
										class="input-group-text">%</span
									>
								</Input>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-row">
									<div class="col-12">
										<Checkbox
											label="Use Vendor Order Multiplier"
											title="When checked, show each 'pack' of the buy package quantity as 1 order quantity on vendor-facing documents"
											disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
											bind:checked={part.useVendorOrderMultiplier}
										/>
									</div>
									<div class="col-12 col-md-6">
										<Input
											label="Buy Package"
											type="number"
											inputmode="decimal"
											title="The quantity that this item is normally bought in groups of (defaults to 1)"
											bind:value={part.buyPackage}
										/>
									</div>
									<div class="col-12 col-md-6">
										<Input
											label="Sell Package"
											type="number"
											inputmode="decimal"
											title="The quantity that this item is normally sould in groups of (defaults to 1)"
											bind:value={part.sellPackage}
										/>
									</div>
								</div>
							</div>
							<div class="col-6 col-md-3">
								<Input
									label="Minimum Quantity"
									type="number"
									inputmode="decimal"
									title="The quantity at which to perform a stock order."
									bind:value={part.minQuantity}
								/>
							</div>
							<div class="col-6 col-md-3">
								<Input
									label="Maximum Quantity"
									title="The quantity to order back up to when performing stock ordering.This is also known as the stocking point."
									type="number"
									inputmode="decimal"
									bind:value={part.maxQuantity}
								/>
							</div>
							<div class="col-12 col-md-3">
								<Input
									label="Popularity Code"
									title="The popularity code of this item at this store. Popularity code can be used to categorize how quickly this item moves and its stock priority"
									bind:value={part.popularityCode}
								/>
							</div>
							<div class="col-12 col-md-6">
								<Checkbox
									label="Seasonal"
									title="Check this if the item follows a distinctly seasonal demand pattern"
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									bind:checked={part.seasonal}
								/>
							</div>
						</div>
					</div>
					<div class="card-header border-top d-flex justify-content-between flex-wrap">
						<h6 class="">Calculated Values</h6>
						<Button
							class="btn-sm btn-outline-primary"
							disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
							icon={{
								class: 'fa-flip-horizontal',
								icon: 'arrow-turn-up',
							}}
							on:click={useSuggestedQuantities}>Use Suggested Quanities</Button
						>
					</div>
					<div class="card-body">
						<div class="form-row align-items-end">
							<div class="col-6 col-md-3">
								<Input
									label="Suggested Minimum Quantity"
									type="number"
									inputmode="decimal"
									readonly
									title="A suggested minimum quantity value calculated based on historical demand."
									bind:value={part.suggestedMinQuantity}
								/>
							</div>
							<div class="col-6 col-md-3">
								<Input
									label="Suggested Maximum Quantity"
									type="number"
									inputmode="decimal"
									readonly
									title="A suggested maximum quantity value calculated based on historical demand."
									bind:value={part.suggestedMaxQuantity}
								/>
							</div>
							<div class="col-6 col-md-3">
								<Input
									label="Avg Demand Per Day"
									readonly
									title="Historical average daily demand over the last year. This is used to calculate suggested stocking levels."
									bind:value={part.averageDemandPerDay}
								/>
							</div>
							<div class="col-6 col-md-3">
								<Input
									label="Avg Demand Per Month"
									readonly
									title="Historical average monthly demand over the last year. This is used to calculate suggested stocking levels."
									bind:value={part.averageDemandPerMonth}
								/>
							</div>
							<div class="col-12 col-md-4">
								<Input
									label="Lead Time (days)"
									readonly
									title="This is the average historical lead time for this item from the default purchase vendor.  Lead time represents&#013how many days it generally takes to fulfill orders of this item after it has been ordered.&#013Note: only purchase orders started in last 90 days are considered"
									bind:value={part.vendorLeadTime}
								/>
							</div>
							<div class="col-12 col-md-4">
								<Input
									readonly
									label="Suggested Safety Stock"
									type="number"
									inputmode="decimal"
									title="A suggested safety stock value calculated based on historical demand."
									bind:value={part.suggestedSafetyStockPercent}
								>
									<span
										slot="append"
										class="input-group-text">%</span
									>
								</Input>
							</div>
							<div class="col-12 col-md-4">
								<Input
									label="Freeze Calculations Until"
									title="If checked, this represents a timer for when the item can first start to calculate historical demand patterns.&#013Before this date, the system will not attempt to automatically calculate any stocking statistics."
									type="date"
									value={part.freezeUntil ? formatISO(part.freezeUntil, { representation: 'date' }) : null}
									on:change={event => (part.freezeUntil = new Date(getEventValue(event)))}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</CollapsibleCard>
	{/if}
	<CollapsibleCard
		headerText="Vehicle"
		cardHeaderClass="card-header d-flex justify-content-between flex-wrap h5"
		cardClass="mt-2"
		entireHeaderToggles
		bind:bodyShown={$cardsOpen.vehicle}
	>
		<div slot="headerRight">
			<Button
				outline
				size="sm"
				class="mr-1"
				iconClass="search"
				title="Search for a vehicle record to associate with this part."
				disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
				on:click={event => {
					event.stopPropagation()
					vehicleLookupModal?.show()
				}}
			>
				Find <span class="d-none d-sm-inline">Vehicle</span>
			</Button>
			<Button
				outline
				size="sm"
				class="mr-4"
				iconClass="truck"
				title="Open the vehicle record associated with this inventory record."
				disabled={!part.vehicleId}
				href={part.vehicleId ? asr.makePath('app.vehicle', { vehicleId: part.vehicleId, storeId: part.storeId }, {}) : null}
				on:click={event => event.stopPropagation()}
				>Open <span class="d-none d-sm-inline">Vehicle</span>
			</Button>
		</div>

		{#if part.vehicle && part.vehicleId}
			<div class="form-row">
				{#if part.vehicle.topImage}
					<div class="col-md-auto mb-3">
						<a
							target="_blank"
							href={part.vehicle.topImage.url}
						>
							<img
								class="img-fluid rounded"
								src="{part.vehicle.topImage.url}?height=150&width=150&fit=contain"
								title={part.vehicle.topImage.name}
								alt={part.vehicle.topImage.name}
							/>
						</a>
					</div>
				{/if}
				<div class="col-md">
					<Input
						readonly
						id="stockNumber"
						label="Stock Number"
						title="Vehicle record associated with this inventory record (if any). If a vehicle is associated with a part record, cost, revenue, and profit will be tracked back to that vehicle unit."
						value={part.vehicle.stockNumber}
					/>
				</div>
				<div class="col-md-6 col-xl-4">
					<Input
						readonly
						label="Year"
						value={part.vehicle.year}
					/>
				</div>
				<div class="col-md-6 col-xl-4">
					<Input
						readonly
						label="Make"
						title="Vehicle make that this item fits or came off of"
						value={part.vehicle.manufacturer?.name ?? ''}
					/>
				</div>
				<div class="col-md-6 col-xl-4">
					<Input
						readonly
						label="Model"
						title="Vehicle model that this item fits or came off of"
						value={part.vehicle.model?.name ?? ''}
					/>
				</div>
				<div class="col-md">
					<Input
						readonly
						label="VIN"
						title="Vehicle Identification Number"
						value={part.vehicle.vin}
					/>
				</div>
				<div class="col-md">
					<Input
						readonly
						label="Body"
						title="Body style of the vehicle this item came off of"
						value={part.vehicle.bodyStyle}
					/>
				</div>
			</div>
		{:else}
			<div class="form-row">
				<div class="col-md-4 col-xl-2">
					<Input
						id="vehicleYear"
						label="Year"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.vehicleYear}
						on:change={() => appraise()}
					/>
				</div>
				<div class="col-md-4 col-xl-3">
					<Input
						label="Make"
						list="vehicleMakes"
						style="text-transform:uppercase"
						title="Vehicle make that this item fits or came off of"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.vehicleMake}
						on:change={async () => {
							if (part.vehicleMake) {
								vehicleModels = await loadVehicleModelsForMake(part.vehicleMake)
							} else {
								vehicleModels = []
							}
							appraise()
						}}
					/>
					<datalist id="vehicleMakes">
						{#each vehicleMakes as make}
							<option value={make}>{make}</option>
						{/each}
					</datalist>
				</div>
				<div class="col-md-4 col-xl-3">
					<Input
						label="Model"
						list="vehicleModels"
						style="text-transform:uppercase"
						title="Vehicle model that this item fits or came off of"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.vehicleModel}
						on:change={() => appraise()}
					/>
					<datalist id="vehicleModels">
						{#each vehicleModels as model}
							<option value={model.toUpperCase()}>{model}</option>
						{/each}
					</datalist>
				</div>
				<div class="col-md-4 col-xl-2">
					<Input
						id="vehicleVin"
						label="VIN"
						title="Vehicle Identification Number"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.vehicleVin}
					/>
				</div>
				<div class="col-md-4 col-xl-2">
					<Input
						id="bodyStyle"
						label="Body Style"
						title="Body style of the vehicle this item came off of"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.bodyStyle}
					/>
				</div>
			</div>
		{/if}
	</CollapsibleCard>
</CollapsibleCard>

<div class="form-row p-0">
	<div
		class="col-12 col-xl-6"
		class:d-flex={$cardsOpen.fulfillment}
	>
		<CollapsibleCard
			headerText="Fulfillment"
			cardHeaderClass="card-header d-flex justify-content-between h4"
			cardClass="mt-2 {$cardsOpen.fulfillment ? 'flex-grow-1' : ''}"
			entireHeaderToggles
			bind:bodyShown={$cardsOpen.fulfillment}
		>
			<div class="table-responsive">
				<table class="table table-sm table-bordered mb-0">
					<thead>
						<tr>
							<th style="width: 80%;"></th>
							<th
								style="min-width: 45px;"
								class="text-right text-nowrap">Days to Return</th
							>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="align-bottom">
								<Checkbox
									label="Returnable"
									title="If checked, this item can be returned by customers"
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									bind:checked={part.returnable}
								/>
							</td>
							<td>
								<Input
									class="text-right"
									labelParentClass="mb-0"
									showLabel={false}
									title="The maximum number of days until a return will no longer be accepted from a customer"
									disabled={!part.returnable || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									value={part.returnable ? part.daysToReturn : null}
									on:change={event => numberChange(event, 'daysToReturn')}
								/>
							</td>
						</tr>
						<tr>
							<td class="align-bottom">
								<Checkbox
									label="Core Required"
									title="If checked, when this item is sold to a customer, a core exchange will be expected&#013This means an inherent core charge will be added to documents and it allow a dirty core exchange to refund that core charge"
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									bind:checked={part.coreRequired}
								/>
							</td>
							<td>
								<Input
									class="text-right"
									labelParentClass="mb-0"
									showLabel={false}
									title="The maximum number of days until a core exchange will no longer be accepted from a customer"
									disabled={!part.coreRequired || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									value={part.coreRequired ? part.daysToReturnCore : null}
									on:change={event => numberChange(event, 'daysToReturnCore')}
								/>
							</td>
						</tr>
						<tr>
							<td class="align-bottom">
								<Checkbox
									label="Returnable to Vendor"
									title="If checked, this item can be returned to the vendor on a return purchase order"
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									bind:checked={part.returnableToVendor}
								/>
							</td>
							<td>
								<Input
									class="text-right"
									labelParentClass="mb-0"
									showLabel={false}
									title="The maximum number of days until a return will no longer be accepted by the vendor"
									disabled={!part.returnableToVendor || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									value={part.returnableToVendor ? part.daysToReturnToVendor : null}
									on:change={event => numberChange(event, 'daysToReturnToVendor')}
								/>
							</td>
						</tr>
						<tr>
							<td class="align-bottom">
								<Checkbox
									label="Vendor Core Required"
									title="If checked, when this item is purchased from a vendor, a core exchange will be expected&#013This means an inherent core charge will be added to POs in the the amount of the core cost, and a core must be exchanged to refund that core charge"
									disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									bind:checked={part.coreRequiredToVendor}
								/>
							</td>
							<td>
								<Input
									class="text-right"
									labelParentClass="mb-0"
									showLabel={false}
									title="The maximum number of days until a core exchange will no longer be accepted by the vendor"
									disabled={!part.coreRequiredToVendor || !checkSessionPermission('PARTS_CAN_EDIT_INFO')}
									value={part.coreRequiredToVendor ? part.daysToReturnCoreToVendor : null}
									on:change={event => numberChange(event, 'daysToReturnCoreToVendor')}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</CollapsibleCard>
	</div>
	<div class="col-12 col-xl-6">
		<CollapsibleCard
			headerText="Sales and Shipping"
			cardHeaderClass="card-header d-flex justify-content-between h4"
			cardClass="mt-2"
			entireHeaderToggles
			bind:bodyShown={$cardsOpen.salesAndShipping}
		>
			<div class="form-row">
				<div class="col-md-4 col-xl-6">
					<Input
						id="shipping-weight"
						inputmode="decimal"
						type="number"
						class="text-right"
						title="The shipping weight of the item"
						label="Weight ({part.shippingDimensions.weightUnit?.toLowerCase()})"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.shippingDimensions.weight}
					/>
				</div>
				<div class="col-md-4 col-xl-6">
					<Input
						id="shipping-length"
						inputmode="decimal"
						type="number"
						class="text-right"
						title="The length of the item for shipping calculations."
						label="Length({part.shippingDimensions.measurementUnit?.toLowerCase()})"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.shippingDimensions.length}
					/>
				</div>
				<div class="col-md-4 col-xl-6">
					<Input
						id="shipping-width"
						inputmode="decimal"
						type="number"
						class="text-right"
						title="The width of the item for shipping calculations."
						label="Width ({part.shippingDimensions.measurementUnit?.toLowerCase()})"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.shippingDimensions.width}
					/>
				</div>
				<div class="col-md-4 col-xl-6">
					<Input
						id="shipping-height"
						inputmode="decimal"
						type="number"
						class="text-right"
						title="The height of the item for shipping calculations."
						label="Height ({part.shippingDimensions.measurementUnit?.toLowerCase()})"
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.shippingDimensions.height}
					/>
				</div>
				<div class="col-md-4 col-xl-6">
					<Select
						id="weightUnit"
						label="Weight Unit"
						showEmptyOption={false}
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.shippingDimensions.weightUnit}
					>
						<option value="LB">Pounds</option>
						<option value="KG">Kilograms</option>
					</Select>
				</div>
				<div class="col-md-4 col-xl-6">
					<Select
						id="measurementUnit"
						label="Measurement Unit"
						showEmptyOption={false}
						disabled={!checkSessionPermission('PARTS_CAN_EDIT_INFO')}
						bind:value={part.shippingDimensions.measurementUnit}
					>
						<option value="IN">Inches</option>
						<option value="CM">Centimeters</option>
					</Select>
				</div>
			</div>
		</CollapsibleCard>
	</div>
</div>

<LoadItemModal
	itemTitle="Vehicles by Make, Model, Stock #, and VIN"
	itemIdProp="id"
	itemDisplayProp="stockNumber"
	doSearch={doVehicleSearch}
	on:lookupError={({ detail: { error } }) =>
		mediator.publish('showMessage', {
			heading: 'Error Looking Up Vehicles',
			message: error.message,
			color: 'danger',
			time: false,
		})}
	on:chooseItem={({ detail: { item } }) => {
		part.vehicleId = item.id
		part.vehicle = item
		// You can associate a vehicle to a REPLENISHABLE part, so don't override the stockCategory in that case
		part.stockCategory = part.replenishable ? 'REPLENISHABLE' : 'STANDARD'
		appraise()
	}}
	bind:this={vehicleLookupModal}
	let:item
>
	<div class="w-100 d-flex justify-content-between">
		<h5 class="mb-1">{item.stockNumber}</h5>
		<h5
			class="mb-1"
			title={item.store.name}
		>
			Store {item.store.code}
		</h5>
	</div>
	<div class="w-100">{item.year} {item.manufacturer?.name} {item.model?.name}</div>
	{#if item.vin}
		<small class="mt-1">VIN {item.vin}</small>
	{/if}
</LoadItemModal>
