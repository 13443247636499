export default {
    "name": "UserStatuses",
    "kind": "HoudiniQuery",
    "hash": "e35a12889946c295cec3e3887024b8cb16df05a7b8b860ab9740b9d1f43f0954",

    "raw": `query UserStatuses($type: UserStatusTypeEnum) {
  userStatuses(type: $type) {
    status
    description
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "userStatuses": {
                "type": "UserStatus",
                "keyRaw": "userStatuses(type: $type)",

                "selection": {
                    "fields": {
                        "status": {
                            "type": "String",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "type": "UserStatusTypeEnum"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=925dc3f2e58e903da4b1843db44acfbe3cf45a7918d1b35bedcca04299b446d1";