<script lang="ts">
	import type {
		Store,
		Module,
		SearchField,
		SearchModule,
		StaticSearchField,
		StaticSearchFieldKey,
		AdditionalField as AdditionalFieldType,
		InventoryStaticSearchFieldValue,
		InventoryQuestionValue,
		AdditionalFieldValue,
	} from 'utility/search-fields'
	import type { SvelteAsr } from 'types/common'

	import Icon from '@isoftdata/svelte-icon'
	import AdditionalField from './AdditionalField.svelte'
	import StoreSelect from 'components/StoreSelect.svelte'
	import SideAutocomplete from './SideAutocomplete.svelte'
	import SmartSearchInput from './SmartSearchInput.svelte'
	import StatusSelect from 'components/StatusSelect.svelte'
	import TextField from 'components/InputSearchField.svelte'
	import ModelAutocomplete from 'components/ModelAutocomplete.svelte'
	import YearRangeInput from 'components/YearRangeInput.svelte'
	import LocationAutocomplete from 'components/LocationAutocomplete.svelte'
	import ConditionAutocomplete from './ConditionAutocomplete.svelte'
	import ManufacturerAutocomplete from 'components/ManufacturerAutocomplete.svelte'
	import AddEditSearchFilters from 'components/AddEditSearchFilters.svelte'
	import CategoryAutocomplete from 'components/CategoryAutocomplete.svelte'
	import InventoryQuestionField from 'components/InventoryQuestionField.svelte'
	import UserStatusAutocomplete from 'components/UserStatusAutocomplete.svelte'
	import InventoryTypeAutocomplete from 'components/InventoryTypeAutocomplete.svelte'
	import {
		loadInventoryQuestions,
		getStaticSearchFieldsWithDependencyGraph,
		getInitialSelectedSearchFields,
		getSearchFieldsByCategory,
		getValidTypeFields,
		saveUserSetting,
		computeSearchParameterValues,
	} from 'utility/search-fields'

	// #region props
	export let searchModules: SearchModule[] = []
	export let authorizedStores: Store[] = []
	export let stores: number[] = []
	export let searchableInventoryFields: AdditionalFieldType[] = []
	export let asr: SvelteAsr
	export let userPinnedSearchFields: string[] = []
	// #endregion

	// #region local vars
	/** List of all static search fields, with their dependencies. (All possible search fields) */
	const staticSearchFields = getStaticSearchFieldsWithDependencyGraph()
	const staticSearchFieldsMap: Map<StaticSearchFieldKey, StaticSearchField> = new Map(staticSearchFields.map(field => [field.key, field]))
	/** List of all search fields. Set via reactive statement as it can change based on the selection of other search fields */
	let searchFields: SearchField[] = [] //This is the list of all search fields. Set via reactive statement as it can change based on the selection of other search fields.
	/** List of selected search fields */
	let selectedSearchFieldsKeys = getInitialSelectedSearchFields(staticSearchFields, userPinnedSearchFields) //This is the list of selected search fields
	/** List of search field values that will be passed to Result state */
	let searchParameterValues: Record<string, unknown> = {} //This is the list of search field values that will be passed to Result state

	// #region maps of search fields with values
	let staticSearchFieldValues: InventoryStaticSearchFieldValue = {
		STORE: stores, //default to their current store, passed from part-search.ts
		INVENTORY_TYPE: null,
		ASSEMBLY_MANUFACTURER: null,
		ASSEMBLY_MODEL: null,
		PART_MANUFACTURER: null,
		PART_MODEL: null,
		LOCATION: null,
		CONDITION: null,
		STATUS: '@',
		USER_STATUS: null,
		CATEGORY: null,
		SIDE: null,
		YEAR_RANGE: { from: null, to: null },
		VEHICLE_MANUFACTURER: null,
		VEHICLE_MODEL: null,
		SMART_SEARCH: {
			keyword: '',
			moduleIds: searchModules.reduce((acc: Array<Module['id']>, searchModule) => {
				return searchModule.default ? [...acc, searchModule.module.id] : acc
			}, []), //This is the user's default search modules
		},
	}
	let additionalFieldValues: Record<string, AdditionalFieldValue> = {}
	let typeFieldValues: Record<string, string> = {} //Type fields can only be strings()
	let inventoryQuestionValues: Record<string, InventoryQuestionValue> = {}
	// #endregion

	let inEditMode: boolean = false
	let pinnedSearchFields: Set<string> = new Set(userPinnedSearchFields)
	let inventoryQuestions: SearchField[] = []
	let typeFields: SearchField[] = []
	let additionalSearchFields: SearchField[] = []

	async function loadAndSetInventoryQuestions({
		inventoryTypeId,
		manufacturerId,
		modelId,
		category,
	}: {
		inventoryTypeId?: number
		manufacturerId?: number
		modelId?: number
		category?: string | null
	}) {
		const loadedInventoryQuestions = await loadInventoryQuestions({ inventoryTypeId, manufacturerId, modelId, category: category })
		let inventoryQuestionNames = new Set()
		inventoryQuestions = loadedInventoryQuestions.reduce((acc: SearchField[], question) => {
			//Maybe the API should be ensuring that we don't get multiple questions with the same name, but as of writing this comment(2024-04-10), it doesn't.
			if (inventoryQuestionNames.has(question.name)) {
				return acc
			} else {
				inventoryQuestionNames.add(question.name)
				return [
					...acc,
					{
						key: `QA_${question.name}`, //we use name as the key because id is specific to a type/man/model/category combo, but we want to be able to use the same question across different combinations
						name: question.name,
						dependencies: [],
						qaFieldData: question,
					},
				]
			}
		}, [])
	}

	function getAdditionalFields(inventorySearchFields: AdditionalFieldType[], staticSearchFields: StaticSearchField[]): SearchField[] {
		//We don't want duplicates of our nice static search fields, so we'll filter out any of the dynamic "additional" fields that have the same dbFieldKey as any of the static fields.
		const searchFieldsToExclude = staticSearchFields.reduce((acc: string[], field) => {
			if (field.dbFieldKey?.length) {
				acc = [...acc, ...field.dbFieldKey]
				return acc
			} else return acc
		}, [])

		return inventorySearchFields.reduce((acc: SearchField[], additionalField) => {
			//Nested looping, amirite? But since we actually need to look in the contents of the string, we can't use a Set or even an .includes(), unfortunately.
			if (!!searchFieldsToExclude.find(searchFieldToExclude => additionalField.fieldName.indexOf(searchFieldToExclude) > -1)) {
				return acc
			}

			return [
				...acc,
				{
					key: `ADDITIONAL_${additionalField.fieldName}`, //Because these fields are dynamic just come from a table in the db, We've decided to key off the field name, rather than the auto-increment id.
					name: `${additionalField.displayName}`,
					dependencies: [],
					additionalFieldData: additionalField,
				},
			]
		}, [])
	}

	$: loadAndSetInventoryQuestions({
		inventoryTypeId: staticSearchFieldValues.INVENTORY_TYPE?.id,
		manufacturerId: staticSearchFieldValues.PART_MANUFACTURER?.id,
		modelId: staticSearchFieldValues.PART_MODEL?.id,
		category: staticSearchFieldValues.CATEGORY?.name,
	})
	$: typeFields = getValidTypeFields(staticSearchFieldValues.INVENTORY_TYPE)
	$: searchFields = [...staticSearchFields, ...typeFields, ...inventoryQuestions, ...additionalSearchFields]
	$: additionalSearchFields = getAdditionalFields(searchableInventoryFields, staticSearchFields)
	$: searchFieldsByCategory = getSearchFieldsByCategory(staticSearchFields, typeFields, inventoryQuestions, additionalSearchFields)

	async function toggleEditMode(forceExit = false) {
		inEditMode = forceExit ? false : !inEditMode

		if (!inEditMode) {
			const searchFieldsSet = new Set(searchFields.map(field => field.key))
			await saveUserSetting.mutate({
				input: {
					searchParts: {
						//Just in case, filter out any invalid keys
						pinnedSearchFields: Array.from(pinnedSearchFields).filter(field => searchFieldsSet.has(field)),
					},
				},
			})
		}
	}

	$: searchParameterValues = computeSearchParameterValues(selectedSearchFieldsKeys, staticSearchFieldValues, additionalFieldValues, typeFieldValues, inventoryQuestionValues, staticSearchFieldsMap)
</script>

<div class="card">
	<div class="card-header">
		<div class="d-flex justify-content-between align-items-end">
			<div class="h4 mb-0">
				<div>
					<Icon
						icon="engine"
						class="mr-2"
					/><span class="d-none d-sm-inline-block">Part Search</span>
				</div>
			</div>
			<div>
				<a
					id="newPart"
					href={asr.makePath('app.part')}
					class="btn btn-sm btn-success"
				>
					<i
						class="fas fa-plus"
						aria-hidden="true"
					></i> New Part
				</a>
			</div>
		</div>
	</div>
	<div class="card-body">
		<form
			id="search-field-form"
			on:submit|preventDefault={() => {
				toggleEditMode(true)
				asr.go('app.part-search.results', searchParameterValues)
			}}
		>
			{#if selectedSearchFieldsKeys.size}
				<div class="form-row row-cols-2 align-items-end">
					{#each selectedSearchFieldsKeys as searchFieldKey (searchFieldKey)}
						{@const searchField = searchFields.find(field => field.key === searchFieldKey)}
						{#if searchField}
							{#if searchField?.key === 'STORE'}
								<StoreSelect
									id={searchField?.key}
									{searchField}
									stores={authorizedStores}
									bind:selectedStores={staticSearchFieldValues.STORE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'INVENTORY_TYPE'}
								<InventoryTypeAutocomplete
									id={searchField?.key}
									{searchField}
									bind:inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									vehicle={false}
									{inEditMode}
								/>
							{:else if searchField?.key === 'ASSEMBLY_MANUFACTURER'}
								<ManufacturerAutocomplete
									id={searchField?.key}
									{searchField}
									type="ASSEMBLY"
									bind:manufacturer={staticSearchFieldValues.ASSEMBLY_MANUFACTURER}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									{inEditMode}
								/>
							{:else if searchField?.key === 'ASSEMBLY_MODEL'}
								<ModelAutocomplete
									id={searchField?.key}
									{searchField}
									type="ASSEMBLY"
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									manufacturer={staticSearchFieldValues.ASSEMBLY_MANUFACTURER}
									bind:model={staticSearchFieldValues.ASSEMBLY_MODEL}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'PART_MANUFACTURER'}
								<ManufacturerAutocomplete
									id={searchField?.key}
									{searchField}
									type="PART"
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									bind:manufacturer={staticSearchFieldValues.PART_MANUFACTURER}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'PART_MODEL'}
								<ModelAutocomplete
									id={searchField?.key}
									{searchField}
									type="PART"
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									manufacturer={staticSearchFieldValues.PART_MANUFACTURER}
									bind:model={staticSearchFieldValues.PART_MODEL}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'LOCATION'}
								<LocationAutocomplete
									id={searchField?.key}
									{searchField}
									bind:inventoryLocation={staticSearchFieldValues.LOCATION}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									stores={staticSearchFieldValues.STORE}
									{inEditMode}
								/>
							{:else if searchField?.key === 'CONDITION'}
								<ConditionAutocomplete
									id={searchField?.key}
									{searchField}
									bind:condition={staticSearchFieldValues.CONDITION}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'STATUS'}
								<StatusSelect
									id={searchField?.key}
									{searchField}
									bind:status={staticSearchFieldValues.STATUS}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'USER_STATUS'}
								<UserStatusAutocomplete
									id={searchField?.key}
									{searchField}
									bind:userStatus={staticSearchFieldValues.USER_STATUS}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'CATEGORY'}
								<CategoryAutocomplete
									id={searchField?.key}
									{searchField}
									bind:category={staticSearchFieldValues.CATEGORY}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									inventoryType={staticSearchFieldValues.INVENTORY_TYPE}
									{inEditMode}
								/>
							{:else if searchField?.key === 'SIDE'}
								<SideAutocomplete
									id={searchField?.key}
									{searchField}
									bind:side={staticSearchFieldValues.SIDE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'YEAR_RANGE'}
								<YearRangeInput
									id={searchField?.key}
									label="Year"
									{searchField}
									bind:value={staticSearchFieldValues.YEAR_RANGE}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'VEHICLE_MANUFACTURER'}
								<ManufacturerAutocomplete
									id={searchField?.key}
									{searchField}
									type="VEHICLE"
									bind:manufacturer={staticSearchFieldValues.VEHICLE_MANUFACTURER}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'VEHICLE_MODEL'}
								<ModelAutocomplete
									id={searchField?.key}
									type="VEHICLE"
									manufacturer={staticSearchFieldValues.VEHICLE_MANUFACTURER}
									{searchField}
									bind:model={staticSearchFieldValues.VEHICLE_MODEL}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									{inEditMode}
								/>
							{:else if searchField?.key === 'SMART_SEARCH'}
								<SmartSearchInput
									id={searchField?.key}
									{searchField}
									{searchModules}
									{inEditMode}
									bind:keyword={staticSearchFieldValues.SMART_SEARCH.keyword}
									bind:selectedSearchModuleIds={staticSearchFieldValues.SMART_SEARCH.moduleIds}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
								/>
							{:else if searchField.key.startsWith('ADDITIONAL_')}
								<AdditionalField
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									on:change={e => {
										additionalFieldValues[searchField.key] = e.detail.value
									}}
								/>
							{:else if searchField.key.startsWith('QA_')}
								<InventoryQuestionField
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									on:change={e => {
										inventoryQuestionValues[searchField.key] = e.detail.value
									}}
								/>
							{:else if searchField.key === 'TAG_NUMBER' || searchField.key.startsWith('TYPE_FIELD_')}
								<TextField
									id={searchField?.key}
									{searchField}
									{inEditMode}
									bind:pinnedSearchFields
									bind:selectedSearchFieldsKeys
									on:change={e => {
										typeFieldValues[searchField.key] = e.detail.value
									}}
								/>
							{:else}
								<p>Unsupported field type: {searchField.key}</p>
							{/if}
						{/if}
					{/each}
				</div>
			{:else}
				<div class="jumbotron my-3">
					<h1 class="display-4">No selected search filters.</h1>
					<p class="lead">Use the "Add Search Filter" dropdown below.</p>
				</div>
			{/if}
		</form>
	</div>

	<div class="card-footer">
		<AddEditSearchFilters
			label="Inventory"
			{searchFieldsByCategory}
			{searchFields}
			{inEditMode}
			bind:selectedSearchFieldsKeys
			on:toggleEditMode={e => {
				toggleEditMode(e.detail)
			}}
		/>
	</div>
</div>

<uiView></uiView>
