<script lang="ts">
	import type { SearchField } from 'utility/search-fields'
	import type { InputTypes } from '@isoftdata/svelte-input'

	import Pin from 'components/Pin.svelte'
	import Input from '@isoftdata/svelte-input'
	import { createEventDispatcher } from 'svelte'
	import EditModeControls from 'components/EditModeControls.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let label: string = ''
	export let value: string = ''
	export let disabled: boolean = false
	export let id: string = ''
	export let type: InputTypes = 'text'

	const dispatch = createEventDispatcher<{ change: { field: SearchField; value: string } }>()

	$: disableInput = disabled || inEditMode
	$: label = searchField.name ?? ''

	function change(e: Event) {
		value = (e.target as HTMLInputElement)?.value
		dispatch('change', { field: searchField, value })
	}
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Input
		{id}
		{label}
		{value}
		disabled={disableInput}
		{type}
		on:change={change}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Input>
</EditModeControls>
