import { dashboardChartMembershipQuery, dashboardReportQuery, dashboardChartDataQuery } from "../queries"
import { dashboardCreateReportMutation, dashboardUpdateReportMutation } from "../mutations"

function parseJSON(json) {
	try {
		return JSON.parse(json)
	} catch (e) {
		return null
	}
}

export async function loadChartDataHelper(mediator, { dashboardReportId, chartId, parameterSelectionList }) {
	parameterSelectionList = parameterSelectionList.map(param => {
		return {
			name: param.name,
			value: (param.value ?? '').toString(),
			dates: param.dates,
		}
	})
	// This does not throw when it errors, but I wish it did because then I could catch the errors and display them!
	const { dashboardReport } = await mediator.publish('graphqlFetch', dashboardChartDataQuery, { dashboardReportId, chartArgs: { id: chartId, inReport: true }, parameterSelectionList })
	let { json, dataTable, formatting, ...chart } = dashboardReport.charts.find(chart => chart.chartId === chartId)

	const { query, formatting: unprocessedFormatting, ...jsonProps } = JSON.parse(json) // don't include query, unprocessed formatting

	chart = { formatting: JSON.parse(formatting), ...chart, ...jsonProps }
	// Chart-type specific data handling
	if (chart.type.toLowerCase() === 'table') {
		dataTable.rows = parseJSON(dataTable?.rows) || []
		// Find all the columns we have to convert to numbers
		if (chart.formatting) {
			const colsToFormat = Object.keys(chart.formatting).reduce((prevValue, curValue) => {
				if (chart.formatting[curValue].type === 'NumberFormat') {
					prevValue.push(curValue)
					return prevValue
				}
			}, [])
			// Convert the number columns to numbers
			if (colsToFormat.length) {
				dataTable.rows = dataTable.rows.map(row => {
					for (const col of colsToFormat) {
						row[col].value = Number(row[col].value)
					}
					return row
				})
			}
		}
		chart.table = { ...chart.table, ...dataTable }
	} else if (chart.type.toLowerCase() === 'embed') {
		// TODO?
	} else if (chart.type.toLowerCase() === 'treemap' && dataTable) {
		dataTable.rows = JSON.parse(dataTable?.rows)
		for (let row of dataTable.rows) {
			row.c[2].v = Number(row.c[2].v)
		}
		chart.chartWrapper.dataTable = dataTable
	} else if (chart.type.toLowerCase() === 'piechart' && dataTable) {
		dataTable.rows = JSON.parse(dataTable.rows)
		for (let row of dataTable.rows) {
			row.c[1].v = Number(row.c[1].v)
		}
		chart.chartWrapper.dataTable = dataTable
	} else if (dataTable) {
		dataTable.rows = JSON.parse(dataTable.rows)
		chart.chartWrapper.dataTable = dataTable
	}

	// Parse any dates that are sent as strings but have a 'DateFormat' specified
	for (const format in chart.formatting) {
		if (chart.formatting[format].type === 'DateFormat') {
			const dateColIndex = dataTable.cols.findIndex(col => col.label === format)
			dataTable.cols[dateColIndex].type = 'date'
			for (let i = 0; i < dataTable.rows.length; i++) {
				dataTable.rows[i].c[dateColIndex].v = new Date(dataTable.rows[i].c[dateColIndex].v)
			}
		}
	}
	return chart
}

export async function loadReportMetadataHelper(mediator, { dashboardReportId, chartId = null }) {
	let { dashboardReport } = await mediator.publish('graphqlFetch', dashboardReportQuery, { dashboardReportId, chartArgs: { id: chartId, inReport: true } })
	dashboardReport.charts = dashboardReport.charts.map(chart => {
		let { json, formatting, ...chartProps } = chart
		const { query, formatting: unprocessedFormatting, ...jsonProps } = JSON.parse(json) // don't include query, unprocessed formatting
		return {
			// formatting: JSON.parse(formatting),
			formatting: unprocessedFormatting,
			...chartProps,
			...jsonProps, // chartWrapper, multiSeries, overrides
		}
	})
	return dashboardReport
}

export async function loadDashboardChartMembershipHelper(mediator, { dashboardReportId }) {
	const { dashboardChartMembership } = await mediator.publish('graphqlFetch', dashboardChartMembershipQuery, { reportId: dashboardReportId })
	return dashboardChartMembership.filter(chart => chart.display).map(chart => {
		return {
			...chart,
			dashboardReportId,
		}
	})
}

export async function saveDashboardReportHelper(mediator, { dashboardReport }) {
	console.log("Report put in", dashboardReport)
	if (!dashboardReport.dashboardReportId) { // saving a new report
		const { createDashboardReport } = await mediator.publish('graphqlFetch', dashboardCreateReportMutation, { input: {
			name: dashboardReport.reportName,
			title: dashboardReport.reportTitle,
			json: JSON.stringify({ parameters: dashboardReport.parameters }), // autoRefreshInterval
		} })
		console.log("Report out (new)", createDashboardReport)
		return createDashboardReport
	} else { // update name, title working, parameters and sharing not working yet
		const { updateDashboardReport } = await mediator.publish('graphqlFetch', dashboardUpdateReportMutation, { input: {
			id: dashboardReport.dashboardReportId,
			name: dashboardReport.reportName,
			title: dashboardReport.reportTitle,
			json: JSON.stringify({ parameters: dashboardReport.parameters, autoRefreshInterval: dashboardReport.autoRefreshInterval }),
			shareId: dashboardReport.shareId,
			shareType: dashboardReport.shareType.toUpperCase(),
		} })
		console.log("Report out (update)", updateDashboardReport)
		return {
			...updateDashboardReport,
			shareType: updateDashboardReport.shareType.toLowerCase(),
		}
	}
}
