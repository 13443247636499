import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/EnteredBy'


// create the fragment store

export class EnteredByStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "EnteredByStore",
			variables: true,
			
		})
	}
}
