import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/InventoryType'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class InventoryTypeStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "InventoryTypeStore",
			variables: true,
		})
	}
}

export async function load_InventoryType(params) {
	await initClient()

	const store = new InventoryTypeStore()

	await store.fetch(params)

	return {
		InventoryType: store,
	}
}
