<script lang="ts">
	export let route: string = ''
</script>

<div
	class="container"
	style="margin-top: 20px;"
>
	<div class="well">
		<h1>Sorry, couldn't find route: {route}<small class="float-right"> ¯\_(ツ)_/¯</small></h1>
	</div>
</div>
