<script lang="ts">
	import type { InventoryTypesFull$result } from '$houdini'
	import type { Column } from '@isoftdata/svelte-table'
	import type { SvelteAsr } from 'types/common'

	import { Table, Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import InventoryTypeModal from './InventoryTypeModal.svelte'
	import { onMount, tick } from 'svelte'
	import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'

	export let inventoryTypes: InventoryTypesFull$result['inventoryTypes']
	export let selectedInventoryTypeId: number | undefined
	export let asr: SvelteAsr

	let selectedInventoryType: InventoryTypesFull$result['inventoryTypes'][0] | undefined = undefined
	$: selectedInventoryType = inventoryTypes.find(({ id }) => id === selectedInventoryTypeId)

	let inventoryTypeModal: any

	const columns: Column[] = [
		{ property: 'active', name: 'Active' },
		{ property: 'id', name: 'Type #', numeric: true },
		{ property: 'setId', name: 'Set #', numeric: true },
		{ property: 'name', name: 'Name' },
		{ property: 'vehicle', name: 'Vehicle Unit' },
	]

	onMount(async () => {
		if (selectedInventoryTypeId) {
			await tick()
			document.getElementById(`inventory_type_id_${selectedInventoryTypeId}`)?.scrollIntoView({ block: 'center' })
		}
	})
</script>

<Table
	parentStyle="overflow-y: auto; max-height: 50vh;"
	class="table-sm border-0"
	responsive
	{columns}
	rows={inventoryTypes}
	selectionEnabled
	idProp="id"
	stickyHeader
	columnHidingEnabled
	filterEnabled
	let:row={inventoryTypeItem}
>
	<div
		slot="header"
		class="btn-group mb-2"
	>
		<Button
			size="sm"
			color="success"
			outline
			iconClass="plus"
			title="Add"
			on:click={() => {
				inventoryTypeModal.add()
			}}>Add</Button
		>

		<Button
			size="sm"
			color="primary"
			outline
			colorGreyDisabled
			iconClass="pencil"
			title="Edit Inventory Type"
			disabled={!selectedInventoryType}
			on:click={() => {
				if (selectedInventoryType) {
					inventoryTypeModal.edit(selectedInventoryType)
				}
			}}>Edit Inventory Type</Button
		>
	</div>
	<tr
		class="cursor-pointer"
		id="inventory_type_id_{inventoryTypeItem.id}"
		class:table-primary={selectedInventoryTypeId === inventoryTypeItem.id}
		class:text-black-50={!inventoryTypeItem.active}
		class:font-italic={!inventoryTypeItem.active}
		on:click={() => asr.go(null, { inventoryTypeId: inventoryTypeItem.id })}
	>
		<Td property="active">
			<i
				class:fa-check-circle={inventoryTypeItem.active}
				class:fa-dash={!inventoryTypeItem.active}
				class="far fa-square-check fa-fw"
				aria-hidden="true"
			></i>
		</Td>
		<Td property="id">{inventoryTypeItem.id}</Td>
		<Td property="setId">{inventoryTypeItem.setId}</Td>
		<Td property="name">{inventoryTypeItem.name}</Td>
		<Td property="vehicle">{yesNo(inventoryTypeItem.vehicle)}</Td>
	</tr>
	<tr slot="no-row">
		<td colspan="15"> No matching parts found.</td>
	</tr>
</Table>

<div class="card mt-3">
	<div class="card-header">
		<h5 class="card-title">Inventory Type Details</h5>
	</div>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>

<InventoryTypeModal
	bind:this={inventoryTypeModal}
	bind:inventoryTypeList={inventoryTypes}
	{asr}
/>
