export default {
    "name": "InventoryBySkuAndStore",
    "kind": "HoudiniQuery",
    "hash": "2d0817bcf3c2f161efdaa0d7401de7e71a908d71e3c84d1c2e7faf5ff7dc4f20",

    "raw": `query InventoryBySkuAndStore($sku: Int!, $storeId: Int!) {
  inventoryBySkuAndStore(sku: $sku, storeId: $storeId) {
    id
    vehicleId
    inventoryType {
      vehicle
      id
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventoryBySkuAndStore": {
                "type": "Inventory",
                "keyRaw": "inventoryBySkuAndStore(sku: $sku, storeId: $storeId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "vehicleId": {
                            "type": "UInt",
                            "keyRaw": "vehicleId",
                            "nullable": true,
                            "visible": true
                        },

                        "inventoryType": {
                            "type": "InventoryType",
                            "keyRaw": "inventoryType",

                            "selection": {
                                "fields": {
                                    "vehicle": {
                                        "type": "Boolean",
                                        "keyRaw": "vehicle",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "sku": "Int",
            "storeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b58e48ac7b9612684c0849e345bf21b189d7f1ec97512bf02f221191f9745930";