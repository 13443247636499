export default {
    "name": "SetUserSetting",
    "kind": "HoudiniMutation",
    "hash": "934b5c637e4e031b0a9e288cd462dbb2765decf54b13da65307f4c4a9ef8bc6c",

    "raw": `mutation SetUserSetting($input: SetUserSettingInput!) {
  setUserSetting(input: $input)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "setUserSetting": {
                "type": "Boolean",
                "keyRaw": "setUserSetting(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "SetUserSettingInput"
        },

        "types": {
            "SetUserPartsSettingInput": {
                "clearScreenOnSave": "Boolean",
                "defaultNewAttachmentsArePublic": "Boolean"
            },

            "SetUserPartSearchSettingInput": {
                "pinnedSearchFields": "String"
            },

            "SetUserVehicleSearchSettingInput": {
                "pinnedSearchFields": "String"
            },

            "SetUserVehiclesSettingInput": {
                "defaultNewAttachmentsArePublic": "Boolean"
            },

            "SetUserSettingInput": {
                "parts": "SetUserPartsSettingInput",
                "searchParts": "SetUserPartSearchSettingInput",
                "searchVehicles": "SetUserVehicleSearchSettingInput",
                "vehicles": "SetUserVehiclesSettingInput"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4eea6a897a31cb028cd48673c8017202c048bb6efb3b3876460ae487cbaaa67a";