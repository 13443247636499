<script lang="ts">
	import type { SearchField, NumberRange } from 'utility/search-fields'

	import Pin from './Pin.svelte'
	import { createEventDispatcher } from 'svelte'
	import YearInput from '@isoftdata/svelte-year-input'
	import EditModeControls from './EditModeControls.svelte'

	const dispatch = createEventDispatcher<{ change: NumberRange }>()

	export let id: string
	export let label: string = ''
	export let inEditMode: boolean
	export let searchField: SearchField
	export let disabled: boolean = false
	export let value: NumberRange = { from: null, to: null }
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
</script>

<EditModeControls
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
	{searchField}
	{inEditMode}
>
	<label
		for={id}
		class="col-form-label pb-0"
		>{label}
		<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
	</label>
	<div class="form-row">
		<div class="col-12 col-md-6">
			<YearInput
				{id}
				label="From"
				disabled={disabled || inEditMode}
				on:change={e => {
					value.from = e.detail
					dispatch('change', { from: value.from, to: value.to })
				}}
				value={value.from}
			/>
		</div>
		<div class="col-12 col-md-6">
			<YearInput
				id="{searchField.key}-to"
				label="To"
				disabled={disabled || inEditMode}
				on:change={e => {
					value.to = e.detail
					dispatch('change', { from: value.from, to: value.to })
				}}
				value={value.to}
			/>
		</div>
	</div>
</EditModeControls>
