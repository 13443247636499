<script lang="ts">
	import type { CurrencyRange } from 'utility/search-fields'

	import Pin from 'components/Pin.svelte'
	import Input from '@isoftdata/svelte-input'
	import { createEventDispatcher } from 'svelte'

	export let to: string = ''
	export let from: string = ''
	export let label: string = ''
	export let disabled: boolean = false
	export let isPinned: boolean = false
	export let id: string = ''

	const dispatch = createEventDispatcher<{
		change: CurrencyRange
	}>()
</script>

<label
	for={id}
	class="col-form-label pb-0"
	>{label} <Pin {isPinned} />
</label>
<div class="form-row">
	<div class="col-12 col-md-6">
		<Input
			{id}
			label="From"
			bind:value={from}
			on:change={() => {
				if (from && !to) {
					to = from
				}
				dispatch('change', { from, to })
			}}
			{disabled}
		/>
	</div>

	<div class="col-12 col-md-6">
		<Input
			label="To"
			bind:value={to}
			on:change={() => dispatch('change', { from, to })}
			{disabled}
		/>
	</div>
</div>
