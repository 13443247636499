export default [{ type: 'Vehicle',
	abbreviation: 'A',
	name: 'Available',
	triggerPartChange: 'True',
	triggerPartValue: 'A' },
{ type: 'Vehicle',
	abbreviation: 'B',
	name: 'Bid',
	triggerPartChange: 'False',
	triggerPartValue: 'D' },
{ type: 'Vehicle',
	abbreviation: 'C',
	name: 'Crushed',
	triggerPartChange: 'True',
	triggerPartValue: 'C' },
{ type: 'Vehicle',
	abbreviation: 'D',
	name: 'Deleted',
	triggerPartChange: 'True',
	triggerPartValue: 'D' },
{ type: 'Vehicle',
	abbreviation: 'H',
	name: 'On Hold',
	triggerPartChange: 'True',
	triggerPartValue: 'H' },
{ type: 'Vehicle',
	abbreviation: 'S',
	name: 'Sold',
	triggerPartChange: 'True',
	triggerPartValue: 'S' }]
