<script
	lang="ts"
	context="module"
>
	export type AdditionalInfoType = 'Basic Info' | 'Allocations' | 'Attachments' | 'Locations' | 'Messages' | 'Q & A' | 'Vehicle Info'

	//end of context=module
</script>

<script lang="ts">
	import type { InventoryItem } from './results.ts'
	import type { SvelteAsr } from 'types/common'

	import { tick } from 'svelte'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import PartBasic from './PartInfo.svelte'
	import PartMessages from './PartMessages.svelte'
	import PartQA from 'components/PartQA.svelte'
	import PartLocations from './PartLocations.svelte'
	import VehicleInfo from 'components/VehicleInfo.svelte'
	import PartAllocations from './PartAllocations.svelte'
	import PartAttachments from 'components/ItemDetailAttachments.svelte'

	let partAttachments: PartAttachments | undefined
	export let asr: SvelteAsr
	export let item: InventoryItem //required
	export let additionalInfoType: AdditionalInfoType = 'Basic Info'
	const additionalInfoTypeList: AdditionalInfoType[] = ['Basic Info', 'Allocations', 'Attachments', 'Locations', 'Messages', 'Q & A', 'Vehicle Info']
</script>

<div class="d-flex justify-content-between align-items-end">
	<Select
		label="Part Information"
		showEmptyOption={false}
		bind:value={additionalInfoType}
	>
		{#each additionalInfoTypeList as additionalInfoTypeItem}
			<option value={additionalInfoTypeItem}>{additionalInfoTypeItem}</option>
		{/each}
	</Select>
	<Button
		size="sm"
		outline
		iconClass="pencil"
		class="mb-1 ml-2"
		href={asr.makePath('app.part', { inventoryId: item.id })}>Edit Part</Button
	>
</div>
{#if item?.attachments?.[0]?.file?.path && additionalInfoType !== 'Attachments'}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
	<div class="d-flex justify-content-center">
		<img
			class="w-50 rounded cursor-pointer"
			src={item?.attachments?.[0]?.file?.path}
			alt={[item.manufacturer?.name, item.model?.name, item.inventoryType?.name].filter(value => value).join(' ')}
			on:click={async () => {
				additionalInfoType = 'Attachments'
				await tick()
				partAttachments?.openImageViewerModal(item?.attachments?.[0]?.id)
			}}
		/>
	</div>
	<hr />
{/if}

<!-- Ensure components get rerendered when the item changes. Fixes an issue with attachments -->
{#key item.id}
	{#if additionalInfoType === 'Basic Info'}
		<PartBasic {item} />
	{:else if additionalInfoType === 'Messages'}
		<PartMessages inventoryId={item.id} />
	{:else if additionalInfoType === 'Q & A'}
		<PartQA inventoryId={item.id} />
	{:else if additionalInfoType === 'Locations'}
		<PartLocations inventoryId={item.id} />
	{:else if additionalInfoType === 'Vehicle Info'}
		<VehicleInfo
			vehicleId={item.vehicle?.id}
			{asr}
		/>
	{:else if additionalInfoType === 'Allocations'}
		<PartAllocations inventoryId={item.id} />
	{:else if additionalInfoType === 'Attachments'}
		<PartAttachments
			attachments={item?.attachments ?? []}
			bind:this={partAttachments}
		/>
	{/if}
{/key}
