<script lang="ts">
	import type { DateRange as DateRangeType } from 'utility/search-fields'

	import DateRange from '@isoftdata/svelte-date-range'
	import Pin from 'components/Pin.svelte'

	export let label: string = ''
	export let disabled: boolean = false
	export let isPinned: boolean = false
	export let dates: DateRangeType = { from: '', to: '' }
	export let id: string = ''
</script>

<label
	for={id}
	class="col-form-label pb-0"
	>{label}
	<Pin {isPinned} />
</label>

<DateRange
	fromId={id}
	allowNone
	colClass="col-12 col-lg-6"
	hideDateRange
	bind:dates
	{disabled}
	on:change
	(...$$restProps)
/>
