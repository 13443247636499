export default class DefaultPart {
	constructor(parentId, storeId) {
		this.category = ''
		this.side = ''
		this.quantity = 1

		this.cost = 0
		this.retailPrice = 0
		this.listPrice = 0
		this.wholesalePrice = 0

		this.status = null

		this.description = null
		this.notes = null

		this.vehicleId = parentId
		this.storeId = storeId
		this.parentManufacturerId = null //assembly
		this.parentModelId = null //assembly

		this.manufacturerId = null
		this.modelId = null
	}
}
