<script lang="ts">
	import type { Store, SearchField } from 'utility/search-fields'

	import { createEventDispatcher } from 'svelte'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'

	const dispatch = createEventDispatcher<{ change: number[] }>()

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let stores: Store[]
	export let selectedStores: number[]
	export let disabled: boolean = false
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<!--
		NOTE: We're not using our Select component here because of an issue with the `multiple` attribute.
		Instead of getting an array back, you just get the last clicked value.
		When that issue is resolved, we can switch back to using our Select component since the Select now has a `hint` slot.
	-->
	<div class="form-group mb-1">
		<label for="stores"
			>Stores
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</label>
		<select
			id="stores"
			class="form-control form-control-sm"
			bind:value={selectedStores}
			multiple
			disabled={disabled || inEditMode}
			on:change={() => dispatch('change', selectedStores)}
			{...$$restProps}
		>
			{#each stores as store}
				<option value={store.id}>{store.code} - {store.name}</option>
			{/each}
		</select>
	</div>
</EditModeControls>
