import { vehicleInventoryReturnData } from './fragments'

export const dashboardCreateReportMutation = `#graphql
mutation LIB_createDashboardReportMutation($input: CreateDashboardReportInput!) {
	createDashboardReport(input: $input) {
		dashboardReportId: id
		reportTitle: title
		reportName: name
		ownerId
		shareType
		shareId
		parameters: parameterValues {
			... on DashboardReportDateParameter {
				name
				type
				title
				default
			}
			... on DashboardReportDateRangeParameter {
				name
				type
				title
				default
			}
			... on DashboardReportSelectionParameter {
				name
				type
				title
				default
				optionList {
					id
					name
					fromQuery
					rawSql
				}
			}
			... on DashboardReportSessionParameter {
				name
				type
				title
				default
				value
				}
			... on DashboardReportSiteSelectionParameter {
				name
				type
				title
				default
				value
				permission
				optionList {
					id
					name
					fromQuery
				}
			}
		}
	}
}`

export const dashboardUpdateReportMutation = `#graphql
mutation Mutation($input: UpdateDashboardReportInput) {
	updateDashboardReport(input: $input) {
		dashboardReportId: id
		reportTitle: title
		reportName: name
		ownerId
		shareType
		shareId
		autoRefreshInterval
		parameters: parameterValues {
			... on DashboardReportDateParameter {
				name
				type
				title
				default
			}
			... on DashboardReportDateRangeParameter {
				name
				type
				title
				default
			}
			... on DashboardReportSelectionParameter {
				name
				type
				title
				default
				optionList {
					id
					name
					fromQuery
					rawSql
				}
			}
			... on DashboardReportSessionParameter {
				name
				type
				title
				default
				value
				}
			... on DashboardReportSiteSelectionParameter {
				name
				type
				title
				default
				value
				permission
				optionList {
					id
					name
					fromQuery
				}
			}
		}
	}
}`

export const updateVehicleInventoryMutation = `#graphql
mutation LIB_updateVehicleInventory($innodbInventoryid: UInt!, $part: InventoryUpdate!) {
	part: updateInventory(id:$innodbInventoryid, input: $part) {
		${vehicleInventoryReturnData}
	}
}`

export const newVehicleInventoryMutation =	`#graphql
mutation LIB_newVehicleInventory($part: NewStandardInventory!) {
	part: createStandardInventory(input: $part) {
		${vehicleInventoryReturnData}
	}
}`
