<script lang="ts">
	//NOTE: This component expects to be rendered to the DOM with a form element which has an id of `search-field-form`. The search button in this component will submit that form.

	import type { SearchFieldsByCategory, SearchField } from 'utility/search-fields'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import { tick, createEventDispatcher } from 'svelte'
	import getEventValue from 'utility/get-event-value'

	export let label: string // Required
	export let searchFieldsByCategory: SearchFieldsByCategory // Required
	export let selectedSearchFieldsKeys: Set<string> //Required
	export let searchFields: SearchField[] // Required
	export let inEditMode: boolean // Required

	const dispatch = createEventDispatcher<{ toggleEditMode: boolean | undefined }>()
	let availableSearchFields: SearchFieldsByCategory
	let addSearchFieldValue: string | null = null
	let addSelectFieldSelect: HTMLSelectElement | undefined

	function getAvailableSearchFields(searchFieldsByCategory: SearchFieldsByCategory, selectedSearchFieldsKeys: Set<string>): SearchFieldsByCategory {
		return Object.keys(searchFieldsByCategory).reduce((acc, categoryKey) => {
			const availableFields = searchFieldsByCategory[categoryKey].fields.filter(field => !selectedSearchFieldsKeys.has(field.key))
			if (availableFields.length) {
				acc[categoryKey] = {
					...searchFieldsByCategory[categoryKey],
					fields: availableFields,
				}
			}

			return acc
		}, {})
	}

	function onAddSearchField(event: Event) {
		const searchFieldKey = getEventValue(event)
		if (searchFieldKey) {
			addSearchField(searchFieldKey)
		}
		addSearchFieldValue = null
	}

	async function addSearchField(searchFieldKey: string, isFirstDependency: boolean = false) {
		const searchFieldToAdd = searchFields.find(field => field.key === searchFieldKey)
		if (searchFieldToAdd && !selectedSearchFieldsKeys.has(searchFieldKey)) {
			if (searchFieldToAdd?.dependencies?.length) {
				for (let i = 0; i < searchFieldToAdd?.dependencies.length; i++) {
					await addSearchField(searchFieldToAdd?.dependencies[i], i === 0)
				}
			}
			selectedSearchFieldsKeys.add(searchFieldKey)
			selectedSearchFieldsKeys = selectedSearchFieldsKeys
			await tick()
			if ((searchFieldToAdd?.dependencies?.length && isFirstDependency) || !searchFieldToAdd?.dependencies?.length) {
				focusSearchField(searchFieldKey)
			}
		}
	}

	function focusSearchField(searchFieldKey: string) {
		const el = document.querySelector<HTMLInputElement | HTMLSelectElement>(`#${CSS.escape(searchFieldKey)}`)
		el?.focus?.()
		el?.showPicker() //If the element in question has a picker that can be shown, go ahead and do it!
	}

	$: availableSearchFields = getAvailableSearchFields(searchFieldsByCategory, selectedSearchFieldsKeys)
</script>

<div class="form-row align-items-end">
	<div class="col-12 col-md-auto">
		<Select
			label="Add Search Filter"
			emptyValue={null}
			bind:value={addSearchFieldValue}
			bind:select={addSelectFieldSelect}
			on:change={onAddSearchField}
			disabled={!Object.keys(availableSearchFields).length}
			on:focus={() => {
				dispatch('toggleEditMode', true)
				//Always show the dropdown for this select when focused in order to avoid an issue where they type when the field is focused by the dropdown isn't open and it calls on change on each keystroke
				addSelectFieldSelect?.showPicker()
			}}
		>
			{#each Object.keys(availableSearchFields) as categoryKey (categoryKey)}
				<optgroup label={availableSearchFields[categoryKey].name}>
					{#each availableSearchFields[categoryKey].fields as field (field.key)}
						<option value={field.key}>
							{field.name}
						</option>
					{/each}
				</optgroup>
			{/each}
		</Select>
	</div>
	<div class="col-12 col-md-auto">
		<Button
			size="sm"
			outline={!inEditMode}
			iconClass="pencil"
			class="mb-1 d-block d-md-inline-block w-100"
			on:click={() => dispatch('toggleEditMode')}
			>{#if inEditMode}Done Editing{:else}Edit Filters{/if}</Button
		>
	</div>
	<div class="ml-auto col-auto">
		<Button
			size="sm"
			iconClass="search"
			class="mb-1 d-block d-md-inline-block ml-auto"
			form="search-field-form"
			type="submit">Search {label}</Button
		>
	</div>
</div>
