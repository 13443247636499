<script
	lang="ts"
	context="module"
>
	import type { InventoryLocation, SearchField } from 'utility/search-fields'

	import { loadLocationsForStore } from 'utility/search-fields'
	import { klona } from 'klona'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import EditModeControls from 'components/EditModeControls.svelte'
	import Pin from 'components/Pin.svelte'
</script>

<script lang="ts">
	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let locationList: Promise<InventoryLocation[]> | InventoryLocation[] = []
	export let inventoryLocation: InventoryLocation | null = null
	export let stores: number[]
	export let disabled: boolean = false

	let previousStores: number[] | null = null //default to the same as the original store array

	async function loadLocations(storeIds: number[]) {
		//TODO: we can remove this if we fix whatever bug in the autocomplete component makes it be slow when the autocomplete has a `value` and the `options` change
		//I've put timers here, thinking that the amount of data we get from the API was causing the slowness, but it's not.
		//It's the autocomplete component itself and I couldn't figure out what was causing it.
		if (previousStores && previousStores.length === stores.length && previousStores.every(storeId => stores.includes(storeId))) {
			previousStores = klona(stores)
			return
		}

		previousStores = klona(stores)
		if (storeIds.length) {
			locationList = loadLocationsForStore(storeIds)
			locationList.then(locations => {
				locationList = locations

				if (inventoryLocation && locationList && !locationList.find(locationListItem => locationListItem.id === inventoryLocation?.id)) {
					inventoryLocation = null
				}
			})
		} else {
			locationList = []
			inventoryLocation = null
		}
	}

	$: loadLocations(stores)
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Autocomplete
		label="Location"
		options={locationList}
		emptyValue={null}
		disabled={disabled || !stores.length || inEditMode}
		placeholder={stores.length ? '-- Select a Location --' : 'Choose at least one store first'}
		bind:value={inventoryLocation}
		getLabel={option => (option ? option?.name ?? '' : '')}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</Autocomplete>
</EditModeControls>
