import * as currency from '@isoftdata/utility-currency'

const currencyFormatOptions = { includeSymbol: false, includeComma: false }

export default class myTeardownItem {
	constructor(part, inventoryTypeList) {
		const exists = !!(part.inventoryId)
		const inventoryType = inventoryTypeList.find(inventoryType => inventoryType.inventoryTypeId === part.inventoryTypeId)
		//const setInventoryType = inventoryTypeList.find((setInventoryType) => setInventoryType.inventoryTypeId === inventoryType.typeSetId)

		this.id = part.inventoryId
		this.storeId = part.storeId
		this.innodbInventoryid = part.innodbInventoryid
		this.tagNumber = part.tagNumber

		this.parentManufacturerId = part.parentManufacturerId
		this.parentModelId = part.parentModelId

		this.partManufacturerId = part.manufacturerId
		this.partModelId = part.modelId

		this.partModelName = part.model ? part.model.name : ''
		this.partManufacturerName = part.manufacturer ? part.manufacturer.name : ''
		this.parentModelName = part.parentModel ? part.parentModel.name : ''
		this.parentManufacturerName = part.parentManufacturer ? part.parentManufacturer.name : ''

		this.notes = part.notes
		this.coreCost = currency.format(part.coreCost, currencyFormatOptions)
		this.status = part.status

		this.breakdownTemplateId = part.breakdownTemplateId ? part.breakdownTemplateId : null
		this.rank = part.rank ? part.rank : null

		this.inventoryTypeName = inventoryType?.name
		this.inventoryTypeId = part.inventoryTypeId
		this.typeSetId = inventoryType?.typeSetId

		this.description = part.description
		this.category = part.category
		this.userStatus = part.userStatus
		this.categoryName = part.category ? part.category.name : ''
		this.categoryId = part.category ? part.category.id : null

		this.oemNumber = part.oemNumber || ''
		this.condition = part.condition || ''
		this.shippingDimensions = part.shippingDimensions || {}
		this.serials = part.serials || []
		this.serialized = !!(part.serialized)
		this.public = !!(part.public)
		this.typeField1 = part.typeField1 || {}
		this.typeField2 = part.typeField2 || {}
		this.typeField3 = part.typeField3 || {}
		this.typeField4 = part.typeField4 || {}

		this.side = part.side
		this.vehicleId = part.vehicleId
		this.quantity = part.quantity
		this.retailPrice = currency.format(part.retailPrice, currencyFormatOptions)
		this.wholesalePrice = currency.format(part.wholesalePrice, currencyFormatOptions)
		this.cost = currency.format(part.cost, currencyFormatOptions)
		this.jobberPrice = currency.format(part.jobberPrice, currencyFormatOptions)
		this.retailCorePrice = currency.format(part.retailCorePrice, currencyFormatOptions)

		//this.partCategoryList = inventoryType.categories
		//this.parentManufacturerList = setInventoryType.partManufacturers

		this.parentModelList = part.parentManufacturer ? part.parentManufacturer.models : []
		this.partModelList = part.manufacturer ? part.manufacturer.models : []

		this.attachments = part.attachments ? part.attachments.map(item => {
			return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
		}) : []
		this.exists = exists
		this.fileCount = part.attachments ? part.attachments.length : 0
		this.locations = part.locations
		this.locked = !!part.locked
	}
}
