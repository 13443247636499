export default {
    "name": "ShippingDimensions",
    "kind": "HoudiniFragment",
    "hash": "2a4dd5eb0e427fe044b1b403287af6ac5fd1ada480539cb53aa3b1459d7a4f5b",

    "raw": `fragment ShippingDimensions on Inventory {
  shippingDimensions {
    weightUnit
    measurementUnit
    weight
    length
    width
    height
  }
  id
  __typename
}
`,

    "rootType": "Inventory",

    "selection": {
        "fields": {
            "shippingDimensions": {
                "type": "Dimensions",
                "keyRaw": "shippingDimensions",

                "selection": {
                    "fields": {
                        "weightUnit": {
                            "type": "DimensionsWeightUnit",
                            "keyRaw": "weightUnit",
                            "visible": true
                        },

                        "measurementUnit": {
                            "type": "DimensionsMeasurementUnit",
                            "keyRaw": "measurementUnit",
                            "visible": true
                        },

                        "weight": {
                            "type": "Decimal6",
                            "keyRaw": "weight",
                            "visible": true
                        },

                        "length": {
                            "type": "Decimal2",
                            "keyRaw": "length",
                            "visible": true
                        },

                        "width": {
                            "type": "Decimal2",
                            "keyRaw": "width",
                            "visible": true
                        },

                        "height": {
                            "type": "Decimal2",
                            "keyRaw": "height",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "id": {
                "type": "UInt",
                "keyRaw": "id",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=ffe455db6748775af992c38d48251da21ccc7228dfbbff23b61418954a4c1db5";