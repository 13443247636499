<script lang="ts">
	import type { Template, DomApi } from 'types/common'
	import type { Column } from '@isoftdata/svelte-table'
	import type { AdditionalInfoType } from './ItemDetail.svelte'
	type Vehicles = VehicleSearchResultsData$result['vehicles']
	import type { Vehicle } from './results.ts'
	import type { Breakpoints } from '@isoftdata/utility-bootstrap'
	import type { AbstractStateRouter } from 'abstract-state-router'
	import type { VehicleSearchResultsData$result } from '$houdini'
	import type { PerPageCount } from 'utility/search-fields'

	import { localWritable } from '@macfja/svelte-persistent-store'
	import { tick, onMount } from 'svelte'
	import { fly } from 'svelte/transition'
	import Modal from '@isoftdata/svelte-modal'
	import ItemDetail from './ItemDetail.svelte'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { Table, Td } from '@isoftdata/svelte-table'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import { perPageCounts, computeTypeLabelColumns } from 'utility/search-fields'

	export let sortColumnProp: string
	export let pageNumber: number // Required
	export let vehicleSearchResults: Vehicles // Required
	export let sortDirection: 'ASC' | 'DESC' = 'ASC'
	export let perPageCount: PerPageCount // Required
	export let asr: AbstractStateRouter<Template, DomApi>
	export let selectedVehicleId: number | null // Required
	export let previousSortDirection: 'ASC' | 'DESC' = 'DESC'
	export let unitTypeId: number | undefined

	const showThumbnails = localWritable('showThumbs', true)
	let resultsColumns: Column[] = computeTableColumns($showThumbnails, [])
	let sortColumn: Column | undefined = sortColumnProp ? resultsColumns.find(column => column.property === sortColumnProp) : undefined
	let itemDetailVehicle: Vehicle | null = null
	let itemDetailManModSubtitle: string = ''
	let bootstrapBreakpoint: Breakpoints | 'xxl' = getBootstrapBreakpoint() //it can't actually be xxl class, but because of the typing of the getBootstrapBreakpoint fn, we need to allow xxl
	let isPhoneSize: boolean = false
	let showItemDetailModal: boolean = false
	let itemDetailAdditionalInfoType: AdditionalInfoType = 'Basic Info'

	function computeItemDetailManModSubtitle(vehicle: Vehicle | null): string {
		if (vehicle) {
			const { make, model, year } = vehicle
			if (make && model) {
				return [year, make, model].filter(v => v).join(' > ')
			}

			return vehicle.stockNumber ?? ''
		}

		return ''
	}

	function computeTableColumns(showThumbnails: boolean, typeLabelColumns: Column[] = []) {
		let columns: Column[] = [
			{ property: 'attentionIcons', name: '', icon: 'circle-info', sortType: false, minWidth: '150px' },
			{ property: 'storeId', name: 'Store' },
			{ property: 'stockNumber', name: 'Stock #' },
			{ property: 'wholeUnit[inventoryType][name]', name: 'Type Name' },
			{ property: 'year', name: 'Year' },
			{ property: 'make', name: 'Make' },
			{ property: 'model', name: 'Model' },
			{ property: 'vin', name: 'VIN' },
			{ property: 'categoryType[name]', name: 'Category' },
			{ property: 'location', name: 'Location' },
			{ property: 'receivedDate', name: 'Date Received' },
			{ property: 'status', name: 'Status' },
			{ property: 'userStatus', name: 'User Status' },
			{ property: 'wholeUnit[retailPrice]', name: 'Retail' },
		]

		columns = [...columns, ...typeLabelColumns]

		if (showThumbnails) {
			const attentionColumnIndex = columns.findIndex(column => (column.property = 'attentionIcons'))
			if (attentionColumnIndex > -1) {
				columns.splice(attentionColumnIndex + 1, 0, { property: 'attachmentCount', icon: 'photo-video', name: '' })
			}
		}

		return columns
	}

	async function showDetailPane(item: Vehicle, additionalInfoType: AdditionalInfoType) {
		if (item.id === itemDetailVehicle?.id && additionalInfoType === itemDetailAdditionalInfoType) {
			//They clicked the same button, so just close the sidebar
			itemDetailVehicle = null
			additionalInfoType = 'Basic Info'
			return
		}

		itemDetailVehicle = item
		if (isPhoneSize) {
			showItemDetailModal = true
		}
		await tick()
		if (additionalInfoType) {
			itemDetailAdditionalInfoType = additionalInfoType
		}
	}

	$: itemDetailManModSubtitle = computeItemDetailManModSubtitle(itemDetailVehicle)
	$: isPhoneSize = bootstrapBreakpoint === 'xs'
	$: typeLabelColumns = computeTypeLabelColumns(
		unitTypeId,
		vehicleSearchResults.items.map(item => item.wholeUnit?.inventoryType),
	)
	$: resultsColumns = computeTableColumns($showThumbnails, typeLabelColumns)
</script>

<svelte:window
	on:resize={() => {
		bootstrapBreakpoint = getBootstrapBreakpoint()
		if (!isPhoneSize && showItemDetailModal) {
			showItemDetailModal = false
		} else if (isPhoneSize && itemDetailVehicle) {
			showItemDetailModal = true
		}
	}}
/>

<div class="card-body">
	<div class="form-row justify-content-between align-items-end">
		<div class="col-auto">
			<Checkbox
				label="Show Image Thumbnails"
				bind:checked={$showThumbnails}
				inline
			/>
		</div>
		<div class="col-auto">
			{vehicleSearchResults.totalItems} Vehicles Found
		</div>
		<div class="col-auto">
			<Select
				label="Results Per Page"
				showEmptyOption={false}
				bind:value={perPageCount}
				on:change={() => {
					localStorage.setItem('vehicleSearchResultsPerPageCount', perPageCount)
					asr.go(null, { perPageCount, pageNumber: 1 }, { inherit: true })
				}}
			>
				{#each perPageCounts as perPageCount}
					<option value={perPageCount}>{perPageCount}</option>
				{/each}
			</Select>
		</div>
		{#if itemDetailVehicle && !isPhoneSize}
			<div class="col-md-6 col-lg-4 col-xl-3"></div>
		{/if}
	</div>

	<div class="form-row flex-nowrap">
		<div class={itemDetailVehicle && !isPhoneSize ? 'col-md-6 col-lg-8' : 'col-auto'}>
			<Table
				parentStyle="overflow-y: auto; max-height: 85vh;"
				class="border-0"
				responsive
				rows={vehicleSearchResults.items}
				columns={resultsColumns}
				currentPageNumber={pageNumber}
				totalItemsCount={vehicleSearchResults.totalItems}
				selectedRowIds={[selectedVehicleId]}
				idProp="id"
				perPageCount={parseInt(perPageCount, 10)}
				stickyHeader
				columnHidingEnabled
				{previousSortDirection}
				{sortDirection}
				{sortColumn}
				let:row={vehicle}
				on:pageChange={e => asr.go(null, { pageNumber: e.detail.pageNumber }, { inherit: true })}
				columnClickedMethod={async (column, direction) => {
					previousSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC'
					asr.go(null, { sortColumn: column.property, sortDirection: direction, pageNumber: 1 }, { inherit: true })
				}}
			>
				<tr
					class="cursor-pointer"
					class:table-primary={selectedVehicleId === vehicle.id}
					on:click={() => asr.go('app.vehicle', { vehicleId: vehicle.id, storeId: vehicle.storeId })}
				>
					<Td
						property="attentionIcons"
						stopPropagation
					>
						<Button
							size="xs"
							color="dark"
							outline={!(itemDetailVehicle?.id === vehicle.id && itemDetailAdditionalInfoType === 'Basic Info')}
							iconClass="truck"
							title="View Vehicle Info"
							on:click={() => {
								showDetailPane(vehicle, 'Basic Info')
							}}
						/>
						{#if vehicle.componentCount > 0}
							<Button
								size="xs"
								color="dark"
								outline={!(itemDetailVehicle?.id === vehicle.id && itemDetailAdditionalInfoType === 'Components')}
								iconClass="engine"
								title="View Vehicle Components"
								on:click={() => {
									showDetailPane(vehicle, 'Components')
								}}>{vehicle.componentCount}</Button
							>
						{/if}
						<!-- {#if vehicle.messageCount}
							<Button
								size="xs"
								color="dark"
								outline={!(itemDetailVehicle?.id === vehicle.id && itemDetailAdditionalInfoType === 'Messages')}
								iconClass="message-lines"
								title="View Vehicle Messages"
								on:click={() => {
									showDetailPane(vehicle, 'Messages')
								}}>{vehicle.messageCount}</Button
							>
						{/if} -->
						<!-- <Button
							size="xs"
							color="dark"
							style="width: 46px;"
							outline={!(itemDetailVehicle?.id === vehicle.id && itemDetailAdditionalInfoType === 'Allocations')}
							iconClass="files"
							title={inventoryTotalAllocationsMap.get(vehicle.id) ? 'View Part Allocations' : 'No Allocations for This Part'}
							colorGreyDisabled={false}
							disabled={$inventoryAllocationsQuery.fetching || !inventoryTotalAllocationsMap.has(vehicle.id) || !inventoryTotalAllocationsMap.get(vehicle.id)}
							on:click={() => {
								showDetailPane(vehicle, 'Allocations')
							}}>{$inventoryAllocationsQuery.fetching ? '' : inventoryTotalAllocationsMap.get(vehicle.id) ?? '0'}</Button
						> -->
						{#if vehicle.attachments.length}
							<Button
								size="xs"
								color="dark"
								outline={!(itemDetailVehicle?.id === vehicle.id && itemDetailAdditionalInfoType === 'Attachments')}
								iconClass="paperclip"
								title="View Part Attachments"
								on:click={() => {
									showDetailPane(vehicle, 'Attachments')
								}}>{vehicle.attachments.length}</Button
							>
						{/if}
					</Td>
					{#if $showThumbnails}
						<Td
							property="imageThumbnail"
							stopPropagation
						>
							<ImageThumbnail
								thumbnailFile={{ path: vehicle?.topImage?.path ?? '' }}
								showThumbnail
								showImageCount
								noImagePath="images/noimage.jpg"
								context={vehicle.attachments}
								fileCount={vehicle.attachments.filter(attachment => attachment.file.mimetype.startsWith('image/')).length}
								on:click={() => {
									showDetailPane(vehicle, 'Attachments')
								}}
							/>
						</Td>
					{/if}
					<Td property="storeId">{vehicle.storeId ?? ''}</Td>
					<Td property="stockNumber">{vehicle.stockNumber ?? ''}</Td>
					<Td property="wholeUnit[inventoryType][name]">{vehicle.wholeUnit?.inventoryType?.name ?? ''}</Td>
					<Td property="year">{vehicle.year ?? ''}</Td>
					<Td property="make">{vehicle.manufacturer?.name ?? ''}</Td>
					<Td property="model">{vehicle.model?.name ?? ''}</Td>
					<Td property="vin">{vehicle.vin ?? ''}</Td>
					<Td property="categoryType[name]">{vehicle.categoryType?.name ?? ''}</Td>
					<Td property="location">{vehicle.location ?? ''}</Td>
					<Td property="receivedDate">{vehicle.receivedDate ? vehicle.receivedDate.toLocaleDateString() : ''}</Td>
					<Td property="status">{vehicle.status ?? ''}</Td>
					<Td property="userStatus">{vehicle.userStatus ?? ''}</Td>
					<Td property="wholeUnit[retailPrice]">{formatCurrency(vehicle.wholeUnit?.retailPrice ?? '')}</Td>
					{#each typeLabelColumns as column}
						<Td property={column.property}>
							{#if !unitTypeId && vehicle[column.property]?.data}
								<span
									class="font-weight-bold"
									style="white-space: nowrap;">{vehicle[column.property.replace('Label', 'Field')]?.label ?? ''}:</span
								>
							{/if}
							{vehicle[column.property]?.data ?? ''}
						</Td>
					{/each}
				</tr>
				<tr slot="no-row">
					<td colspan="15"> No matching vehicles found.</td>
				</tr>
			</Table>
		</div>
		{#if itemDetailVehicle && !isPhoneSize}
			<div class="col-md-6 col-lg-4">
				<div
					transition:fly={{ x: 500, duration: 200 }}
					class="card"
				>
					<div class="card-header">
						<div class="d-flex justify-content-between align-items-center flex-nowrap">
							<h5 class="w-100 text-truncate">
								{#if itemDetailVehicle}
									Stock #: {itemDetailVehicle?.stockNumber ?? ''}
									<br />
									<small>
										<span class="font-weight-bold">{itemDetailVehicle?.wholeUnit?.inventoryType?.name}</span>
										{itemDetailManModSubtitle}
									</small>
								{/if}
							</h5>
							<Button
								size="sm"
								iconClass="caret-right"
								outline
								title="Collapse Item Details Pane"
								on:click={() => (itemDetailVehicle = null)}
							/>
						</div>
					</div>
					<div
						class="card-body"
						style="overflow-y: auto; max-height: 80vh;"
					>
						<ItemDetail
							item={itemDetailVehicle}
							bind:additionalInfoType={itemDetailAdditionalInfoType}
							{asr}
							on:imageThumbnailClick={e => {
								const inventoryItem = vehicleSearchResults.items.find(item => item.id === e.detail)
								if (inventoryItem) {
									showDetailPane(inventoryItem, 'Attachments')
								}
							}}
						/>
					</div>
				</div>
			</div>
		{/if}
	</div>
</div>

<Modal
	title="Stock #: {itemDetailVehicle?.stockNumber ?? 'Item Details'}"
	subtitle={itemDetailManModSubtitle}
	confirmShown={false}
	show={!!(itemDetailVehicle && showItemDetailModal)}
	on:close={() => {
		showItemDetailModal = false
		itemDetailVehicle = null
	}}
	>{#if itemDetailVehicle}
		<ItemDetail
			item={itemDetailVehicle}
			bind:additionalInfoType={itemDetailAdditionalInfoType}
			{asr}
		/>
	{/if}</Modal
>
