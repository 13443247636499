export const dashboardChartMembershipQuery = `#graphql
query LIB_chartMembershipQuery($reportId: Int!) {
	dashboardChartMembership(reportId: $reportId) {
		chartId: id
		display
		inReport
		rank
		supertype
		title
		chartType: type
	}
}`

export const dashboardChartDataQuery = `#graphql
query LIB_chartDataQuery(
		$parameterSelectionList: [DashboardReportParametersInput]
		$dashboardReportId: Int!
		$chartArgs: DashboardReportChartsArgs
) {
	dashboardReport(parameters: $parameterSelectionList, id: $dashboardReportId) {
		id
		charts(args: $chartArgs) {
			chartId: id
			name
			title
			rank
			type
			supertype
			formatting
			json: jsonCombined
			dataTable {
				... on GoogleDataTable {
					rows
					cols {
						id
						label
						type
						role
					}
				}
				... on RactiveDataTable {
					columns {
						class
						name
						property
					}
					rows
				}
			}
			multiSeries {
				value
				series
				tooltip
				groupXAxisBy
			}
		}
		parameterValues {
			... on DashboardReportDateParameter {
				name
				type
				title
				default
				value
			}
			... on DashboardReportDateRangeParameter {
				name
				type
				title
				default
				value
				dates {
					from
					to
				}
			}
			... on DashboardReportSelectionParameter {
				name
				type
				title
				default
				value
				optionList {
					id
					name
					fromQuery
					rawSql
				}
			}
			... on DashboardReportSessionParameter {
				name
				type
				title
				default
				value
			}
			... on DashboardReportSiteSelectionParameter {
				name
				type
				title
				default
				value
				permission
				optionList {
					id
					name
					fromQuery
				}
			}
		}
	}
}`

export const dashboardReportTitles = `#graphql
query LIB_reportTitlesQuery($args: DashboardReportArgs) {
	dashboardReports(args: $args) {
		dashboardReportId: id
		title
	}
}`

export const dashboardReportQuery = `#graphql
query LIB_reportQuery($dashboardReportId: Int!, $chartArgs: DashboardReportChartsArgs) {
	dashboardReport(id: $dashboardReportId) {
		dashboardReportId: id
		title
		ownerId
		autoRefreshInterval
		parameterValues {
			... on DashboardReportDateRangeParameter {
				name
				type
				title
				default
				value
				dates {
					from
					to
				}
			}
			... on DashboardReportDateParameter {
				name
				type
				title
				default
				value
			}
			... on DashboardReportSelectionParameter {
				name
				type
				title
				default
				value
				optionList {
					id
					name
					rawSql
					fromQuery
				}
			}
			... on DashboardReportSessionParameter {
				name
				type
				title
				default
				value
				}
			... on DashboardReportSiteSelectionParameter {
				name
				type
				title
				default
				value
				permission
				optionList {
					id
					name
					fromQuery
				}
			}
		}
		charts(args: $chartArgs) {
			chartId: id
			name
			title
			rank
			json: jsonCombined
			supertype
			showChart: display
		}
	}
}`
