<script lang="ts">
	import type { SearchModule, Module, SearchField, SmartSearch } from 'utility/search-fields'

	import Pin from 'components/Pin.svelte'
	import Input from '@isoftdata/svelte-input'
	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import { createEventDispatcher } from 'svelte'
	import EditModeControls from 'components/EditModeControls.svelte'

	export let inEditMode: boolean
	export let keyword: string = ''
	export let searchField: SearchField
	export let disabled: boolean = false
	export let showModuleModal: boolean = false
	export let searchModules: SearchModule[] = []
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchModuleIds: Array<Module['id']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let selectedSearchModulesMap: Map<Module['id'], SearchModule> = new Map()

	const dispatch = createEventDispatcher<{ change: SmartSearch }>()
	let disableInput: boolean = false

	function toggleSearchModuleSelection(toggledSearchModuleId: Module['id']) {
		if (!selectedSearchModuleIds.includes(toggledSearchModuleId)) {
			//Add
			selectedSearchModuleIds = [...selectedSearchModuleIds, toggledSearchModuleId]
		} else {
			//Remove
			selectedSearchModuleIds = selectedSearchModuleIds.filter(moduleId => moduleId !== toggledSearchModuleId)
		}
		dispatch('change', {
			keyword,
			moduleIds: selectedSearchModuleIds,
		})
	}

	$: disableInput = disabled || inEditMode
	$: selectedSearchModulesMap = new Map(searchModules.filter(searchModule => selectedSearchModuleIds.includes(searchModule.module.id)).map(searchModule => [searchModule.module.id, searchModule]))
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<Input
		label="Smart Search"
		bind:value={keyword}
		disabled={disableInput}
		on:change={() => {
			//This is a custom event that is emitted by the Input component
			dispatch('change', { keyword, moduleIds: selectedSearchModuleIds })
		}}
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
			<small
				>Using:
				{#if selectedSearchModuleIds.length === searchModules.length}
					<span class="badge badge-primary mr-1">All {searchModules.length} Modules</span>
				{:else if selectedSearchModuleIds.length === 0}
					<span class="badge badge-danger mr-1">None</span>
				{:else}
					{#each selectedSearchModuleIds as moduleId}
						<span class="badge badge-primary mr-1">{selectedSearchModulesMap.get(moduleId)?.module.name ?? ''}</span>
					{/each}
				{/if}
			</small>
		</svelte:fragment>
		<Button
			slot="append"
			iconClass="pencil"
			class="input-group-append"
			outline
			{disabled}
			on:click={() => {
				showModuleModal = true
				return
			}}>Modules</Button
		>
	</Input>

	<Modal
		title="Select Search Modules"
		bind:show={showModuleModal}
		cancelShown={false}
		on:confirm={() => (showModuleModal = false)}
		on:close={() => (showModuleModal = false)}
		confirmButtonText="Done"
	>
		<div
			class="list-group"
			style="max-height: 60vh; overflow-y: auto;"
		>
			{#each searchModules as searchModule}
				<button
					class="list-group-item list-group-item-action py-2 px-3"
					class:active={selectedSearchModulesMap.has(searchModule.module.id)}
					on:click={() => toggleSearchModuleSelection(searchModule.module.id)}
				>
					{searchModule.module.name}
				</button>
			{/each}
		</div>

		<svelte:fragment slot="modalFooter">
			<Button
				size="sm"
				disabled={selectedSearchModuleIds.length === searchModules.length}
				outline
				on:click={() => {
					selectedSearchModuleIds = searchModules.map(searchModule => searchModule.module.id)
				}}>Select All</Button
			>
			<Button
				size="sm"
				disabled={!selectedSearchModuleIds.length}
				outline
				on:click={() => {
					selectedSearchModuleIds = []
				}}>Select None</Button
			>
		</svelte:fragment>
	</Modal>
</EditModeControls>
