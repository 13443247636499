export default {
    "name": "InventoryOptionValues",
    "kind": "HoudiniFragment",
    "hash": "09717e20f08fae6d5c3fb213a8635229cdf224d9022a4b39aca6a6db08dd00fb",

    "raw": `fragment InventoryOptionValues on Inventory {
  inventoryOptions: optionValues {
    ...InventoryOptionValueData
    id
    inventoryId
    optionId
  }
  id
  __typename
}

fragment InventoryOptionValueData on InventoryOptionValue {
  inventoryOptionValueId: id
  optionId
  value
  option {
    id
    name
    defaultChoice
    required
    rank
    dataType
    public
    manufacturer {
      id
      name
    }
    model {
      id
      name
    }
    category {
      id
      name
    }
    inventoryType {
      id
    }
    choices {
      default
      label
      rank
      id
    }
  }
  id
  inventoryId
  __typename
}
`,

    "rootType": "Inventory",

    "selection": {
        "fields": {
            "inventoryOptions": {
                "type": "InventoryOptionValue",
                "keyRaw": "inventoryOptions",

                "selection": {
                    "fields": {
                        "inventoryOptionValueId": {
                            "type": "Int",
                            "keyRaw": "inventoryOptionValueId",
                            "visible": true
                        },

                        "optionId": {
                            "type": "Int",
                            "keyRaw": "optionId",
                            "visible": true
                        },

                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "nullable": true,
                            "visible": true
                        },

                        "option": {
                            "type": "InventoryOption",
                            "keyRaw": "option",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "defaultChoice": {
                                        "type": "String",
                                        "keyRaw": "defaultChoice",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "required": {
                                        "type": "Boolean",
                                        "keyRaw": "required",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "dataType": {
                                        "type": "DataTypeEnum",
                                        "keyRaw": "dataType",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "category": {
                                        "type": "Category",
                                        "keyRaw": "category",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "choices": {
                                        "type": "InventoryOptionChoice",
                                        "keyRaw": "choices",

                                        "selection": {
                                            "fields": {
                                                "default": {
                                                    "type": "Boolean",
                                                    "keyRaw": "default",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "Int",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "inventoryId": {
                            "type": "Int",
                            "keyRaw": "inventoryId",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "InventoryOptionValueData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "id": {
                "type": "UInt",
                "keyRaw": "id",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=cf57aa20bc4208970e2e764de3f7b09da878bfb9da8fa430cfe5a6eef1446f25";