import { inventoryReturnData, vehicleInventoryReturnData, shippingDimensions, defaultShippingDimensions } from '../fragments.js'

export const inventoryTypesQuery = `#graphql
query InventoryTypes($active: Boolean) {
	inventoryTypeList: inventoryTypes(active: $active)
	{
		inventoryTypeId:id
		typeSetId:setId
		name
		vehicle
		categories{ id name description }
		partManufacturers{ id name code }
		requireSerialization
	}
}`

export const storeLocations = `#graphql
query LIB_storeLocations($storeId: Int) {
	locations(storeId: $storeId, allowInventory: true) {
		id
		name
		description
	}
}`

/*const vehicleAttachments = `#graphql
query LIB_vehicleAttachments($vehicleId: Int!	) {
	vehicle(id: $vehicleId) {
	attachments {
		id rank public
		file {
		id name url path host
		}
	}
	}
}`*/

export const vehicleAttachments = `#graphql
query LIB_vehicleAttachments($vehicleId: Int!) {
	vehicle(id: $vehicleId) {
		attachments {
			fileId: id
			rank
			public
			file {
				id
				name
				path: url
				type
				size
				mimeType: mimetype
				extension
				updated
				createdDate: created
			}
		}
	}
}`

export const itemAttachments = `#graphql
query LIB_itemAttachments($innodbInventoryid: UInt! ) {
	inventory(id: $innodbInventoryid) {
		attachments {
			fileId: id
			rank
			public
			file {
				id
				name
				path: url
				type
				size
				mimeType: mimetype
				extension
				updated
				createdDate: created
			}
		}
	}
}`

export const vehicleTopImage = `#graphql
query LIB_vehicleTopImage($vehicleId: Int!	) {
	vehicle(id: $vehicleId) {
		topImage {
			id
			name
			path
			host
			url
		}
	}
}`

export const updateVehicleAttachment = `#graphql
mutation LIB_UpdateVehicleAttachment(
	$data: VehicleAttachmentUpdate!
) {
	updateVehicleAttachment(input: $data) {
		fileId: id
		rank
		public
		file {
			id
			name
			path: url
			type
			size
			mimeType: mimetype
			extension
			updated
			createdDate: created
		}
	}
}`

export const updateVehicleAttachments = `#graphql
mutation TEST_UpdateVehicleAttachments(
	$data: [VehicleAttachmentUpdate!]!
) {
	updateVehicleAttachments(input: $data) {
		fileId: id
		rank
		public
		file {
			id
			name
			path: url
			type
			size
			mimeType: mimetype
			extension
			updated
			createdDate: created
		}
	}
}`

export const updateItemAttachments = `#graphql
mutation LIB_updateItemAttachments(
	$data: [BulkItemAttachmentUpdate!]!
	) {
	updateItemAttachments(input: $data) {
		fileId: id
		rank
		public
		file {
			id
			name
			path: url
			type
			size
			mimeType: mimetype
			extension
			updated
			createdDate: created
		}
	}
}`

export const deleteVehicleAttachment = `#graphql
mutation LIB_deleteVehicleAttachment($id: UInt!) {	deleteVehicleAttachment(id: $id)}`
export const deleteVehicleAttachments = `#graphql
mutation TEST_deleteVehicleAttachments ($ids: [UInt!]!) {	deleteVehicleAttachments(ids: $ids)}`

export const deleteItemAttachments = `#graphql
mutation TEST_deleteItemAttachments ($ids: [UInt!]!) {	deleteItemAttachments(ids: $ids)}`

export const inventoryConditions = `#graphql
query LIB_inventoryConditions{inventoryConditions}`

export const vehicleTypesQuery = `#graphql
query LIB_vehicleTypes {
	vehicleTypes: inventoryTypes(vehicle: true) {
		id
		name
		categories {
			id
			name
		}
	}
}`

// For now, please add any new permissions to client/commmon/Permissions.gql as well until we fully transition away from this file.
export const permissionPartialQ = `#graphql
permissions {
	WEB_CAN_LOGIN
	VEHICLES_CAN_EDIT_STOCK_NUMBER
	VEHICLES_CAN_EDIT_STATUS
	VEHICLES_CAN_EDIT_BASIC
	VEHICLES_CAN_EDIT_ATTACHMENTS
	VEHICLES_CAN_EDIT_BIDS
	VEHICLES_CAN_EDIT_NON_BID_UNITS
	DASHBOARD_REPORT_VIEW
	DASHBOARD_REPORT_EDIT_OWN
	DASHBOARD_REPORT_EDIT_ALL
	PARTS_CAN_CLONE_PARTS
    PARTS_CAN_CREATE_CONDITIONS
    PARTS_CAN_EDIT_ATTACHMENTS
    PARTS_CAN_EDIT_COST
    PARTS_CAN_EDIT_GLCATEGORY
    PARTS_CAN_EDIT_INFO
    PARTS_CAN_EDIT_LOCATION
    PARTS_CAN_EDIT_PRICES
    PARTS_CAN_EDIT_QUANTITY
    PARTS_CAN_EDIT_STATUS
    PARTS_CAN_EDIT_STOCKING_DATA
    PARTS_CAN_REPLICATE_PARTS
    PARTS_CAN_SAVE_PARTS
    PARTS_CAN_VIEW_COST
    PARTS_CAN_VIEW_PARTS
    PARTS_EDIT_RESTRICTED_PART_TYPE_PRICES
    PARTS_EDIT_RESTRICTED_PURCHASE_VENDOR
	PART_CONFIGURATION_CAN_EDIT_PART_TYPES
	CONFIGURATION_CAN_CONFIGURE_STORES
	GLOBAL_USER_ADMINISTRATOR
}`

export const vehiclePermissions = `#graphql
query vehiclePermissions {
	session {
		${permissionPartialQ}
	}
}`

export const fieldAccessQuery = `#graphql
query LIB_fieldAccessQuery{
	session {
		fieldAccess {
			inventory {
				cost {
					read
					update
				}
			}
		}
	}
}`

export const storeQuery = `#graphql
query LIB_storeQuery {
	stores {
		id
		name
		code
		phone
		addressService {
			service
			apiKey
		}
		taxService {
			apiKey
			service
		}
	}
}`

export const storesQueryForStoresConfig = `#graphql
query LIB_storesQueryForStoresConfig {
	stores {
		id
		address
		city
		code
		country
		fax
		invoiceMemo
		name
		phone
		private
		remittanceAddress
		remittanceCity
		remittanceCountry
		remittancePhone
		remittanceState
		remittanceZip
		state
		webAddress
		zip
		addressService {
			service
			apiKey
		}
		taxService {
			apiKey
			service
		}
	}
}
`

export const updateStoreMutation = `#graphql
mutation LIB_updatestore($input: StoreUpdate!) {
	updateStore(input: $input) {
		id
	}
}
`
export const statesQuery = `#graphql
query LIB_states {
	states {
		stateAbbreviation
		stateName
	}
}
`
export const autofillByZipQuery = `#graphql
	query LIB_autofillByZip($zipcode: String!) {
		autofillByZip(zipcode: $zipcode) {
			city
			stateAbbreviation
			country
		}
	}
`

export const storesForLoginQuery = `#graphql
query LIB_storesForLogin {
	storesForLogin {
		id
		code
		name
	}
}`

export const userStatusListQuery = `#graphql
query LIB_userStatusListQuery
{
	userPartStatusList: userStatuses (type: INVENTORY) {
		type
		status
		trigger
		partStatus
		description
	}
	userVehicleStatusList: userStatuses (type: VEHICLE) {
		type
		status
		trigger
		partStatus
		description
	}
}`

export const configurationSettingsQuery = `#graphql
query LIB_configurationSettingsQuery {
	settingValuesForConfig {
		id
		defaultValue
		description
		category: location
		name
		scope
		type: settingType
		value
	}
}
`

export const settingValuesQuery = `#graphql
query LIB_settingValues
{
	settingValues {
		vehicle {
			userStatusRequired
			minVinLength
			mileageRequired
			flexField4Required
			flexField3Required
			flexField2Required
			flexField1Required
			conditionRequired
		}
		inventory {
			defaultNonReplenishablePartsArePublic
			categoryEditable
			categoryRequired
			categoryVisible
			conditionEditable
			conditionRequired
			conditionVisible
			coreCostEditable
			coreCostRequired
			coreCostVisible
			costEditable
			costRequired
			costVisible
			defaultDaysToReturn
			defaultDaysToReturnCore
			defaultDaysToReturnCoreToVendor
			defaultDaysToReturnToVendor
			defaultLocationName
      		defaultQuantityForMisc
      		defaultQuantityForReplenishable
      		defaultQuantityForStandard
			defaultReplenishablePartsArePublic
			defaultReturnable
			defaultGlCategoryId
			defaultNonReplenishablePartsArePublic
			defaultReturnableToVendor
			distributorCorePriceEditable
			distributorCorePriceRequired
			distributorCorePriceVisible
			distributorPriceEditable
			distributorPriceRequired
			distributorPriceVisible
			glCategoryEditable
			glCategoryRequired
			glCategoryVisible
			inventoryTypeEditable
			inventoryTypeRequired
			inventoryTypeVisible
			jobberCorePriceEditable
			jobberCorePriceRequired
			jobberCorePriceVisible
			jobberPriceEditable
			jobberPriceRequired
			jobberPriceVisible
			listPriceEditable
			listPriceRequired
			listPriceVisible
			oemNumberEditable
			oemNumberRequired
			oemNumberVisible
			retailPriceEditable
			retailPriceRequired
			retailPriceVisible
			retailCorePriceEditable
			retailCorePriceRequired
			retailCorePriceVisible
			sideEditable
			sideRequired
			sideVisible
			userStatusEditable
			userStatusRequired
			userStatusVisible
			varianceLocationName
			vendorEditable
			vendorRequired
			vendorVisible
			wholesalePriceEditable
			wholesalePriceRequired
			wholesalePriceVisible
			wholesaleCorePriceEditable
			wholesaleCorePriceRequired
			wholesaleCorePriceVisible
		}
		fileAttachments {
			maxImageHeight
			maxImageWidth
		}
		location {
			enforceLocationHierarchy
		}
	}
}`

export const vehicleManufacturers = `#graphql
query LIB_vehicleManufacturers {
	makes:vehicleManufacturers {
		id
		make:name
	}
}`

// vehicleMakes replaces vehicleManufacturers query because of LKQ old version
export const vehicleMakes = `#graphql
query LIB_vehicleMakes {
	vehicleMakes
}`

export const vehicleModelsByMake = `#graphql
query LIB_vehicleModels($make:String){
	vehicleModels(make: $make) {
		name
		active
	}
}`

export const modelsbymakeandinventorytype = `#graphql
query LIB_modelsbymakeandinventorytype($make:String,$inventoryTypeId: Int){
	makemodels:manufacturers(search:$make) {
		id make:name
		models ( inventoryTypeId: $inventoryTypeId) {
			name modelId:id inventoryTypeId active
			${defaultShippingDimensions}
		}
	}
}`

export const modelsQuery = `#graphql
query Models($manufacturerId: Int!, $inventoryTypeId: Int) {
	manufacturer(id: $manufacturerId) {
		  models(inventoryTypeId: $inventoryTypeId) {
		id
		name
		active
		defaultShippingDimensions {
		  height
		  length
		  measurementUnit
		  weight
		  weightUnit
		  width
		}
	   inventoryTypeId
	  }
	}
}`

export const modelsbyManufacturerAndInventorytype = `#graphql
query LIB_modelsbyManufacturerAndInventorytype($manuId:Int!,$inventoryTypeId: Int){ makemodels:manufacturer(id:$manuId) { id make:name models ( inventoryTypeId: $inventoryTypeId) { name modelId:id inventoryTypeId active ${defaultShippingDimensions} } } }`

/*
type VehicleUpdate {
id: UInt!
category: String
description: String
dismantled: EnumBoolean
notes: String
stockNumber: String
vin: String
}
*/
export const updateVehicleMutation = `#graphql
mutation LIB_updateVehicle($VehicleUpdateData: VehicleUpdate!) {
	updateVehicle( input: $VehicleUpdateData) {
		id category description dismantled notes stockNumber vin
	}
}`

export const createVehicleMutation = `#graphql
mutation createVehicle( $VehicleData: NewVehicle!) {
	vehicle:createVehicle(input:$VehicleData)
	{
		id
	}
}`

export const vehiclequery = `#graphql
query LIB_vehicledata($vehicleId: Int!) {
	vehicle(id: $vehicleId) {
		id
		vin
		manufacturer {
			name
		}
		model {
			name
		}
		year
		mileage
		description
		notes
		stockNumber
		location
		storeId
		status
		dismantled
		bodyStyle
		category
		externalColor
		externalColorCode
		internalColor
		internalColorCode
		userStatus
		titleNumber
		titleStatus
		titleState
		titleType
		claimNumber
		purchasedFrom
		sellerContact
		sellerPhone
		sellerInfo
		topImage {
			id
			name
			path
			host
			url
		}
		dismantler {
			id
			name
		}
		teardownDate
		receivedDate
		wholeUnit {
			inventoryType {
				inventoryTypeId: id
				setTypeId: setId
				categories {
					id
					name
				}
			}
		}
	}
}`

export const vehiclebreakdowntemplatequery = `#graphql
query LIB_vehiclebreakdowntemplatequery($vehicleId: Int!) {
	vehicle(id: $vehicleId) {
		wholeUnit {
			inventoryType {
				breakdownTemplates {
					breakdownTemplateId: id
					default
					description
					name
					components {
						inventoryTypeId
						quantity
						rank
						integral
					}
				}
			}
		}
	}
}`

export const vehicleinventoryquery = `#graphql
query LIB_vehicleinventory($vehicleId: Int!	) {
	vehicle(id: $vehicleId) {
		inventories {
			${vehicleInventoryReturnData}
		}
	}
}`

export const closeSession = `#graphql
mutation closeSession{ closeSession }`

export const vehicleSearch = `#graphql
query LIB_vehicleSearch($searchString:String = "", $stores:[Int!], $available:Boolean=true){
	vehicles(available:$available,stores:$stores,search:$searchString) {
		stockNumber
		id
		vin
		make
		model
		year
		mileage
		location
		storeId
		dismantled
		status
		receivedDate
		teardownDate
		userStatus
	}
}`

export const vehicleSearchPaged = `#graphql
query DEV_vehicleSearch(
	$searchString: String = ""
	$stores: [Int!]
	$pagination: PaginationOptions
	$orderBy: [VehicleOrderBy!]
	$vehiclesFilterBy: VehicleFilterBy
	) {
	vehicles(
		search: $searchString
		stores: $stores
		pagination: $pagination
		orderBy: $orderBy
		filterBy: $vehiclesFilterBy
	) {
		pageInfo {
			pageSize
			pageNumber
			totalPages
		}
		totalItems
		items {
			dismantled
			id
			location
			make
			category
			model
			status
			stockNumber
			storeId
			vin
			year
			userStatus
			wholeUnit {
				inventoryType {
					id
					name
				}
			}
			dateEntered
			lastChanged
			purchaseDate
			receivedDate
			teardownDate
			topImage {
				id
				url
				path: url
				name
			}
			imageCount
			attachments {
				id
				public
				rank
				file {
					id
					name
					path: url
					mimetype
				}
			}
			componentCount
		}
	}
}`

export const createBreakdownTemplate =	`#graphql
mutation createBreakdownTemplate( $breakdowndata: NewBreakdownTemplate!) {
	createBreakdownTemplate(input:$breakdowndata)
	{
		name
		inventoryTypeId
		id
		description
		default
		components {
			inventoryTypeId
			rank
			quantity
			integral
		}
	}
}`

export const updateBreakdownTemplate =	`#graphql
mutation updateBreakdownTemplate( $id: Int!, $breakdowndata:	BreakdownTemplateUpdate!) {
	updateBreakdownTemplate(id:$id, input:$breakdowndata)
	{
		name
		inventoryTypeId
		id
		description
		default
		components {
			inventoryTypeId
			rank
			quantity
			integral
		}
	}
}`

export const modelCategoryPricingQuery = `#graphql
query LIB_modelCategoryPricing($modelId: Int!,	$categoryId: Int) {
	modelCategoryPricing(modelId: $modelId, categoryId: $categoryId) {
		modelId
		category
		notes
		pricing {
			jobber
			jobberCore
			list
			retail
			retailCore
			wholesale
			wholesaleCore
		}
	}
}`

/*
{
	"updateInventorySerialInput": {
		"id": 3715,
		"number": "changed"
	}
}*/
export const updateInventorySerial = `#graphql
mutation updateInventorySerial($updateInventorySerialInput: InventorySerialUpdate!) {
	updateInventorySerial(input: $updateInventorySerialInput) {
		id
		dateEntered
		number
	}
}`

/*
{
	"createInventorySerialInput": {
		"inventoryId": 24190062,
		"number": "serialnumbertest"
	}
}	*/
export const createInventorySerial = `#graphql
mutation createInventorySerial($createInventorySerialInput: NewInventorySerial!) {
	createInventorySerial(input: $createInventorySerialInput) {
		id
		dateEntered
		number
		location {
			... on Location {
				id
				name
			}
			... on VirtualLocation {
				name
			}
		}
	}
}`

export const inventoryQuery = `#graphql
query LIB_inventoryQuery($innodbInventoryid: UInt!, $inventoryTypeId: Int,$typeSetId: Int, $serialFilter: InventorySerialFilter) {
	inventory(id: $innodbInventoryid) {
		innodbInventoryid: id
		inventoryId
		storeId
		tagNumber
		inventoryTypeId
		cost
		wholesalePrice
		retailPrice
		jobberPrice
		retailCorePrice
		side
		status
		description
		notes
		quantity
		dateEntered
		dateModified
		vehicleId
		vehicleVin
		vehicleMake
		vehicleModel
		vehicleYear
		oemNumber
		condition
		userStatus
		public
		${shippingDimensions}
		parentModel {
			id
			name
		}
		parentManufacturer {
			id
			name
			models(inventoryTypeId:$typeSetId) {
				name
				modelId:id
				inventoryTypeId
				active
			}
		}
		model {
			id
			name
		}
		manufacturer {
			id
			name
			models(inventoryTypeId:$inventoryTypeId) {
				name
				modelId:id
				inventoryTypeId
				active
				${defaultShippingDimensions}
			}
		}
		category {
			id
			name
			description
		}
		attachments {
			fileId: id
			rank
			public
			file {
				id
				name
				path: url
				type
				size
				mimeType: mimetype
				extension
				updated
				createdDate: created
			}
		}
		typeField1 {
			label
			data
		}
		typeField2 {
			label
			data
		}
		typeField3 {
			label
			data
		}
		typeField4 {
			label
			data
		}
		locations {
			id
			name
			rank
			quantity
			holdQuantity
			permanent
		}
		serialized
		serials(filter: $serialFilter) {
			id
			dateEntered
			location {
				... on Location {
					id
					name
				}
				... on VirtualLocation {
					name
				}
			}
			number
		}
		modelId
		manufacturerId
	}
}`

export const newInventoryMutation =	`#graphql
mutation LIB_newInventory($part: NewStandardInventory!, $serialFilter: InventorySerialFilter) {
	part: createStandardInventory(input: $part) {
		${inventoryReturnData}
	}
}`

export const newMiscInventoryMutation = `#graphql
mutation CreateMiscInventory($part: NewMiscInventory!, $serialFilter: InventorySerialFilter) {
  part: createMiscInventory(input: $part) {
    ${inventoryReturnData}
  }
}`

export const newReplenishableInventoryMutation = `#graphql
mutation CreateReplenishableInventory($part: NewReplenishableInventory!, $serialFilter: InventorySerialFilter) {
  part: createReplenishableInventory(input: $part) {
    ${inventoryReturnData}
  }
}`

export const inventoryQueryById = `#graphql
query inventoryQuery($innodbInventoryid: UInt!, $serialFilter: InventorySerialFilter) {
	inventory(id: $innodbInventoryid) {
		${inventoryReturnData}
	}
}`

export const appraisal = `#graphql
query AppraisalQuery($inputs: [AppraisalInput!]!) {
  appraisal(inputs: $inputs) {
    value
    relevance
    min
    max
    itemsAffected
    deviance
  }
}
`

export const inventoryOptionFields = `#graphql
id
name
rank
required
public
dataType
choices(suppressFixedChoices: $suppressFixedChoices, suppressQueryChoices: $suppressQueryChoices, queryOverride: $queryOverride) {
	id
	isDefault: default
	label
	rank
}
manufacturer {
	id
	name
}
model {
	id
	name
}
category {
	id
	name
}
inventoryType {
    id
	name
}
defaultChoice
choiceQuery
`

export const inventoryOptionsQuery = `#graphql
query InventoryOptions(
	$inventoryTypeId: [Int!]
	$manufacturerId: [Int!]
	$modelId: [Int!]
	$categoryName: [String!]
	$suppressFixedChoices: Boolean
	$suppressQueryChoices: Boolean
	$queryOverride: String
) {
  inventoryOptions(inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId, categoryName: $categoryName) {
    ${inventoryOptionFields}
  }
}
`

export const inventoryTypeFieldsQuery = `#graphql
query TypeFields($inventoryTypeId: Int!) {
  inventoryType(id: $inventoryTypeId) {
	id
	setId
    typeData1History
    typeData2History
    typeData3History
    typeData4History
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
}
`

export const partManufacturersQuery = `#graphql
query PartManufacturers($inventoryTypeId: Int!) {
	inventoryType(id: $inventoryTypeId) {
		partManufacturers {
			id
			name
		}
	}
}`

export const partModelsQuery = `#graphql
query Models($manufacturerId: Int!, $active: Boolean, $inventoryTypeId: Int) {
	manufacturer(id: $manufacturerId) {
		models(active: $active, inventoryTypeId: $inventoryTypeId) {
			id
			name
		}
	}
}`

export const categoriesQuery = `#graphql
query Categories($inventoryTypeId: Int!) {
	inventoryType(id: $inventoryTypeId) {
		categories {
			name
			id
		}
	}
}`

export const vendorsQuery = `#graphql
query Vendors($pagination: PaginationOptions) {
  vendors(pagination: $pagination) {
    items {
      id
      active
      code
      companyName
      contactName
      stockVendor
    }
  }
}
`
export const typeDataHistory = `#graphql
query typeDataHistory($inventoryTypeId: Int!) {
	typeDataHistory:inventoryType(id: $inventoryTypeId) {
		typeData4History
		typeData3History
		typeData2History
		typeData1History
	}
}`

export const sessionInformation = `#graphql
query Query {
	session {
		status
		storeId
		userAccount {
		name
		driver
		salesPerson
		worker
		workEmail
		recoveryEmail
		firstName
		lastName
		authorizedStores {
			id
			name
			code
		}
		}
	}
}`

export const inventorySearchQuery = `#graphql
query PartSearch(
	$pagination: PaginationOptions
	$orderBy: [InventorySort!]
	$filter: InventoryFilter
	) {
	inventories(
		pagination: $pagination
		orderBy: $orderBy
		filter: $filter
	) {
		pageInfo {
		pageNumber
		pageSize
		totalPages
		}
		totalItems
		items {
			id
			storeId
			attachments {
				id
				public
				rank
				file {
					id
					name
					path: url
					mimetype
				}
			}
			tagNumber
			inventoryType {
				name
			}
			manufacturer {
				id
				name
			}
			model {
				id
				name
			}
			category {
				id
				name
			}
			# Tracking Number?
			vehicleYear
			vehicleMake
			vehicleModel
			bodyStyle
			# UPC?
			oemNumber
			locations {
				id
				name
				quantity
				holdQuantity
			}
			wholesalePrice
			retailCorePrice
			retailPrice
			quantity
			description
			notes
			condition
			sku
			side
			vehicle {
				bodyStyle
				externalColor
				externalColorCode
				mileage
			}
			typeField1 {
				label
				data
			}
			typeField2 {
				data
				label
			}
			typeField3 {
				data
				label
			}
			typeField4 {
				data
				label
			}
			status
			userStatus
			dateEntered
			stockCategory
		}
	}
}` // TODO: Filter by what the user has edit permissions for

export const dashboardReportsForConfiguration = `#graphql
query ReportsForConfigQuery($args: DashboardReportArgs) {
	dashboardReports(args: $args) {
		dashboardReportId: id
		reportName: name
		reportTitle: title
		shareId
		shareType
		ownerId
		autoRefreshInterval
		parameters: parameterValues {
			... on DashboardReportDateParameter {
				name
				type
				title
				default
			}
			... on DashboardReportDateRangeParameter {
				name
				type
				title
				default
			}
			... on DashboardReportSelectionParameter {
				name
				type
				title
				default
				optionList {
					id
					name
					fromQuery
					rawSql
				}
			}
			... on DashboardReportSessionParameter {
				name
				type
				title
				default
				value
				}
			... on DashboardReportSiteSelectionParameter {
				name
				type
				title
				default
				value
				permission
				optionList {
					id
					name
					fromQuery
				}
			}
		}
	}
}`

export const groupsQuery = `#graphql
query GroupsQuery {
	groups {
		groupId: id
		name
		groupPermissions: permissions {
			permissionId: id
			value: level
		}
	}
}`

export const dashboardUserAccountsQuery = `#graphql
query DashboardUserAccountsQuery($status: UserAccountStatusEnum) {
	userAccounts(status: $status) {
		userAccountId: id
		firstName
		lastName
		userName: name
	}
}`

export const dashboardAddChartToReportMutation = `#graphql
mutation Mutation($input: AddChartToDashboardReportInput!) {
	addChartToDashboardReport(input: $input) {
		chartId: id
		display
		inReport
		rank
		supertype
		title
		chartType: type
	}
}`

export const dashboardRemoveChartFromReportMutation = `#graphql
mutation Mutation($reportId: Int!, $chartId: Int!) {
	removeChartFromDashboardReport(reportId: $reportId, chartId: $chartId) {
		chartId: id
		display
		inReport
		rank
		supertype
		title
		chartType: type
	}
}`

export const dashboardUpdateChartRankMutation = `#graphql
mutation UpdateDashboardChartRank($input: UpdateDashboardReportInput) {
	updateDashboardReport(input: $input) {
		chartMembership {
			chartId: id
			title
			display
			inReport
			supertype
			chartType: type
		}
		chartEntries {
			id
			rank
			chart {
				id
			}
		}
	}
}`

export const deleteDashboardReportMutation = `#graphql
mutation DeleteDashboardReportMutation($dashboardReportId: Int!) {
	deleteDashboardReport(id: $dashboardReportId) {
		id
		success
		message
	}
}`

export const glCategoriesQuery = `#graphql
query GlCategoriesQuery {
	glCategories {
		id
		name
		# maxPercentOfPrice
		# miscPercentOfPrice
		# percentOfPrice
	}
}`

export const userAccountsQuery = `#graphql
	query UserAccounts {
		userAccounts {
			id
			name
			status
			lastAccess
			authorizedStores {
				id
			}
		}
	}
`

const userAccountData = `#graphql
	id
	name
	firstName
	lastName
	status
	lockNotes
	workEmail
	recoveryEmail
	driver
	salesPerson
	worker
	lastPasswordResetDate
	authorizedStores {
		id
		name
		code
	}
	groups {
		id
		name
	}
	highestAccessPermissions {
		id
		level
	}
	highestGroupPermissions {
		id
		level
	}
	userPermissions {
		id
		level
	}
	wageRate {
		id
		name
		code
		overTimeHours
		overTimeMultiplier
		overTimeType
		startDate
		wage
		glCategoryId
    }
`

export const userAccountQuery = `#graphql
	query UserAccount($userAccountId: Int!) {
		userAccount(id: $userAccountId) {
			${userAccountData}
		}
	}
`

export const createUserAccountMutation = `#graphql
	mutation CreateUserAccount($input: NewUserAccount!) {
  		createUserAccount(input: $input) {
			${userAccountData}
		}
	}
`

export const updateUserAccountMutation = `#graphql
	mutation UpdateUserAccount($input: UserAccountUpdate!) {
  		updateUserAccount(input: $input) {
			${userAccountData}
		}
	}
`

export const permissionsQuery = `#graphql
	query Permissions {
		permissions {
			id
			displayName
			description
			codeName
			category
		}
	}
`

export const wageRatesQuery = `#graphql
	query WageRates {
		wageRates {
			id
			name
			overTimeHours
			overTimeMultiplier
			overTimeType
			startDate
			wage
			code
			glCategoryId
		}
	}
`

// Created this query to have a more semantic query/name since I only need to know this setting value
export const adminSetPasswordSettingValueQuery = `#graphql
	query SettingValues {
		settingValues {
			security {
				administratorsCanSetOtherUsersPasswords
			}
		}
	}
`
