import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/Vendor'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class VendorStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "VendorStore",
			variables: true,
		})
	}
}

export async function load_Vendor(params) {
	await initClient()

	const store = new VendorStore()

	await store.fetch(params)

	return {
		Vendor: store,
	}
}
