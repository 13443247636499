<script lang="ts">
	import type { NumberRange } from 'utility/search-fields.js'

	import Pin from 'components/Pin.svelte'
	import Input from '@isoftdata/svelte-input'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher<{
		change: NumberRange
	}>()

	export let label: string = ''
	export let to: number | null = null
	export let disabled: boolean = false
	export let isPinned: boolean = false
	export let from: number | null = null
	export let id: string = ''
</script>

<label
	for={id}
	class="col-form-label pb-0"
	>{label} <Pin {isPinned} />
</label>
<div class="form-row">
	<div class="col-12 col-md-6">
		<Input
			{id}
			label="From"
			bind:value={from}
			type="number"
			inputmode="decimal"
			on:change={() => {
				if (from && !to) {
					to = from
				}
				dispatch('change', { from, to })
			}}
			{disabled}
		/>
	</div>

	<div class="col-12 col-md-6">
		<Input
			bind:value={to}
			label="To"
			type="number"
			inputmode="decimal"
			on:change={() => dispatch('change', { from, to })}
			{disabled}
		/>
	</div>
</div>
