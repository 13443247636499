import { vehicleAttachments, updateVehicleAttachments, deleteVehicleAttachments } from 'common/graphql-queries'
import makeAttachmentComponent from '@isoftdata/attachment'
import template from './attachment.html'

export default function(appContext) {
	const mediator = appContext.mediator

	appContext.stateRouter.addState({
		name: 'app.vehicle.attachment',
		route: 'attachment',
		querystringParameters: [ 'vehicleId', 'storeId' ],
		template: {
			template,
			twoway: false,
			components: {
				itAttachment: makeAttachmentComponent({
					saveFileAndAssociate: async(newfile, association) => {
						const returnFile = await mediator.publish('uploadFileAttachment', { file: newfile.File, relationId: association, relation: 'vehicle', rank: newfile.rank })

						if (returnFile.errors && Array.isArray(returnFile.errors)) {
							// Just in case the returnFile has errors, but we didn't throw
							throw returnFile.errors[0]
						}

						// We should only get here if we don't throw, which *should* mean we know `data` is available. See `handleError` for when the request throws.
						return {
							fileId: returnFile.data.createVehicleAttachment.fileId,
							public: returnFile.data.createVehicleAttachment.public,
							rank: returnFile.data.createVehicleAttachment.rank,
							...returnFile.data.createVehicleAttachment.file,
						}
					},
					updatePublicFlag: async(fileIds, toPublic, association) => {
						let variables = {}
						variables.data = fileIds.map(id => {
							return { id,	public: toPublic	}
						})

						const returnFiles = await mediator.publish('graphqlFetch', updateVehicleAttachments, variables)

						return returnFiles.updateVehicleAttachments.map(item => {
							return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
						})
					},

					setTopRankedFile: async(fileId, association, fileList) => {
						let r = 1
						const data = fileList.map(file => {
							const rank = file.fileId === fileId ? 1 : ++r
							return { id: file.fileId, rank }
						})

						const returnFiles = await mediator.publish('graphqlFetch', updateVehicleAttachments, { data })
						return returnFiles.updateVehicleAttachments.map(item => {
							return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
						})
					},

					deleteFileAssociation: async(fileIds, association) => {
						return await mediator.publish('graphqlFetch', deleteVehicleAttachments, { ids: fileIds })
					},
					handleError: async err => {
						let message = err.message
						if (typeof err === 'object' && err !== null && typeof err.message === 'string') {
							if (err.extensions?.code === 'DUPLICATE_ATTACHMENT') {
								message = 'Error: Duplicate attachments not allowed'
							}
						}
						mediator.publish('showMessage', { heading: 'Error Saving Attachment', message, type: 'danger', time: false })
					},
				}),

			},
			data: {

			},
		},
		async resolve(data, parameters) {
			const vehicleId = parseInt(parameters.vehicleId, 10)
			const res = await mediator.publish('graphqlFetch', vehicleAttachments, { vehicleId })

			return {
				vehicleFiles: res.vehicle.attachments.map(item => {
					return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
				}),
				vehicleId,
			}
		},
		activate(context) {
			const ractive = context.domApi
		},
	})
}
