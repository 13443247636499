export default {
    "name": "Vendor",
    "kind": "HoudiniQuery",
    "hash": "29725532d69e25fce2d611637793d49f58bb7fbe246ece191a7bbe051b07b2b4",

    "raw": `query Vendor($vendorId: UInt!) {
  vendor(id: $vendorId) {
    coreClassHistory
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "vendor": {
                "type": "Vendor",
                "keyRaw": "vendor(id: $vendorId)",

                "selection": {
                    "fields": {
                        "coreClassHistory": {
                            "type": "String",
                            "keyRaw": "coreClassHistory",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "vendorId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=51bf7fb817abad540399e162708d525b917bfa3cce7b4d4dc4a04c5ffded6b9e";