import type { AppContext } from 'types/common'
import type { InventorySort$options, PartSearchResultsData$input, PartSearchResultsData$result } from '$houdini'
import type { Side, NumberRange, SmartSearch, Status, PerPageCount } from 'utility/search-fields'
export type InventoryItem = PartSearchResultsData$result['inventories']['items'][0]

import { graphql, InventorySort } from '$houdini'
import Results from './Results.svelte'
import { getSession } from 'stores/session'

import { buildAdditionalFieldValueFilterArgument, buildInventoryQuestionValueFilterArgument, isValidPerPageCount } from 'utility/search-fields'

const dataQuery = graphql(`
	query PartSearchResultsData($pagination: PaginationOptions, $orderBy: [InventorySort!], $filter: InventoryFilter) {
		inventories(pagination: $pagination, orderBy: $orderBy, filter: $filter) {
			pageInfo {
				pageNumber
				pageSize
				totalPages
			}
			totalItems
			items {
				id
				storeId
				attachments {
					id
					public
					rank
					file {
						id
						name
						path: url
						mimetype
						size
					}
				}
				tagNumber
				inventoryType {
					name
					typeLabel1
					typeLabel2
					typeLabel3
					typeLabel4
				}
				manufacturer {
					id
					name
				}
				model {
					id
					name
				}
				parentManufacturer {
					id
					name
				}
				parentModel {
					id
					name
				}
				category {
					id
					name
				}
				vehicleYear
				vehicleMake
				vehicleModel
				bodyStyle
				oemNumber
				locations {
					id
					name
					quantity
					holdQuantity
				}
				wholesalePrice
				retailCorePrice
				retailPrice
				quantity
				description
				notes
				condition
				sku
				side
				vehicle {
					id
					bodyStyle
					externalColor
					externalColorCode
					mileage
				}
				typeField1 {
					label
					data
				}
				typeField2 {
					data
					label
				}
				typeField3 {
					data
					label
				}
				typeField4 {
					data
					label
				}
				status
				userStatus
				dateEntered
				stockCategory
				messageCount
			}
		}
	}
`)

function getOrderBy(column: string | undefined, direction: string) {
	let orderBy = column

	if (orderBy) {
		if (orderBy === 'attachments.length') {
			orderBy = 'attachmentCount'
		} else if (orderBy === 'category.name') {
			orderBy = 'category'
		} else if (orderBy === 'vehicle.bodyStyle') {
			orderBy = 'bodyStyle'
		} else if (orderBy.includes('typeField')) {
			const split = orderBy.split('.')
			orderBy = split[0]
		} else if (orderBy.includes('.')) {
			const split = orderBy.split('.')
			split[1] = split[1].charAt(0).toUpperCase() + split[1].slice(1)
			orderBy = split.join('')
		}

		return `${orderBy}_${direction === 'DESC' ? 'DESC' : 'ASC'}`
	} else {
		return ''
	}
}

const isValidOrderBy = (orderBy: string): orderBy is InventorySort$options => {
	return Object.keys(InventorySort).includes(orderBy)
}

const jsonParseParameters = new Set(['STORE', 'SMART_SEARCH', 'YEAR_RANGE', 'perPageCount', 'pageNumber', 'ADDITIONAL_FIELDS', 'QA_FIELDS'])

//TODO: remove this? I was going to type the possible asr parameters but i couldn't make ts happy about it
//type Parameters = | 'selectedInventoryId' | 'pageNumber' | 'perPageCount' | 'sortColumn' | 'sortDirection' | 'STORE' | 'INVENTORY_TYPE' | 'ASSEMBLY_MANUFACTURER' | 'ASSEMBLY_MODEL' | 'PART_MANUFACTURER' | 'PART_MODEL' | 'LOCATION' | 'CONDITION' | 'STATUS' | 'USER_STATUS' | 'CATEGORY' | 'SIDE' | 'YEAR_RANGE' | 'VEHICLE_MANUFACTURER' | 'VEHICLE_MODEL' | 'SMART_SEARCH' | 'SIDE' | 'ADDITIONAL_FIELDS' | 'TYPE_FIELD_1' | 'TYPE_FIELD_2' | 'TYPE_FIELD_3' | 'TYPE_FIELD_4' | 'QA_FIELDS'

interface ParsedStateParameters {
	selectedInventoryId?: number
	pageNumber?: number
	perPageCount?: number
	sortColumn?: InventorySort$options
	sortDirection?: 'ASC' | 'DESC'
	STORE?: number[]
	INVENTORY_TYPE?: string
	ASSEMBLY_MANUFACTURER?: string
	ASSEMBLY_MODEL?: string
	PART_MANUFACTURER?: string
	PART_MODEL?: string
	LOCATION?: string
	CONDITION?: string
	STATUS?: Status
	USER_STATUS?: string
	CATEGORY?: string
	SIDE?: Side
	YEAR_RANGE?: NumberRange
	VEHICLE_MANUFACTURER?: string
	VEHICLE_MODEL?: string
	SMART_SEARCH?: SmartSearch
	TAG_NUMBER?: string
	ADDITIONAL_FIELDS?: Record<string, string>
	TYPE_FIELD_1?: string
	TYPE_FIELD_2?: string
	TYPE_FIELD_3?: string
	TYPE_FIELD_4?: string
	QA_FIELDS?: Record<string, string>
}

function getApiFilter(stateParameters: ParsedStateParameters): PartSearchResultsData$input['filter'] {
	const filter: PartSearchResultsData$input['filter'] = {}
	for (const parameterKey in stateParameters) {
		if (parameterKey === 'STORE' && stateParameters.STORE) {
			filter.stores = stateParameters.STORE
		} else if (parameterKey === 'INVENTORY_TYPE' && stateParameters.INVENTORY_TYPE) {
			filter.inventoryTypeId = { eq: parseInt(stateParameters.INVENTORY_TYPE, 10) }
		} else if (parameterKey === 'ASSEMBLY_MANUFACTURER' && stateParameters.ASSEMBLY_MANUFACTURER) {
			filter.assemblyManufacturerId = parseInt(stateParameters.ASSEMBLY_MANUFACTURER, 10)
		} else if (parameterKey === 'ASSEMBLY_MODEL' && stateParameters.ASSEMBLY_MODEL) {
			filter.assemblyModelId = parseInt(stateParameters.ASSEMBLY_MODEL, 10)
		} else if (parameterKey === 'PART_MANUFACTURER' && stateParameters.PART_MANUFACTURER) {
			filter.manufacturerId = parseInt(stateParameters.PART_MANUFACTURER, 10)
		} else if (parameterKey === 'PART_MODEL' && stateParameters.PART_MODEL) {
			filter.modelId = parseInt(stateParameters.PART_MODEL, 10)
		} else if (parameterKey === 'LOCATION' && stateParameters.LOCATION) {
			filter.location = stateParameters.LOCATION
		} else if (parameterKey === 'CONDITION' && stateParameters.CONDITION) {
			filter.condition = stateParameters.CONDITION
		} else if (parameterKey === 'STATUS' && stateParameters.STATUS) {
			filter.statuses = stateParameters.STATUS === '@' ? ['A', 'H'] : [stateParameters.STATUS]
		} else if (parameterKey === 'USER_STATUS' && stateParameters.USER_STATUS) {
			filter.userStatus = stateParameters.USER_STATUS
		} else if (parameterKey === 'CATEGORY' && stateParameters.CATEGORY) {
			filter.category = stateParameters.CATEGORY
		} else if (parameterKey === 'SIDE' && stateParameters.SIDE) {
			filter.side = stateParameters.SIDE
		} else if ((parameterKey === 'YEAR_RANGE' && stateParameters.YEAR_RANGE?.from) || stateParameters.YEAR_RANGE?.to) {
			filter.year = {}
			if (stateParameters.YEAR_RANGE?.from) {
				filter.year.gte = stateParameters.YEAR_RANGE?.from
			}
			if (stateParameters.YEAR_RANGE?.to) {
				filter.year.lte = stateParameters.YEAR_RANGE?.to
			}
		} else if (parameterKey === 'VEHICLE_MANUFACTURER' && stateParameters.VEHICLE_MANUFACTURER) {
			filter.vehicleManufacturerId = parseInt(stateParameters.VEHICLE_MANUFACTURER, 10)
		} else if (parameterKey === 'VEHICLE_MODEL' && stateParameters.VEHICLE_MODEL) {
			filter.vehicleModelId = parseInt(stateParameters.VEHICLE_MODEL, 10)
		} else if (parameterKey === 'SMART_SEARCH' && stateParameters.SMART_SEARCH?.keyword && stateParameters.SMART_SEARCH?.moduleIds?.length) {
			filter.smartSearchFilters = [stateParameters.SMART_SEARCH]
		} else if (parameterKey === 'TAG_NUMBER' && stateParameters.TAG_NUMBER) {
			filter.tagNumber = stateParameters.TAG_NUMBER
		} else if (parameterKey === 'TYPE_FIELD_1' && stateParameters.TYPE_FIELD_1) {
			filter.typeField1 = stateParameters.TYPE_FIELD_1
		} else if (parameterKey === 'TYPE_FIELD_2' && stateParameters.TYPE_FIELD_2) {
			filter.typeField2 = stateParameters.TYPE_FIELD_2
		} else if (parameterKey === 'TYPE_FIELD_3' && stateParameters.TYPE_FIELD_3) {
			filter.typeField3 = stateParameters.TYPE_FIELD_3
		} else if (parameterKey === 'TYPE_FIELD_4' && stateParameters.TYPE_FIELD_4) {
			filter.typeField4 = stateParameters.TYPE_FIELD_4
		} else if (parameterKey === 'ADDITIONAL_FIELDS' && stateParameters.ADDITIONAL_FIELDS && Object.keys(stateParameters.ADDITIONAL_FIELDS).length) {
			filter.additionalFields = Object.entries(stateParameters.ADDITIONAL_FIELDS).map(([key, value]) => {
				return {
					name: key,
					value: buildAdditionalFieldValueFilterArgument(value),
				}
			})
		} else if (parameterKey === 'QA_FIELDS' && stateParameters.QA_FIELDS && Object.keys(stateParameters.QA_FIELDS).length) {
			filter.customFields = Object.entries(stateParameters.QA_FIELDS).map(([key, value]) => {
				return {
					name: key,
					value: buildInventoryQuestionValueFilterArgument(value),
				}
			})
		}
	}

	return filter
}

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.part-search.results',
		route: 'results',
		querystringParameters: [
			'selectedInventoryId',
			'pageNumber',
			'perPageCount',
			'sortColumn',
			'sortDirection',
			'STORE',
			'INVENTORY_TYPE',
			'ASSEMBLY_MANUFACTURER',
			'ASSEMBLY_MODEL',
			'PART_MANUFACTURER',
			'PART_MODEL',
			'LOCATION',
			'CONDITION',
			'STATUS',
			'USER_STATUS',
			'CATEGORY',
			'SIDE',
			'YEAR_RANGE',
			'VEHICLE_MANUFACTURER',
			'VEHICLE_MODEL',
			'SMART_SEARCH',
			'TAG_NUMBER',
			'ADDITIONAL_FIELDS',
			'TYPE_FIELD_1',
			'TYPE_FIELD_2',
			'TYPE_FIELD_3',
			'TYPE_FIELD_4',
			'QA_FIELDS',
		],
		defaultParameters: {
			selectedInventoryId: null,
			pageNumber: '1',
			perPageCount: (): PerPageCount => {
				const perPageCount = localStorage.getItem('numPartRows') ?? ''
				return perPageCount && isValidPerPageCount(perPageCount) ? perPageCount : '10' //Fallback to a valid default
			},
			STORE: `[${getSession()?.currentStore}]`,
		},
		template: {
			svelte: true,
			component: Results,
		},
		async resolve(_data, parameters: Record<string, string>) {
			const parsedParameters: ParsedStateParameters = {}
			for (const key in parameters) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
				parsedParameters[key] = jsonParseParameters.has(key) ? JSON.parse(parameters[key]) : parameters[key]
			}
			const filter = getApiFilter(parsedParameters)

			const variables: PartSearchResultsData$input = {
				filter,
				pagination: {
					pageSize: parsedParameters.perPageCount && isValidPerPageCount(parsedParameters?.perPageCount?.toString?.() ?? '') ? parsedParameters.perPageCount : 10,
					pageNumber: parsedParameters.pageNumber ?? 1,
				},
			}
			const sortDirection = parsedParameters.sortDirection ?? 'ASC'
			const orderBy = getOrderBy(parsedParameters.sortColumn, sortDirection)
			if (isValidOrderBy(orderBy)) {
				variables.orderBy = [orderBy]
			}
			const { data } = await dataQuery.fetch({ variables })
			const inventoryTypeId = parsedParameters.INVENTORY_TYPE ? parseInt(parsedParameters.INVENTORY_TYPE, 10) : undefined

			return {
				inventoryTypeId,
				selectedInventoryId: parsedParameters?.selectedInventoryId,
				pageNumber: variables?.pagination?.pageNumber,
				perPageCount: variables?.pagination?.pageSize.toString(),
				sortDirection,
				sortColumnProp: parsedParameters?.sortColumn,
				previousSortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC',
				inventories: data?.inventories,
			}
		},
	})
}
