export default function buildApiUrl() {
	const apiUrl = import.meta.env?.API_URL ?? import.meta.env?.BASE_URL
	const microservicehost = apiUrl.replace(/\/$/, '') // remove trailing slash, as we add it in the code below
	const graphqlhostversion = import.meta.env?.GRAPHQL_VERSION ?? 'graphql'

	return {
		microservicehost,
		graphqlhostversion,
		apiURL: `${microservicehost}/${graphqlhostversion}`,
	}
}
