<script lang="ts">
	interface ServerInformation {
		releaseVersionNumber?: string
		schemaVersion?: string
	}

	import Login from '@isoftdata/svelte-login'
	import { getContext } from 'svelte'
	import type { ComponentProps } from 'svelte'
	import debugLog from 'debug'
	import type { Mediator } from 'types/mediator'
	import sessionStore from 'stores/session'

	import { CloseSessionMutationStore, graphql, LoginMutationStore } from '$houdini'

	const loginMutation: LoginMutationStore = graphql`
		mutation LoginMutation($username: String!, $passphrase: String!, $selectedStoreId: UInt!) {
			createSession(user: $username, password: $passphrase, storeId: $selectedStoreId) {
				token
				id
				userName
				currentUserAccountId
				accessType
				accessStatus
				sessionStatus
				storeId
				recordType
				recordId
				...Permissions
				userAccount {
					id
					username: name
					email: workEmail
					wageRateId
					deliveryZoneId
					glDepartmentId
					driver
					salesPerson
					worker
					firstName
					lastName
					groups {
						id
						name
					}
					stores
					highestAccessPermissions {
						id
						codeName
						category
						description
						level
					}
					settings {
						preferences {
							optInToTestingFeatures
						}
					}
				}
			}
		}
	`

	const closeSessionMutation: CloseSessionMutationStore = graphql`
		mutation CloseSessionMutation {
			closeSession
		}
	`

	debugLog('enterpriseweb:login')

	const mediator: Mediator = getContext('mediator')

	export let storeList: ComponentProps<Login>['siteList']
	export let selectedStoreId: number
	export let username = ''
	export let isLoading = false
	export let serverInformation: Promise<ServerInformation>
	export let rememberMe = false
	export let asr
	let loginComponent: Login

	function saveRememberMe(rememberMe, username) {
		if (rememberMe) {
			localStorage.setItem('lastUsername', username)
		} else {
			localStorage.removeItem('lastUsername')
		}
	}

	async function login(event) {
		const { username, password, rememberMe, siteId } = event.detail
		debugLog('login fired')
		isLoading = true
		try {
			const { data } = await loginMutation.mutate({
				username,
				passphrase: password,
				selectedStoreId: parseInt(siteId, 10),
			})

			if (!data) {
				throw new Error('No data returned from login mutation')
			}

			const { createSession: userSession } = data
			debugLog(userSession)

			localStorage.setItem('lastStoreId', siteId)
			saveRememberMe(rememberMe, username)

			if (!userSession.token) {
				throw new Error('No token returned from login mutation')
			}

			/* check if user has WEB_CAN_LOGIN*/
			if (userSession.permissions?.WEB_CAN_LOGIN) {
				const user = {
					...userSession.userAccount,
					currentStore: userSession.storeId,
					sessionPermissions: userSession.permissions,
					authToken: userSession.token,
				}

				sessionStore.set(user)
				asr.go('app')
			} else {
				//need to cancel session
				await closeSessionMutation.mutate(null)
				isLoading = false
				//ractive.findComponent('login').invalidLogin("User does not have login permission for Enterprise Web")
				loginComponent.invalidLogin('User does not have login permission for Enterprise Web')
			}
		} catch (err) {
			isLoading = false
			console.log(err)
			if (Array.isArray(err)) {
				const alert = err.reduce(
					(acc: { message: string; subMessages: string[] }, e, index) => {
						if (index === 0) {
							acc.message = e.message
						} else {
							acc.subMessages.push(e.message)
						}
						return acc
					},
					{ message: '', subMessages: [] },
				)

				loginComponent.setAlert(alert)
			} else if ((err as Error)?.message) {
				loginComponent.invalidLogin((err as Error)?.message || 'An unknown error occured')
			}
		}
	}
</script>

<Login
	{username}
	{rememberMe}
	{isLoading}
	siteLabel="Store"
	siteList={storeList}
	bind:selectedSiteId={selectedStoreId}
	logoImagePath="images/ITrackEnterpiseOnTopNut.svg"
	forgotPasswordDisabled
	on:login={login}
	bind:this={loginComponent}
>
	<div class="mt-3 d-flex justify-content-center">
		<a
			href="https://changelog.isoftdata.com/?product=enterprise-web#__buildVersion__"
			target="enterprise_web_changelog"
		>
			<small
				class="text-dim"
				title="Built Date: __buildDate__
Enviroment: __buildEnv__">App Version __buildVersion__</small
			>
		</a>
	</div>
	<div class="d-flex flex-column align-items-center">
		{#await serverInformation then info}
			{#if info.releaseVersionNumber}
				<a
					href="https://changelog.isoftdata.com/?product=enterprise-api#{info.releaseVersionNumber}"
					target="enterprise_api_changelog"
				>
					<small
						class="text-dim"
						title={info.schemaVersion ? `Schema Version ${info.schemaVersion}` : ''}
						>API Version {info.releaseVersionNumber}</small
					>
				</a>
			{/if}
		{/await}
	</div>
</Login>
