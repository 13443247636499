import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/VehicleSearchResultsData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class VehicleSearchResultsDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "VehicleSearchResultsDataStore",
			variables: false,
		})
	}
}

export async function load_VehicleSearchResultsData(params) {
	await initClient()

	const store = new VehicleSearchResultsDataStore()

	await store.fetch(params)

	return {
		VehicleSearchResultsData: store,
	}
}
