import template from './itStatusSelect.html'
import makeItSelect from '@isoftdata/select'
import statuses from 'common/VehicleStatuses'
//import domValue from 'dom-value'

const defaultOptions = {
	twoway: false,
	lazy: true,
	isolated: true,
}

export default function makeItStatusSelectComponent(mediator, { twoway = false, lazy = true, isolated = true } = defaultOptions) {
	return Ractive.extend({
		template,
		twoway: false,
		components: {
			itSelect: makeItSelect({
				twoway,
				lazy,
				isolated,
			}),
		},
		data() {
			return {
				type: 'Part', //'Part' or 'Vehicle'
				savedInputIdList: [],
				displayFullName: true,
				filterStatuses: [],
				filterAt: false, //@ (A or H)
				class: '',
				value: '',
				isLoading: false,
				statuses,
			}
		},
		computed: {
			displayStatuses() {
				const statuses = this.get('statuses')
				const filterStatuses = this.get('filterStatuses')

				let displayStatuses = statuses

				if (statuses && filterStatuses && Array.isArray(filterStatuses) && filterStatuses.length > 0) {
					displayStatuses = statuses.reduce((acc, status) => {
						if (filterStatuses.find(filterStatus => {
							return filterStatus === status.abbreviation
						})) {
							return acc
						} else {
							return acc.concat(status)
						}
					}, [])
				}

				function statusFound(statusToMatchOn) {
					if (statusToMatchOn && displayStatuses) {
						return !!displayStatuses.find(status => {
							return status.abbreviation === statusToMatchOn
						})
					} else {
						return false
					}
				}

				if (statusFound('A') &&
					statusFound('H') &&
					filterStatuses &&
					filterStatuses.length > 0 &&
					!filterStatuses.includes('@') &&
					!this.get('filterAt')
				) {
					displayStatuses = [{ abbreviation: '@', name: 'A or H' }].concat(displayStatuses)
				}

				return displayStatuses
			},
		},
	})
}
