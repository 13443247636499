import artifact from '$houdini/artifacts/LIB_CreateItemAttachments'
import { MutationStore } from '../runtime/stores/mutation'

export class LIB_CreateItemAttachmentsStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
