<script lang="ts">
	import type { InventoryQa$result } from '$houdini'
	import type { Column } from '@isoftdata/svelte-table'
	type InventoryQa = InventoryQa$result['inventory']
	interface QaTableRow {
		question: string
		[key: string]: string
	}

	import { Table, Td } from '@isoftdata/svelte-table'
	import { graphql } from '$houdini'
	import Icon from '@isoftdata/svelte-icon'

	const inventoryQaQuery = graphql(`
		query InventoryQa($inventoryId: UInt!) {
			inventory(id: $inventoryId) {
				options {
					id
					name
					rank
				}
				serials {
					number
					optionValues {
						optionId
						value
					}
				}
				optionValues {
					optionId
					value
				}
				serialized
			}
		}
	`)

	export let inventoryId: number
	export let vehicle: boolean = false

	let inventoryQa: InventoryQa | undefined = undefined
	let tableColumns: Column[] = []

	async function loadQa(inventoryId: number) {
		return inventoryId ? (await inventoryQaQuery.fetch({ variables: { inventoryId }, policy: 'CacheOrNetwork' }))?.data?.inventory : undefined
	}

	async function loadAndSetQa(inventoryId: number) {
		inventoryQa = await loadQa(inventoryId)
	}

	function computeTableColumns(inventoryQa: InventoryQa | undefined) {
		let columns = [{ property: 'question', name: 'Question' }]
		if (inventoryQa) {
			if (inventoryQa.serialized) {
				for (const serial of inventoryQa.serials) {
					columns.push({ property: serial.number || 'unknownSerial', name: serial.number || 'unknown serial' })
				}
			} else {
				columns.push({ property: 'answer', name: 'Answer' })
			}
		}
		return columns
	}

	function computeQaRows(inventoryQa: InventoryQa | undefined): QaTableRow[] {
		if (inventoryQa) {
			const questionsByRank = inventoryQa.options.slice().sort((a, b) => a.rank - b.rank)
			let rows: QaTableRow[] = []
			if (inventoryQa.serialized) {
				rows = questionsByRank.reduce((acc: QaTableRow[], question) => {
					const serialAnswers = inventoryQa.serials.reduce((acc, serial) => {
						const optionValueForSerial = serial.optionValues.find(o => o.optionId === question.id)
						acc[serial.number] = optionValueForSerial?.value ?? ''
						return acc
					}, {})
					const questionHasSomeAnswers = Object.values(serialAnswers).some(answer => answer)
					if (questionHasSomeAnswers) {
						acc.push({
							question: question.name,
							...serialAnswers,
						})
					}

					return acc
				}, [])
			} else {
				rows = questionsByRank.reduce((acc: QaTableRow[], question) => {
					const optionValue = inventoryQa.optionValues.find(o => o.optionId === question.id)
					if (optionValue?.value) {
						acc.push({
							question: question.name,
							answer: optionValue.value,
						})
					}

					return acc
				}, [])
			}

			return rows
		} else {
			return []
		}
	}

	$: loadAndSetQa(inventoryId)
	$: tableColumns = computeTableColumns(inventoryQa)
	$: tableRows = computeQaRows(inventoryQa)
</script>

{#if $inventoryQaQuery.fetching}
	<Icon
		icon="spinner"
		class="fa-xl fa-spin"
	/>
{:else if tableRows?.length}
	<Table
		rows={tableRows}
		columns={tableColumns}
		let:row={questionRow}
		responsive
	>
		<tr>
			{#each tableColumns.map(column => column.property) as prop}
				<Td property={prop}>{questionRow[prop]}</Td>
			{/each}
		</tr>
	</Table>
{:else}
	<div
		class="alert alert-info mt-3 mb-0"
		role="alert"
	>
		No Q & A data available for this {vehicle ? 'vehicle' : 'part'}.
	</div>
{/if}
