import sessionStore, { getSession, type ClientSession } from 'stores/session'

let session = getSession()
sessionStore.subscribe(value => {
	session = value
})

export type PermissionCode = keyof Exclude<ClientSession['sessionPermissions'], null>

export function checkSessionPermission(permissionCode: PermissionCode): boolean {
	if (session.sessionPermissions && !(permissionCode in session.sessionPermissions)) {
		console.warn(`Permission code "${permissionCode}" is not a valid permission code`)
	}

	return session.sessionPermissions?.[permissionCode] ?? false
}
