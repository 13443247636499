import cache from 'memory-cache'
import { reduceToTruthyValues } from '@isoftdata/utility-object'
import type { Mediator } from '../types/mediator'
import type { ClientSession } from 'stores/session'
import sessionStore, { getSession } from 'stores/session'
// Having the whole query in the error message makes it impossible to see the acutal error.
const TRIM_REGEX = new RegExp('(.*got invalid value) {.*};(.*)')

interface HeaderStrings {
	[key: string]: string
}

export interface FetchOptions {
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
	headers?: HeaderStrings
	body?: string | FormData
	abortSignal?: AbortSignal
}

const getNonNullishProps = (obj: HeaderStrings) => {
	const newObj: HeaderStrings = {}
	for (const key in obj) {
		if (!(obj[key] === undefined || obj[key] === null)) {
			newObj[key] = obj[key]
		}
	}

	return newObj
}

interface MutateAndCache {
	cacheKey: string
	res: unknown
	minutesToLive: number
	mutator: (input: unknown) => unknown
}

const mutateAndCache = ({ cacheKey, res, minutesToLive, mutator }: MutateAndCache) => {
	const mutatedValue = mutator(res)
	cache.put(cacheKey, mutatedValue, minutesToLive * 60 * 1000)

	return mutatedValue
}

let session: ClientSession | Record<string, never> = getSession()
sessionStore.subscribe(value => {
	session = value
})

async function apiFetch(apiUrl: string, { method = 'POST', headers, body }: FetchOptions = {}) {
	const res = await fetch(apiUrl, {
		method,
		headers: getNonNullishProps({
			'auth-token': session.authToken ?? '',
			'apollographql-client-name': 'EnterpriseWeb',
			'apollographql-client-version': '',
			...(headers ?? {}),
		}),
		body,
	})

	return res.json() as unknown
}

interface GqlErrorResponse {
	message?: string
	[x: string]: unknown
}

interface UploadAttachmentResponse {
	errors?: GqlErrorResponse[]
	[x: string]: unknown
}

interface GraphQlQueryResponse<DataType> {
	data: DataType[]
	errors?: GqlErrorResponse[]
}

const apiCall = async (apiUrl: string, query: string, variables?: Record<string, unknown>) => {
	const debugEnabled = false

	if (debugEnabled) {
		console.groupCollapsed(query.split('{', 1)[0])

		if (variables) {
			console.log('variables', variables)
			console.log(JSON.stringify(variables, null, 2))
		}

		console.log(query)
		console.groupEnd()
	}

	const { errors, data } = (await apiFetch(apiUrl, {
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ query, variables }),
	})) as GraphQlQueryResponse<Record<string, unknown>>

	if (debugEnabled && data) {
		console.log('data', data)
	}

	/*	if (errors) {
		console.error(`error in query ${query.split('{', 1)[0]}`, errors)
		throw errors
	} else {
		return data
	}*/
	// Sometimes the API will "return" data that is useless when we get an error, so only count it if it returns something non nullish
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	const truthyValuesObj = reduceToTruthyValues({
		object: data || {},
		options: { objectRecursive: true, onlyNoNullish: true },
	}) as Record<string, unknown>
	const hasData = !!data && Object.keys(truthyValuesObj).length > 0

	const trimmedErrors = errors?.map(({ message, ...err }: GqlErrorResponse) => {
		const matches = message?.match(TRIM_REGEX)
		if (matches) {
			message = `${matches[1]}.${matches[2]}`
		}
		return { ...err, message }
	})

	if (errors && hasData) {
		console.error('data returned with error')
		console.error('data', data)

		console.error('errors', trimmedErrors)
	}

	if (hasData || !errors) {
		return data
	}

	console.error(`error in query ${query.split('{', 1)[0]}`, trimmedErrors?.map(({ message }) => message).join('\n'))
	throw trimmedErrors
}

function getUploadFileAttachmentQuery(relation) {
	if (relation == 'vehicle') {
		return `
		#graphql
		mutation($file: Upload!, $rank: Int, $relationId: UInt!) {
			createVehicleAttachment(input: { vehicleId: $relationId, rank: $rank, file: $file }) {
			  fileId: id
			  rank
			  public
			  file {
				  id
				  name
				  path: url
				  type
				  size
				  mimeType: mimetype
				  extension
				  updated
				  createdDate: created
			  }
			}
		  }`
	}
	if (relation == 'inventory') {
		return `
		#graphql
		mutation($file: Upload!, $rank: Int, $relationId: UInt!) {
			createItemAttachment(input: { id: $relationId, rank: $rank, file: $file }) {
			  fileId: id
			  rank
			  public
			  file {
				  id
				  name
				  path: url
				  type
				  size
				  mimeType: mimetype
				  extension
				  updated
				  createdDate: created
			  }
			}
		  }`
	}
}

interface UploadFileAttachmentOptions {
	file: File
	relationId: number
	relation: string
	rank: number
}

const uploadFileAttachment = (apiUrl: string, { file, relationId, relation, rank }: UploadFileAttachmentOptions) => {
	const formData = new FormData()
	const variables = { file: null, rank, relationId }

	const query = getUploadFileAttachmentQuery(relation)

	formData.append('operations', JSON.stringify({ query, variables }))
	formData.append('map', '{ "0": ["variables.file"] }')
	formData.append('0', file)

	return apiFetch(apiUrl, { body: formData })
}

interface Attachment {
	file: File | null
	public: boolean
	rank: number
}

function uploadFilesAttachments(apiUrl: string, relationId: number, files: Attachment[] = []) {
	if (!files.length) {
		return Promise.resolve({ data: { createItemAttachments: [] } })
	}
	const formData = new FormData()
	// just inventory items right now
	const query = `#graphql
		mutation LIB_CreateItemAttachments($input: NewItemAttachments!) {
		createItemAttachments(input: $input) {
			fileId: id
			rank
			public
			file {
				id
				name
				path: url
				type
				size
				mimeType: mimetype
				extension
				updated
				createdDate: created
			}
		}
	}`

	interface Variables {
		input: {
			sku: number
			attachments: Attachment[]
		}
	}

	interface Acc {
		variables: Variables
		map: Record<number, string[]>
	}

	const { variables, map } = files.reduce(
		(acc: Acc, value: Attachment, index: number) => {
			const variables: Variables = acc.variables
			const map = acc.map

			const isPublic = value.public
			const rank = value.rank

			map[index] = [`variables.input.attachments.${index}.file`]
			variables.input.attachments[index] = { file: null, public: isPublic, rank }
			return { variables, map }
		},
		{ variables: { input: { sku: relationId, attachments: [] } }, map: {} }
	)

	const operations = {
		query,
		variables,
	}

	formData.append('operations', JSON.stringify(operations))
	formData.append('map', JSON.stringify(map))
	files.forEach(({ file }, index) => {
		if (file) {
			formData.append(index.toString(), file)
		}
	})

	return apiFetch(apiUrl, { body: formData })
}

export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<unknown>
export type FetchPathFunction = (
	query: string,
	variables?: Record<string, unknown>,
	keypath?: string
) => Promise<unknown>
interface FetchWithCacheArgs {
	query: string
	variables?: Record<string, unknown>
	minutesToLive?: number
	mutator?: (input: unknown) => unknown
}
export type FetchWithCache = (args: FetchWithCacheArgs) => Promise<unknown>
export type UploadFileAttachmentFunction = (args: UploadFileAttachmentOptions) => Promise<UploadAttachmentResponse>
export type UploadFileAttachmentsFunction = (
	relationId: number,
	files: Attachment[]
) => Promise<UploadAttachmentResponse>

// This is just the "global" providers that are available to all states.
// For "local" providers, consider handling the TS types within that state itself.
export interface MediatorProviders {
	graphqlFetch: FetchFunction
	graphqlFetchPath: FetchPathFunction
	graphqlFetchWithCache: FetchWithCache
	uploadFileAttachment: UploadFileAttachmentFunction
	uploadFileAttachments: UploadFileAttachmentsFunction
}

interface InitArgs {
	apiURL: string
	mediator: Mediator
}

export default function ({ apiURL, mediator }: InitArgs) {
	mediator.provide('graphqlFetch', (query: string, variables?: Record<string, unknown>) =>
		apiCall(apiURL, query, variables)
	)

	// Made a new provider because I didn't want to add an argument after the callback to the above provider, or replace all callbacks with promises right now
	mediator.provide('graphqlFetchPath', async (query: string, variables?: Record<string, unknown>, keypath?: string) => {
		const res = await apiCall(apiURL, query, variables)
		if (keypath) {
			let value: Record<string, unknown> | unknown = res
			for (const key of keypath.split('.')) {
				if (value?.[key]) {
					value = value?.[key]
				}
			}
			return value
		} else {
			return res
		}
	})

	mediator.provide(
		'graphqlFetchWithCache',
		({ query, variables = {}, minutesToLive = 60, mutator = v => v }: FetchWithCacheArgs) => {
			const cacheKey = JSON.stringify({ query, variables })
			const cachedData = cache.get(cacheKey) as unknown

			if (cachedData) {
				return Promise.resolve(cachedData)
			} else {
				return new Promise((resolve, reject) => {
					apiCall(apiURL, query, variables)
						.then(res => {
							resolve(mutateAndCache({ cacheKey, res, minutesToLive, mutator }))
						})
						.catch(reject)
				})
			}
		}
	)

	mediator.provide(
		'uploadFileAttachment',
		async ({ file, relationId, relation, rank }: UploadFileAttachmentOptions) => {
			const res = (await uploadFileAttachment(apiURL, { file, relationId, relation, rank })) as UploadAttachmentResponse
			const { errors } = res
			if (errors && Array.isArray(errors)) {
				throw errors[0]
			}
			return res
		}
	)

	mediator.provide('uploadFileAttachments', async (relationId: number, files: Attachment[]) => {
		const res = (await uploadFilesAttachments(apiURL, relationId, files)) as UploadAttachmentResponse
		const { errors } = res
		if (errors && Array.isArray(errors)) {
			throw errors[0]
		}
		return res
	})
}
