<script lang="ts">
	import Icon from '@isoftdata/svelte-icon'
	export let isPinned: boolean = false
</script>

{#if isPinned}
	<Icon
		icon="thumbtack"
		size="xs"
		class="text-primary"
	/>
{/if}
