import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/InventoryOptions'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class InventoryOptionsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "InventoryOptionsStore",
			variables: false,
		})
	}
}

export async function load_InventoryOptions(params) {
	await initClient()

	const store = new InventoryOptionsStore()

	await store.fetch(params)

	return {
		InventoryOptions: store,
	}
}
